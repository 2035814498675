(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('momentformat', momentformat);

    function momentformat () {
        return {
            require: 'ngModel',
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                outputformat: '='
            },
            link: function (scope, element) {
                var format = function (value, outputformat) {
                    var dateTime = moment(value);
                    return dateTime.format(outputformat);
                };
                scope.$watch(scope.ngModel, function (value) {
                    element.val(format(value, scope.outputformat));
                });
            }
        };
    }

})();
