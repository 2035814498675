(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('eatClick', eatClick);

    function eatClick() {
        return {
            restrict: 'A',
            priority: -1,
            link: function (scope, elem, attrs) {

                var condition = true;

                attrs.$observe('eatClick', function (newCondition) {
                    condition = newCondition;
                });

                elem.on('click', function (event) {

                    if (attrs['href'] == '#') {
                        event.preventDefault();
                        return;
                    }

                    var prevent = condition === '' || condition === 'true';

                    if (prevent) {
                        event.stopImmediatePropagation();
                        event.stopPropagation();
                        event.preventDefault();
                    }
                });

            }
        };
    }

})();
