(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('spidMaxlength', spidMaxlength);

    function spidMaxlength () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {
                var maxlength = Number(attrs.spidMaxlength);

                function fromUser(text) {
                    if (text && text.length > maxlength) {
                        var transformedInput = text.substring(0, maxlength);
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                        return transformedInput;
                    }
                    return text;
                }

                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    }

})();
