(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$scope', '$sce', '$rootScope', 'userSettings', 'mainService'];

    function MainController ($scope, $sce, $rootScope, userSettings, mainService) {
        $scope.initHomePage = initHomePage;

        var deregTranslateChangeStart = $rootScope.$on('$translateChangeStart', function () {
            initHomePage();
        });


        $scope.$on('$destroy', function() {
            deregTranslateChangeStart();
        });

        function initHomePage() {
            initMessages();
            initPerimeterSpid();
        }

        function initMessages() {
            mainService.getItMessage().then(function (welcomeMessMoe) {
                $scope.welcomeMessMoe = $sce.trustAsHtml(welcomeMessMoe);
            });
            mainService.getNewsMessage().then(function (welcomeMessMoa) {
                $scope.welcomeMessMoa = $sce.trustAsHtml(welcomeMessMoa);
            });
        }

        function initPerimeterSpid() {
            $scope.perimeterImgLink = $scope.contentsURL + '/resources/perimeter/' + userSettings.getSelectedIhmLanguage() + '.JPG';
        }

        $scope.user = userSettings.getUser();
        $scope.weekNumber = moment().format('WW');
        $scope.dateToday = new Date();
    }

})();
