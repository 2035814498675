(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('packshotsSign', packshotsSign);

    function packshotsSign () {
        return  {
            restrict: 'E',
            templateUrl: 'productCardNg/imagesVideos/packshotsSign/packshotsSign.html'
        };
    }

})();
