(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('sportsEditionController', sportsEditionController)
        .filter('autoExclusiveSportsGroups', autoExclusiveSportsGroups)
        .filter('autoExclusiveSports', autoExclusiveSports)
        .filter('autoExclusivePractices', autoExclusivePractices);

    sportsEditionController.$inject = ['$scope', 'userSettings', 'convientA', 'sportService', 'modelSportsGroups', 'sportsGroupsRepository', 'sportsRepository', 'practicesRepository'];

    function sportsEditionController($scope, userSettings, convientA, sportService, modelSportsGroups, sportsGroupsRepository, sportsRepository, practicesRepository) {

        $scope.convientA = convientA;

        _(modelSportsGroups).each(function (modelSportsGroup) {
            modelSportsGroup.$pristine = true;
            _(modelSportsGroup.sports).each(function (modelSport) {
                modelSport.$pristine = true;
            });
        });

        $scope.floatTheadSportsOptions = {
            top: 85,
            position: 'auto',
            autoReflow: true,
            scrollContainer: true
        };

        $scope = _($scope).extend({

            //modelSports: sportService.sortSportsGroups(modelSportsGroups),
            modelSportsGroups: modelSportsGroups,
            sportsGroupsRepository: sportsGroupsRepository,
            sportsRepository: sportsRepository,
            practicesRepository: practicesRepository,

            addPractice: addPractice,
            addSport: addSport,
            addSportsGroup: addSportsGroup,
            removePractice: removePractice,
            removeSport: removeSport,
            removeSportsGroup: removeSportsGroup,
            countPractices: countPractices,
            sportGroupHasSportResponsible: sportGroupHasSportResponsible,
            sportHasSportResponsible: sportHasSportResponsible,
            isSportResponsible: isSportResponsible
        });

        function addPractice(practice) {

            var sport = getSport(practice.fatherId);

            var sportsGroup = getActiveSportsGroup(sport.fatherId);

            if (sportsGroupAlreadySelected(sportsGroup)) {

                var sportsGroupContainerToUpdate = _($scope.modelSportsGroups).find(function (sportsGroupContainer) {
                    return sportsGroupContainer.id === sportsGroup.id;
                });

                if (sportAlreadySelected(sportsGroupContainerToUpdate, sport)) {

                    var sportContainerToUpdate = _(sportsGroupContainerToUpdate.sports).find(function (sportContainer) {
                        return sportContainer.id === sport.id;
                    });

                    sportContainerToUpdate.sports.push(practice);

                } else {
                    sport.sports = [practice];
                    sportsGroupContainerToUpdate.sports.push(sport);
                }

            } else {

                sport.sports = [practice];
                sportsGroup.sports = [sport];

                //$scope.modelSports.push(sportsGroup);
                $scope.modelSportsGroups.push(sportsGroup);
            }
            $scope.modelSportsGroups = tampOrders($scope.modelSportsGroups);
            $scope.modelSportsGroups = sportService.sortSportsGroups($scope.modelSportsGroups);
            resetSelectors();
        }

        function sportAlreadySelected(sportsGroupRow, sport) {
            return _($scope.modelSportsGroups).some(function (sportsGroup) {
                return _(sportsGroup.sports).find(function (sportContainer){
                    return sportContainer.id === sport.id;
                }) !== undefined;
            });
        }

        function addSport(sport) {

            var sportsGroup = getActiveSportsGroup(sport.fatherId);

            sport.sports = _(getActivePracticesOfSport(sport.id)).map(function (practice) {
                return practice;
            });

            if (sportsGroupAlreadySelected(sportsGroup)) {

                var sportsGroupContainerToUpdate = _($scope.modelSportsGroups).find(function (sportsGroupContainer){
                    return sportsGroupContainer.id === sportsGroup.id;
                });

                sportsGroupContainerToUpdate.sports.push(sport);

            } else {
                sportsGroup.sports = [sport];
                //$scope.modelSports.push(sportsGroup);
                $scope.modelSportsGroups.push(sportsGroup);
            }
            $scope.modelSportsGroups = tampOrders($scope.modelSportsGroups);
            $scope.modelSportsGroups = sportService.sortSportsGroups($scope.modelSportsGroups);
            resetSelectors();
        }

        function sportsGroupAlreadySelected(sportsGroupRow) {
            return _($scope.modelSportsGroups).find(function (sportsGroupContainer) {
                return sportsGroupContainer.id === sportsGroupRow.id;
            }) !== undefined;
        }

        function addSportsGroup(sportsGroup) {

            var sportsContainers = _(getActiveSportsOfSportsGroup(sportsGroup.id)).map(function (sport) {
                var practicesContainer = _(getActivePracticesOfSport(sport.id)).map(function (practice) {
                    return practice;
                });
                sport.sports = practicesContainer;
                return sport;
            });

            sportsGroup.sports = sportsContainers;

            //$scope.modelSports.push(sportsGroup);
            $scope.modelSportsGroups.push(sportsGroup);
            $scope.modelSportsGroups = tampOrders($scope.modelSportsGroups);
            $scope.modelSportsGroups = sportService.sortSportsGroups($scope.modelSportsGroups);
            resetSelectors();
        }

        function removePractice(sportsGroupContainer, sportContainer, practiceContainer) {
            sportContainer.sports = _(sportContainer.sports).without(practiceContainer);
            if (_(sportContainer.sports).isEmpty()) {
                removeSport(sportsGroupContainer, sportContainer);
            }
        }

        function removeSport(sportsGroupContainer, sportContainer) {
            sportsGroupContainer.sports = _(sportsGroupContainer.sports).without(sportContainer);
            if (_(sportsGroupContainer.sports).isEmpty()) {
                removeSportsGroup(sportsGroupContainer);
            }
        }

        function removeSportsGroup(sportsGroupContainer) {
            var sportsGroupContainerToDeleteIndex = _($scope.modelSportsGroups).indexOf(sportsGroupContainer);
            $scope.modelSportsGroups.splice(sportsGroupContainerToDeleteIndex, 1);
            $scope.modelSportsGroups = tampOrders($scope.modelSportsGroups);
        }

        function countPractices(sportsGroupContainer) {
            return _(sportsGroupContainer.sports).reduce(function (count, sport) {
                return count + sport.sports.length;
            }, 0);
        }

        function resetSelectors() {
            $scope.practiceToAdd = '';
            $scope.sportToAdd = '';
            $scope.sportsGroupToAdd = '';
        }

        function getActiveSportsOfSportsGroup(sportGroupId) {
            return _($scope.sportsRepository).where({fatherId: sportGroupId, active: true});
        }

        function getActivePracticesOfSport(sportId) {
            return _($scope.practicesRepository).where({fatherId: sportId, active: true});
        }

        function getSport(sportId) {
            return _($scope.sportsRepository).findWhere({id: sportId});
        }

        function getActiveSportsGroup(sportGroupId) {
            return _($scope.sportsGroupsRepository).findWhere({id: sportGroupId, active: true});
        }
    }

    function sportGroupHasSportResponsible(sportsGroupContainer) {
        var result = false;
        _(sportsGroupContainer.sports).forEach(function(sport){
            sport.sports.forEach( function(practice){
                    if (practice.sportResponsible == true) {
                        result = true;
                    }
                }
            );

        });
        return result;
    }

    function sportHasSportResponsible(sportContainer) {
        var result = false;
        _(sportContainer.sports).forEach(function(practice) {
            if (practice.sportResponsible == true) {
                result = true;
            }
        });
        return result;
    }

    function isSportResponsible(practiceContainer) {
        var result = false;
        if (practiceContainer.sportResponsible == true) {
            result = true;
        }
        return result;
    }

    function autoExclusiveSportsGroups() {
        return function (sportsGroupsRepository, modelSports) {
            return _(sportsGroupsRepository).reject(function (sportsGroup) {
                return !!_(modelSports).chain()
                    .findWhere({id: sportsGroup.id})
                    .value();
            });
        };
    }

    function autoExclusiveSports() {
        return function (sportsRepository, modelSports) {
            return _(sportsRepository).reject(function (sport) {
                return !!_(modelSports).chain()
                    .pluck('sports')
                    .flatten(true)
                    .findWhere({id: sport.id})
                    .value();
            });
        };
    }

    function autoExclusivePractices() {
        return function (practicesRepository, modelSports) {
            return _(practicesRepository).reject(function (practice) {
                return !!_(modelSports).chain()
                    .pluck('sports')
                    .flatten(true)
                    .pluck('sports')
                    .flatten(true)
                    .findWhere({id: practice.id})
                    .value();
            });
        };
    }

    function tampOrders (sportsGroups) {
        var i = 1;
        var sportsGroupsSorted = _(sportsGroups).sortBy(function (sportsGroup) { return sportsGroup.order; });
        var sportsGroupsTamped = _(sportsGroupsSorted).map(function (sportsGroup) { sportsGroup.order = i++; return sportsGroup; });
        return sportsGroupsTamped;
    }

})();
