(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('productService', productService);

    productService.$inject = ['$q', 'axiosService', 'envService'];

    function productService($q, axiosService, envService) {

        return {
            createModel: function (product, fromManualCreationScreen) {

                return axiosService
                    .post(
                        envService.getModelsContext() +
                            '?fromManualCreationScreen=' +
                            fromManualCreationScreen,
                            product,
                        {
                            withCredentials: true,
                            headers: {
                                'Content-Type':
                                    'application/json;charset=UTF-8',
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });

            }
        };
    }

})();
