(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('userBenefitsService', userBenefitsService);

    userBenefitsService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function userBenefitsService(axiosService, masterdatasCache, envService) {

        function getLocalizedBUs(locale) {
            return axiosService
                .get(
                    envService.getOfferDatasContext() +
                        '/userBenefits?locale=' +
                        locale,
                    {
                        withCredentials: true,
                        cache: masterdatasCache,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                )
                .then(function (response) {
                    return response.data;
                });
        }

        return {
            maxBuByModel: 8,
            getBUList: function (locale) {
                return getLocalizedBUs(locale);
            },
            getBUPictures: function (userBenefitId) {
                return axiosService
                    .get(
                        envService.getUserBenefitContext() +
                            '/' +
                            userBenefitId +
                            '/medias',
                        {
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getBUById: function (id, locale) {
                return getLocalizedBUs(locale).then(function (userBenefits) {
                    return _(userBenefits).filter(filterById(id))[0];
                });
            }
        };

        function filterById(id) {
            return function (elt) {
                return elt.id == id;
            };
        }

    }

})();
