(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('ngEnter', ngEnter);

    function ngEnter () {
        return function (scope, element, attrs) {
            element.bind('keypress', function (event) {
                if(event.which === 13) {
                    scope.$apply(function (){
                        scope.$eval(attrs.ngEnter || attrs.ngClick, {$event: event});
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }
            });
        };
    }

})();
