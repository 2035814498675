(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('dialogService', dialogService);

    dialogService.$inject = ['$uibModal', '$filter'];

    function dialogService ($uibModal, $filter) {

        return {
            showMessageBox: showMessageBox,
            showConfirmBox: showConfirmBox,
            showDeleteBox: showDeleteBox
        };

        function showMessageBox (title, message) {
            return $uibModal.open({
                templateUrl: 'dialogs/messageBox/messageBox.html',
                controller: 'MessageBoxController',
                resolve: {
                    config: function () {
                        return {
                            title: $filter('translate')(title),
                            message: $filter('translate')(message),
                            type: 'messagebox'
                        };
                    }
                }
            });
        }

        function showConfirmBox (title, message, okLabel, cancelLabel) {
            return $uibModal.open({
                templateUrl: 'dialogs/messageBox/messageBox.html',
                controller: 'MessageBoxController',
                size: 'lg',
                resolve: {
                    config: function () {
                        return {
                            title: $filter('translate')(title),
                            message: $filter('translate')(message),
                            type: 'confirmbox',
                            labels: {
                                okLabel: okLabel,
                                cancelLabel: cancelLabel
                            }
                        };
                    }
                }
            });
        }

        function showDeleteBox (title, message) {
            return $uibModal.open({
                templateUrl: 'dialogs/messageBox/messageBox.html',
                controller: 'MessageBoxController',
                resolve: {
                    config: function () {
                        return {
                            title: $filter('translate')(title),
                            message: $filter('translate')(message),
                            type: 'deleteBox'
                        };
                    }
                }
            });
        }

    }

})();
