(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ProductSellingDateController', ProductSellingDateController);

    ProductSellingDateController.$inject = ['$scope', '$uibModalInstance',
                                            'worldStartDate', 'worldEndDate'];

    function ProductSellingDateController ($scope, $uibModalInstance,
                                           worldStartDate, worldEndDate) {
        $scope.calendarsOptions = {
            worldStartDate: {
                date: new Date(worldStartDate),
                isOpen: false,
                format: 'dd/MM/yyyy',
                startingDay: 1,
                minDate: new Date(1990, 1, 1),
                maxDate: new Date(2049, 12, 31)
            },
            worldEndDate: {
                date: new Date(worldEndDate),
                isOpen: false,
                format: 'dd/MM/yyyy',
                startingDay: 1,
                minDate: new Date(worldStartDate),
                maxDate: new Date(2049, 12, 31)
            }
        };

        $scope.checkDateBoundaries = function () {
            $scope.calendarsOptions.worldEndDate.minDate = $scope.calendarsOptions.worldStartDate.date;
            if ($scope.calendarsOptions.worldEndDate.date < $scope.calendarsOptions.worldStartDate.date) {
                $scope.calendarsOptions.worldEndDate.date = new Date($scope.calendarsOptions.worldStartDate.date.getTime() + 7 * 86400000);
            }
        };
    }

})();
