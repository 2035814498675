(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ModelCompositionPopup', ModelCompositionPopup);

    ModelCompositionPopup.$inject = ['$scope', 'model', 'oldCompositionMaxSize', 'materialsRepository', 'productPartsRepository', 'compositionIsDisabled'];

    function ModelCompositionPopup($scope, model, oldCompositionMaxSize, materialsRepository, productPartsRepository, compositionIsDisabled) {

        $scope.model = model;
        $scope.colorsRepository = oldCompositionMaxSize;
        $scope.materialsRepository = materialsRepository;
        $scope.productPartsRepository = productPartsRepository;
        $scope.compositionIsDisabled = compositionIsDisabled;

    }

})();