(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MassActionQueueController', MassActionQueueController);

    MassActionQueueController.$inject = ['$filter', 'dialogService', 'massActionService', 'userSettings'];

    function MassActionQueueController ($filter, dialogService, massActionService, userSettings) {

        var self = this;

        massActionService.getActivePool().then(function (massActions) {
            self.massActions = massActions.data;
            _(self.massActions).each(function (massAction) {
                massAction.requestDateFormated = $filter('date')(massAction.requestDate, 'dd/MM/yyyy HH:mm:ss');
            });
        });

        self.cancelMassAction = function (massAction) {
            var modal = dialogService.showDeleteBox('admin.warningLabel', $filter('translate')('massAction.listRun.popup.message') + ' ' + massAction.id + ' ?');
            modal.result.then(function () {
                return massActionService.removeFromPool(massAction.id);
            }).then(function () {
                self.massActions = _(self.massActions).reject(function(ma) {
                    return ma.id == massAction.id;
                });
            });
        };

        self.getTranslationCode = function (expression) {
            return massActionService.getTranslationCode(expression);
        };

        self.massActionFilterComparator = function (actual, expected) {
            return massActionService.massActionFilterComparator(actual, expected);
        };

        self.isCancellable = function (massAction) {
            return massAction.state === 'WAIT' && (
                userSettings.hasRole('ROLE_ADMIN') || userSettings.getUser().email === massAction.callerEmail
            );
        };

    }

})();
