(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('internationalModal', internationalModal);

    internationalModal.$inject = ['$scope', 'translationService'];

    function internationalModal($scope, translationService) {

        $scope.vm.selectedLocalesInternationalMapping = null;

        $scope.localesInternationalMappings = translationService.getLocalesMapping();
    }


})();
