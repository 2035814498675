(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('editSportsController', editSportsController);

    editSportsController.$inject = ['$scope', 'userSettings', 'convientA', 'sportService', 'modelSportsGroups', 'sportsRepository'];

    function editSportsController($scope, userSettings, convientA, sportService, modelSportsGroups, sportsRepository) {

        $scope.convientA = convientA;

        $scope = _($scope).extend({

            modelSportsGroups: modelSportsGroups,
            sportsRepository: sportsRepository
        });


        $scope.floatTheadSportsOptions = {
            top: 85,
            position: 'auto',
            autoReflow: true,
            scrollContainer: true
        };

        $scope.sportsRepository.forEach(function (sportGroup) {
            sportGroup.sports.forEach(function (sport) {
                if (modelSportsGroups.some(e => e.id === sport.id)) {
                    sport.selected = true;
                    var sportDto = modelSportsGroups
                        .find(modelSport => modelSport.id === sport.id);
                    sport.sportResponsible = sportDto.sportResponsible;
                }
            });
            sportGroup.selected = _(sportGroup.sports).every(function (sport) {
                return sport.selected;
            });
        });

        sortSports();

        checkAllSelected();

        function sortSports() {
            $scope.responsibleSportGroup = [];
            $scope.selectedSportsGroups = [];
            $scope.unselectedSportsGroups = [];
            splitSportsGroups($scope.sportsRepository);
            $scope.selectedSportsGroups.sort(
                function (a, b) {
                    return a.name.localeCompare(b.name);
                }
            );
            $scope.unselectedSportsGroups.sort(
                function (a, b) {
                    return a.name.localeCompare(b.name);
                }
            );

            $scope.sportsRepository = $scope.responsibleSportGroup.concat($scope.selectedSportsGroups).concat($scope.unselectedSportsGroups);
            $scope.sportsRepository = _.uniq($scope.sportsRepository);
        }

        function splitSportsGroups(sportsGroups) {
            sportsGroups.forEach(function (sportGroup) {
                sportGroup.sports.forEach(function (sport) {
                    var sportFound = modelSportsGroups
                        .find(modelSport => modelSport.id === sport.id);
                    if (sportFound && sportFound.sportResponsible === true) {
                        if ($scope.responsibleSportGroup.indexOf(sportGroup) < 0) {
                            $scope.responsibleSportGroup.push(sportGroup);
                        }
                    } else if (sportFound) {
                        if ($scope.selectedSportsGroups.indexOf(sportGroup) < 0 && $scope.responsibleSportGroup.indexOf(sportGroup) < 0) {
                            $scope.selectedSportsGroups.push(sportGroup);
                        }
                    } else {
                        if ($scope.selectedSportsGroups.indexOf(sportGroup) < 0
                            && $scope.responsibleSportGroup.indexOf(sportGroup) < 0
                            && $scope.unselectedSportsGroups.indexOf(sportGroup) < 0) {
                            $scope.unselectedSportsGroups.push(sportGroup);
                        }
                    }
                });
            });
        }

        $scope.selectAll = function () {
            $scope.allSelected = !angular.copy($scope.allSelected);
            sportsRepository.forEach(function (sportsGroup) {
                $scope.selectAllSports(sportsGroup, $scope.allSelected);
            });
        };

        $scope.selectAllSports = function (sportGroup, selectAllFlag) {
            sportGroup.selected = selectAllFlag !== undefined ? selectAllFlag : !sportGroup.selected;
            sportGroup.sports.forEach(function (sport) {
                    $scope.selectSport(sport, sportGroup, sportGroup.selected);
                }
            );
            if (selectAllFlag === undefined) {
                checkAllSelected();
            }
        };

        $scope.selectSport = function(sport, sportGroup, allGroupSelected) {
            var productCardSport = $scope.modelSportsGroups.find(sportDto => sportDto.id === sport.id);
            if (!productCardSport || !productCardSport.sportResponsible) {
                sport.selected = allGroupSelected !== undefined ? allGroupSelected : !sport.selected;
                if (sport.selected) {
                    sport.fatherId = sportGroup.id;
                    sport.fatherLabel = sportGroup.name;
                    if (!$scope.modelSportsGroups.find(modelSport => modelSport.id === sport.id)) {
                        $scope.modelSportsGroups.push(sport);
                    }
                } else {
                    var sportsGroupContainerToDeleteIndex = _($scope.modelSportsGroups).indexOf($scope.modelSportsGroups.find(sportDto => sportDto.id === sport.id));
                    $scope.modelSportsGroups.splice(sportsGroupContainerToDeleteIndex, 1);
                }
                if(allGroupSelected === undefined) {
                    checkAllSelected();
                }
            }
        };

        function checkAllSelected() {
            $scope.sportsRepository.forEach(function (sportGroup) {
                sportGroup.selected = _(sportGroup.sports).every(function (sport) {
                    return sport.selected;
                });
            });

            $scope.allSelected = _($scope.sportsRepository).every(function (sportGroup) {
                return sportGroup.selected;
            });
        }
    }

})();
