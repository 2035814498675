(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('manualSendTranslationModal', manualSendTranslationModal);

    manualSendTranslationModal.$inject = ['$scope', 'models', 'modelStatusesBySmVersion'];

    function manualSendTranslationModal($scope, models, modelStatusesBySmVersion) {

        var tooltips = {
            translationInProgress: 'product.manual_send_translation.error.translationInProgress',
            translationInError: 'product.manual_send_translation.error.translationInError'
        };

        $scope.statusClasses = {
            available: 'label label-green bubble',
            unavailable: 'label label-orange bubble'
        };

        $scope.models = models.sort(function (m1, m2) {
            if (m1.modelCode < m2.modelCode) {
                return -1;
            }
            if (m1.modelCode > m2.modelCode) {
                return 1;
            }
            if (m1.versionDate > m2.versionDate) {
                return -1;
            }
            if (m1.versionDate < m2.versionDate) {
                return 1;
            }
            return 0;
        });
        $scope.models.forEach(e => {
            e.toSendManually = false;
            e.tooltip = undefined;
            if (e.translationInError) {
                e.tooltip = tooltips.translationInError;
            } else if (e.translationInProgress) {
                e.tooltip = tooltips.translationInProgress;
            }
            e.status = modelStatusesBySmVersion[e.smVersionId][e.modelCode];
        });
        $scope.allModelsChecked = false;

        $scope.isManuallySendable = function (model) {
            return !model.translationInProgress && !model.translationInError;
        };

        var manualSendCandidates = $scope.models.filter(model => $scope.isManuallySendable(model));

        function checkIfAllSendableModelsAreChecked() {
            var allChecked = true;
            for (var model of manualSendCandidates) {
                allChecked = allChecked && model.toSendManually;
            }
            $scope.allModelsChecked = allChecked;
        }

        $scope.toggleModelToTranslationIfSendable = function (model) {
            if ($scope.isManuallySendable(model)) {
                model.toSendManually = !model.toSendManually;
                checkIfAllSendableModelsAreChecked();
            }
        };

        $scope.toggleAllModelsToTranslation = function () {
            $scope.allModelsChecked = !$scope.allModelsChecked;
            $scope.models.forEach(model => {
                if ($scope.isManuallySendable(model)) {
                    model.toSendManually = $scope.allModelsChecked;
                }
            });
        };

        $scope.disableSendInTranslationButton = function () {
            return !manualSendCandidates.some(model => model.toSendManually);
        };

        $scope.close = function () {
            var modelsToSend = manualSendCandidates
                .filter(model => model.toSendManually)
                .map(function (model) {
                    return {
                        modelVersionId: model.modelVersionId,
                        localeSource: model.localeSourceToPackageWith,
                        localesToSendInTranslation: model.localesToSendInTranslation
                    };
                });
            $scope.$close(modelsToSend);
        };
    }
})();
