(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ColorsFragranceFlavorsPopup', ColorsFragranceFlavorsPopup);


    ColorsFragranceFlavorsPopup.$inject = ['$scope', 'model', 'definitionCategory', 'siblings', 'colorsRepository', 'flavors', 'fragrances', 'colorIsDisabled', 'modelBrand'];

    function ColorsFragranceFlavorsPopup($scope, model, definitionCategory, siblings, colorsRepository, flavors, fragrances, colorIsDisabled, modelBrand) {
        $scope.colorsRepository = colorsRepository;
        $scope.flavors = flavors;
        $scope.fragrances = fragrances;
        $scope.siblings = siblings;
        $scope.model = model;
        $scope.colorIsDisabled = colorIsDisabled;
        $scope.modelBrand = modelBrand;

        if (!$scope.modelBrand.brand){
            $scope.modelIsAmi = false;
        }
        else{
            $scope.modelIsAmi = !$scope.modelBrand.brand.passion;
        }

        var isEmpty = function (myModel) {
            return myModel.flavors.flavor1Id === null &&
                myModel.flavors.flavor2Id === null &&
                myModel.flavors.flavor3Id === null &&
                myModel.fragrance === null &&
                myModel.colors.color1Id === null &&
                myModel.colors.color2Id === null &&
                myModel.colors.color3Id === null;
        };
        var isDefinitionCategoryEditable = function () {
            return _($scope.siblings)
                .chain()
                .filter(function (sibling) {
                    return sibling.available && sibling.code !== $scope.model.code;
                })
                .every(function (sibling) {
                    return isEmpty(sibling);
                })
                .value()
                &&
                isEmpty($scope.model);
        };

        $scope.definitionCategoryChoosable = isDefinitionCategoryEditable();
        $scope.model.definitionCategory = definitionCategory;
        $scope.selectedDefinitionCategoryTab = $scope.model.definitionCategory !== null ? ['COLOR', 'FLAVOR', 'FRAGRANCE'].indexOf($scope.model.definitionCategory) : 0;

        $scope.changeSelectedDefinitionCategory = function (newDef) {
            $scope.model.definitionCategory = newDef;
        };

        var updateDefinitionCategoryChoosable = function () {
            $scope.definitionCategoryChoosable = isDefinitionCategoryEditable();
        };

        updateDefinitionCategoryChoosable();

        $scope.$watch('model.colors.color1Id', updateDefinitionCategoryChoosable);
        $scope.$watch('model.colors.color2Id', updateDefinitionCategoryChoosable);
        $scope.$watch('model.colors.color3Id', updateDefinitionCategoryChoosable);
        $scope.$watch('model.flavors.flavor1Id', updateDefinitionCategoryChoosable);
        $scope.$watch('model.flavors.flavor2Id', updateDefinitionCategoryChoosable);
        $scope.$watch('model.flavors.flavor3Id', updateDefinitionCategoryChoosable);
        $scope.$watch('model.fragrance', updateDefinitionCategoryChoosable);

    }


})();
