(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('WeightController', WeightController);

    WeightController.$inject = ['$scope', '$uibModalInstance', 'productNatureService', 'productCardDto', 'model', 'productNature', 'userSettings', 'articlesGridSize', 'modelService', '$rootScope'];

    function WeightController ($scope, $uibModalInstance, productNatureService, productCardDto, model, productNature, userSettings, articlesGridSize, modelService, $rootScope) {

        model.articles = model.items;
        model.articles.forEach(function(article) {
            initWeight(article);
        });

        $scope.floatTheadDimensionsOptions = {
            top: 85,
            position: 'auto',
            autoReflow: true,
            scrollContainer: true
        };


        function initModel() {
            if (!model.capacityValue || !model.capacityUnit) {
                model.capacityUnit = null;
                model.capacityValue = null;
            }
            if (!_(model.articles).isEmpty()) {
                var allArticlesSelected = _(model.articles).filter(function (article) {
                    return !!article.balisage;
                });
                if (!allArticlesSelected || !_(allArticlesSelected).isEmpty()) {
                    model.defaultSignKitArticleId = _(allArticlesSelected).first().code;
                }
            }
        }

        initModel();

        $scope = _($scope).extend({
            productCardDto: productCardDto,
            model: model,
            productNature: productNature,
            dimensionType: productNatureService.getProductNatureType(productNature),
            checkDisabledState: checkDisabledState,
            canEdit: !$rootScope.migratedLocales.some(ml => ml === productCardDto.locale)
                || userSettings.hasRole('ROLE_ADMIN')
                || !$rootScope.migratedLocales,
            isAdmin: isAdmin,
            close: close,
            submit: submit
        });

        function checkDisabledState (expectedRole) {
            return !$scope.productCardDto.smFamily.universeCode || !userSettings.hasRole(expectedRole);
        }

         function isAdmin () {
            return userSettings.hasRole('ROLE_ADMIN');
         }


        function submit () {
            modelService.cleanDimensions($scope.productNature, $scope.model);
            $uibModalInstance.close($scope.model);
        }

        $scope.changeDefaultSignKitArticleId = function (selectedArticle) {
            if (selectedArticle.balisage) {
                $scope.model.defaultSignKitArticleId = selectedArticle.code;
            }
            else {
                $scope.model.defaultSignKitArticleId = null;
            }
            _($scope.model.items).each(function (article) {
                article.balisage = (article.code === $scope.model.defaultSignKitArticleId);
            });

        };


        $scope.updateWeight = function (article) {
            article.weightInputField = article.weightInput;
        };

        function initWeight(article) {
            if (!article.weightInput) {
                article.weightInput = article.weightReceived;
            }
        }

        $scope.showWeight = function (article) {
            if (!article.weightReceived) {
                return 'product.article.dimensions.weight.neverReceived';
            }
            if (!article.weightInputField) {
                return 'product.article.dimensions.weight.received';
            }
            return 'product.article.dimensions.weight.received.modify';
        };

        $scope.dimensionIsDisabled = true;
        $scope.$watch('model.metadatas.paceGridSize', function (paceGridSize) {
           var hasRight = !$scope.checkDisabledState('ROLE_CUD');
           var isAdministrator = $scope.isAdmin;
           $scope.dimensionIsDisabled = !hasRight || (!isAdministrator && paceGridSize);
       });

    }

})();
