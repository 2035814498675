(function () {
	'use strict';

	angular
		.module('spidwebApp')
		.directive('nestedOptions', nestedOptions);

	function nestedOptions() {
		return {
			restrict: 'A',
			scope: {
                dataProvider: '=repo',
                childrenProperty: '=',
                idProperty: '=',
                labelProperty: '=',
                labelFunction: '&'
			},
			link: function (scope, element) {

                /**
                 * Version avec les 3 sous niveaux, mais on perd le lien avec ng-model :/

                function generateChildrenElements(datas) {
                    var elements = [];

                    _(datas).each(function (data) {
                        var children = data[scope.childrenProperty];
                        var id = data[scope.idProperty];
                        var label = data[scope.labelProperty];

                        if (angular.isFunction(scope.labelFunction)) {
                            label = scope.labelFunction.apply(this).apply(this, [data]);
                        }

                        if (angular.isDefined(children) && children != null && children.length > 0) {
                            var optgroup = angular.element('<optgroup label="' + label + '" />');
                            var options = generateChildrenElements(children);
                            optgroup.append(options);
                            elements.push(optgroup);
                        }
                        else {
                            var option = angular.element('<option value="' + id + '">' + label + '</option>');
                            elements.push(option);
                        }
                    });
                    return elements;
                }*/

                /**
                 * Version avec le dernier sous niveaux uniquement, mais le lien avec ng-model est présent
                 */
                function generateChildrenElements2(datas) {
                    var html = '';

                    _(datas).each(function (data) {
                        var children = data[scope.childrenProperty];
                        var id = data[scope.idProperty];
                        var label = data[scope.labelProperty];

                        if (angular.isFunction(scope.labelFunction)) {
                            label = scope.labelFunction.apply(this).apply(this, [data]);
                        }

                        if (angular.isDefined(children) && children != null && children.length > 0) {
                            var options = generateChildrenElements2(children);
                            html += '<optgroup label="' + label + '">' + options + '</optgroup>';
                        }
                        else {
                            html += '<option value="' + id + '">' + label + '</option>';
                        }
                    });
                    return html;
                }

                element.html(generateChildrenElements2(scope.dataProvider));
                //$compile(element.contents())(scope);
			}
		};
	}

})();
