(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('lastSearchService', lastSearchService);

    lastSearchService.$inject = ['$rootScope', '$state'];

    function lastSearchService($rootScope, $state) {

        // Holds the state and params of the last search
        var lastSearch = {
            state: null,
            params: null
        };
        // Event emitted when last search is available
        var lastSearchEventName = 'lastSearchAvailable';

        function getLastSearchEventName() {
            return lastSearchEventName;
        }

        // Listens to detect when searches are done
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
            if (toState.name === 'smVersionSearch' ||
                toState.name === 'smVersionSearchByCode') {
                lastSearch.state = toState;
                lastSearch.params = toParams;
                // Send event through app
                $rootScope.$broadcast(getLastSearchEventName());
            }
        });

        // Goes to last search using a version date (for smVersionSearch, using date range)
        function goToLastSearchWithVersionDate(date) {
            if (lastSearch.state.name === 'smVersionSearch') {
                var versionDate = moment.utc(date, 'YYYYww');
                lastSearch.params.from = versionDate.format('YYYY-MM-DD');
                lastSearch.params.to = versionDate.add(1, 'w').format('YYYY-MM-DD');
            }
            return goToLastSearch();
        }

        // Goes to the last search
        function goToLastSearch() {
            return $state.go(lastSearch.state.name, lastSearch.params, {reload: true});
        }

        return {
            goToLastSearch: goToLastSearch,
            goToLastSearchWithVersionDate: goToLastSearchWithVersionDate,
            getLastSearchEventName: getLastSearchEventName
        };
    }
})();
