(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('StructurationController', StructurationController);

    StructurationController.$inject = ['$scope', 'structurationService', '$timeout'];

    function StructurationController($scope) {

        $scope.newStructuration = {};
        $scope.structurationToAdd = undefined;
        $scope.newStructurationValues = {};

        $scope.addValueStructuration = function(structurationToAdd) {
            $scope.structurationToAdd.structurationValues.push(structurationToAdd);
        };

        $scope.removeValueStructuration = function(structurationToRemove) {
            var index = $scope.productCardDto.segmentation.types.indexOf(structurationToRemove);
            $scope.structurationToAdd.structurationValues.splice(index, 1);
        };

        $scope.addStructuration = function (structuration) {
            $scope.newStructurationValues = angular.copy(structuration.structurationValues);
            $scope.structurationToAdd = angular.copy(structuration);
            $scope.structurationToAdd.structurationValues = [];
        };

        $scope.getValuesFromType = function (typeId, filter) {
            var structurationType = _($scope.structurations).chain().filter(function (type) {
                return type.structurationTypeId === typeId;
            }).first().value();

            if (filter) {
                var dtoValues = _($scope.productCardDto.segmentation.types).chain().filter(function (type) {
                    return type.structurationTypeId === typeId;
                }).first().value();

                var selectedValues = _(dtoValues.structurationValues).chain().map(function (dtoValue) {
                    return dtoValue.structurationValueId;
                }).value();

                return _(structurationType.structurationValues).chain().filter(function (value) {
                    return selectedValues.indexOf(value.structurationValueId) === -1;
                }).value();
            } else {
                return structurationType.structurationValues;
            }
        };

        $scope.getStructuration = function () {
            var selectedTypesId = _($scope.productCardDto.segmentation.types).chain().map(function (dtoType) {
                return dtoType.structurationTypeId;
            }).value();

            return _($scope.structurations).chain().filter(function (structurationType) {
                return selectedTypesId.indexOf(structurationType.structurationTypeId) === -1;
            }).value();
        };

        $scope.addStructurationToDto = function () {
            $scope.productCardDto.segmentation.types.push($scope.structurationToAdd);
            $scope.structurationsChanged = true;
            $scope.structurationToAdd = undefined;
            $scope.newStructuration.selected = undefined;
            $scope.newStructurationValues.selected = undefined;
        };

        $scope.removeStructuration = function (deleteStructuration) {
            var index = $scope.productCardDto.segmentation.types.indexOf(deleteStructuration);
            $scope.productCardDto.segmentation.types.splice(index, 1);
            $scope.structurationsChanged = true;
        };

        $scope.changeStructurationIsSignKit = function (selectedStructuration) {
            selectedStructuration.signKit = !selectedStructuration.signKit;
            _($scope.productCardDto.segmentation.types)
                .chain()
                .filter(function (structurationType) {
                    return structurationType.structurationTypeId !== selectedStructuration.structurationTypeId;
                })
                .each(function (structurationType) {
                    structurationType.signKit = false;
                });
        };
    }
})();
