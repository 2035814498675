(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('deletionRequestController', deletionRequestController);

    deletionRequestController.$inject = ['$scope', 'axiosService', 'envService'];

    function deletionRequestController ($scope, axiosService, envService) {

        $scope.models = [];
        $scope.model = {};
        $scope.addList = addList;
        $scope.add = add;
        $scope.send = send;
        $scope.reset = reset;

        //8203355;201301
        var correctRowFormat = new RegExp('[0-9]+;20[0-9]{4}');

        function addList (textToParse) {
            if (!textToParse) {
                return;
            }
            var rows = textToParse.split('\n');
            var badRows = [];
            var goodRows = [];

            rows.forEach(function (row) {
                if (row.match(correctRowFormat)) {
                    goodRows.push(row);
                } else {
                    badRows.push(row);
                }
            });

            $scope.textToParse = '';
            _(badRows).forEach(function (row) {
                $scope.textToParse = $scope.textToParse + row + '\n';
            });

            var models = _(goodRows).map(function (row) {
                var cells = row.split(';');
                return {
                    code: cells[0],
                    startWeek: cells[1],
                    status: 'admin.deletionRequest.todo'
                };
            });
            $scope.models = $scope.models.concat(models);
        }

        function add () {
            $scope.model.status = 'admin.deletionRequest.todo';
            $scope.models.push($scope.model);
            $scope.model = {};
        }

        function send () {

            _($scope.models)
                .chain()
                .where({status: 'admin.deletionRequest.todo'})
                .forEach(function (modelToDelete) {

                modelToDelete.status = 'admin.deletionRequest.pending';

                    axiosService.delete(envService.getModelsContext() + '/' + modelToDelete.code + '/' + modelToDelete.startWeek,
                        {headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Accept': 'application/json;charset=UTF-8'
                    }
                }).then(function (response) {
                    modelToDelete.status = response.data;
                }).catch(function (response) {
                    modelToDelete.status = response.data || 'admin.deletionRequest.error';
                })
                .finally(function () {
                    $scope.$apply();
                });

            });

        }

        function reset () {
            $scope.models = [];
        }

    }

})();
