(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('wediaService', wediaService);

    wediaService.$inject = ['axiosService', 'envService', '$filter', 'parameterService', '$window'];

    function wediaService(axiosService, envService, $filter, parameterService, $window) {
        var wediaPrivateKey = null;
        var sortByName = false;
        return {

            initWediaPrivateKey: function () {
                parameterService.getParameter('core', 'wedia.private.key').then(function (resp) {
                    wediaPrivateKey = resp;
                });
                parameterService.getParameter('featureFlags', 'pixl.autoaffect.filterOnName').then(function (resp) {
                    sortByName = resp === 'true';
                });
            },
            getCartSize: function (criteria, timeout) {
                return axiosService.get(
                    envService.getPixlUrl() + '/getCartSize',
                    {
                        params: {
                            searchCriteria: criteria.search_value,
                            type: criteria.search_type,
                            mediaType: criteria.media_type
                        },
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        },
                        timeout: timeout || 10000
                    })
                    .then(function (response) {
                        return Promise.resolve(response.data);
                    });
            },
            getCartContent: function (criteria, timeout) {
                return axiosService.get(
                    envService.getPixlUrl() + '/getCartContent',
                    {
                        params: {
                            searchCriteria: criteria.search_value,
                            type: criteria.search_type,
                            mediaType: criteria.media_type
                        },
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        },
                        timeout: timeout || 60000
                    })
                    .then(function (response) {
                        return Promise.resolve(mapPixlResultToCart(response.data));
                    });
            },

            getMediaURL: function (media, size) {
                if (angular.isUndefined(media)) {
                    return undefined;
                }

                var mediaURL, pixlId, key, prefix;

                switch (media.type) {
                    case 'PACKSHOT':
                        pixlId = media.pixlId;
                        prefix = media.prefix;
                        key = md5(prefix + pixlId, wediaPrivateKey);
                        mediaURL = envService.getCdnBaseUrl() + '/' + prefix + pixlId + '/k$' + key + (size ? '/' + size + 'x' + size : '') + '/packshot.jpg';
                        break;
                    case 'MEDIA360':
                        mediaURL = 'common/styles/img/360.jpg';
                        break;
                    case 'FILM':
                        mediaURL = '/common/styles/img/video.png';
                        break;
                    case 'COMMUNICATION':
                        pixlId = media.pixlId;
                        key = md5('b' + pixlId, wediaPrivateKey);
                        mediaURL = envService.getCdnBaseUrl() + '/b' + pixlId + '/k$' + key + (size ? '/' + size + 'x' + size : '') + '/packshot.jpg';
                        break;

                    default:
                        throw new Error('Medias - Cannot get the media url due to an invalid media type.');
                }

                return mediaURL;
            },

            getDownloadURL: function (media) {
                var pixlId;
                var key;
                var prefix;
                if (media.type === 'PACKSHOT') {
                    pixlId = media.pixlId;
                    prefix = media.prefix;
                    key = md5(prefix + pixlId, wediaPrivateKey);
                    return envService.getCdnBaseUrl() + '/' + prefix + pixlId + '/' + $window.encodeURIComponent(media.name) + '?k=' + key;
                }
                if (media.type === 'MEDIA360') {
                    pixlId = media.pixlId;
                    prefix = media.prefix;
                    key = md5(prefix + pixlId, wediaPrivateKey);
                    return envService.getCdnBaseUrl() + '/' + prefix + pixlId + '/' + $window.encodeURIComponent(media.name) + '?k=' + key;
                }

            },
            hasExpired: function (media) {
                return moment().isAfter(moment(media.validateToDate));
            },
            willExpireInMonths: function (media, months) {
                return moment(media.validateToDate).isBetween(moment(), moment().add(months, 'months'));
            },
            mapPixlResultToCart: function (cartItems) {
                return mapPixlResultToCart(cartItems, sortByName);
            }
        };
    }

    function mapPixlResultToCart(cartItems, sortByName) {
        _(cartItems).each(function (cartItem) {
            cartItem.pixlId = cartItem.id;
            cartItem.id = null;
            cartItem.cartTimestamp = cartItem.modified;
            cartItem.name = cartItem.name + ' | ' + (cartItem.subType != null ? cartItem.subType : cartItem.mediaType);
            cartItem.validateFromDate = cartItem.rightsstartdate;
            cartItem.validateToDate = cartItem.rightsenddate;
        });

        var cart = {
            packshots: _(cartItems)
                .chain()
                .filter(function (item) {
                    return item.mediaType === 'PHOTO';
                })
                .each(function (item) {
                    item.type = 'PACKSHOT';
                })
                .value(),
            images360: _(cartItems)
                .chain()
                .filter(function (item) {
                    return item.mediaType === '360' || item.mediaType === 'ANIM';
                })
                .each(function (item) {
                    item.type = 'MEDIA360';
                })
                .value(),
            videos: _.uniq(_(cartItems)
                .chain()
                .filter(function (item) {
                    return item.mediaType === 'VIDEO';
                })
                .each(function (item) {
                    item.containerId = item.videoid;
                    item.videos = _(item.languages).map(function (videoItem) {
                        return {
                            name: this.item.name,
                            validateFromDate: this.item.validateFromDate,
                            validateToDate: this.item.validateToDate,
                            langue: videoItem.code.toUpperCase(),
                            brightcoveId: videoItem.ref,
                            pixlId: this.item.pixlId,
                            type: this.item.subType
                        };
                    }, {item: item});

                    item.type = 'FILM';
                })
                .value(), 'pixlId')
        };


        var packshots =
            _(cart.packshots)
                .filter(function (packshot) {
                    return packshot.subType == 'PSHOT';
                });

        var packshotScene =
            _(cart.packshots)
                .filter(function (packshot) {
                    return packshot.subType == 'SCENE';
                });

        var others = _(cart.packshots)
            .filter(function (packshot) {
                return packshot.subType != 'SCENE' && packshot.subType != 'PSHOT';
            });

        var finalPackshots = [];

        if (packshots) {
            finalPackshots = finalPackshots.concat(packshots);
        }
        if (packshotScene) {
            finalPackshots = finalPackshots.concat(packshotScene);
        }
        if (others) {
            finalPackshots = finalPackshots.concat(others);
        }
        if(sortByName) {
            finalPackshots.sort((itemA, itemB) => itemA.name.toLowerCase() > itemB.name.toLowerCase() ? 1 : -1);
            cart.images360.sort((itemA, itemB) => itemA.name.toLowerCase() > itemB.name.toLowerCase() ? 1 : -1);
            cart.videos.sort((itemA, itemB) => itemA.name.toLowerCase() > itemB.name.toLowerCase() ? 1 : -1);
        }
        return {
            packshots: finalPackshots || [],
            images360: cart.images360 || [],
            videos: cart.videos || []
        };
    }

})();
