(function () {
    'use strict';

    angular.module('spidwebApp').controller('migratedLocalesController', migratedLocalesController);

    migratedLocalesController.$inject = ['$scope', 'parameterService', 'envService', 'axiosService', 'toastService'];

    function migratedLocalesController($scope, parametersService, envService, axiosService, toastService) {
        $scope.locales = [];
        $scope.migratedLocales = [];
        $scope.currentLocale = null;
        $scope.loading = true;

        const CONTEXT = 'featureFlags';
        const NAME = 'locales';

        $scope.loadData = function () {
            axiosService
                .get(envService.getUrlLanguageDependencies(), {
                    withCredentials: true,
                    headers: {
                        Accept: 'application/json',
                    },
                    cache: false,
                })
                .then(function (localesResponse) {
                    $scope.locales = [...new Set(localesResponse.data.map(item => item.locale))].sort((a, b) => a > b ? 1 : -1).map(name => ({
                        name,
                        migrated: false
                    }));
                    parametersService.getParameter(CONTEXT, NAME).then(function (migratedLocalesResponse) {
                        $scope.migratedLocales = migratedLocalesResponse.split(';');
                        $scope.locales.forEach(locale => {
                            if ($scope.migratedLocales.includes(locale.name)) {
                                locale.migrated = true;
                            }
                        });
                    }).catch(function (error) {
                        console.error(error);
                        toastService.displayTimedToast('Error while getting migrated locales', 'error', 3000);
                    })
                    .finally(function () {
                        $scope.loading = false;
                        $scope.$apply();
                    });
                })
                .catch(function (error) {
                    console.error(error);
                    toastService.displayTimedToast('Error while getting language dependencies', 'error', 3000);
                });
        };

        function init() {
            $scope.loadData();
        }

        $scope.toggleMigrated = function (locale) {
            $scope.loading = true;

            if (!locale.migrated) {
                $scope.migratedLocales = $scope.migratedLocales.filter(item => item !== locale.name);
            } else {
                $scope.migratedLocales.push(locale.name);
            }

            const data = {
                context: CONTEXT,
                name: NAME,
                value: $scope.migratedLocales.join(';')
            };

            parametersService.saveParameter(data)
                .catch(function (error) {
                    console.error(error);
                    toastService.displayTimedToast('Error while saving migrated locales', 'error', 3000);
                })
                .finally(function () {
                    $scope.loading = false;
                    $scope.$apply();
                });
        };

        $scope.localeAsEmojis = function (locale) {
            let country = locale.name.split('_')[1];
            if (country === 'INT') {
                return '🌍';
            }
            return locale.name.split('_')[1].split('').map(letter => letter.charCodeAt(0) % 32 + 0x1F1E5)
                .map(emojiCode => String.fromCodePoint(emojiCode))
                .join('');
        };

        init();


    }
})();
