(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ChooseYourLocaleController', ChooseYourLocaleController);

    ChooseYourLocaleController.$inject = ['$scope', '$uibModalInstance', '$window', 'userSettings', 'locales'];

    function ChooseYourLocaleController ($scope, $uibModalInstance, $window, userSettings, locales) {

        $scope.locales = locales;
        $scope.profilLocale = {
            defaultWorkingLocale: userSettings.getSelectedWorkingLocale()
        };

        $scope.submit = function () {
            userSettings.setSelectedWorkingLocale($scope.profilLocale.defaultWorkingLocale);
            $window.location.reload();
            $uibModalInstance.close();
        };
    }

})();
