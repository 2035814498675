(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('UploaderController', UploaderController)
        .directive('uploader', uploader);

    function uploader() {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'common/directives/uploader/uploader.html',
            scope: {
                category: '@',
                id: '@',
                contextURL: '@'
            },
            controller: UploaderController
        };
    }

    UploaderController.$inject = ['$scope', '$rootScope', 'FileUploader', '$filter', '$timeout'];

    function UploaderController($scope, $rootScope, FileUploader, $filter, $timeout) {

        $scope.errorMessage = null;

        $scope.uploader = new FileUploader({
            url: $scope.contextURL,
            withCredentials: true
        });

        // FILTERS

        // a sync filter
        $scope.uploader.filters.push({
            name: 'syncFilter',
            fn: function(/*item {File|FileLikeObject}*/) {
                return this.queue.length < 10;
            }
        });

        // an async filter
        $scope.uploader.filters.push({
            name: 'asyncFilter',
            fn: function(item /*{File|FileLikeObject}*/, options, deferred) {
                $scope.errorMessage = null;
                $timeout(deferred.resolve, 1e3);
            }
        });

        $scope.uploader.onErrorItem = function(fileItem, response, status, headers) {
            console.info('onErrorItem', fileItem, response, status, headers);

            if (response.exception === 'org.springframework.web.multipart.MaxUploadSizeExceededException') {
                $scope.errorMessage = $filter('translate')('error.upload.file.size', {
                    fileSize: parseInt(response.message, 10) / 1000
                });
            }
            else {
                $scope.errorMessage = response.message;
            }
            $scope.uploader.clearQueue();
        };

        $scope.uploader.onError = function(response, status, headers) {
            console.info('onError', response, status, headers);
            $scope.uploader.clearQueue();
        };

        $scope.uploader.onSuccessItem = function(item, response) {
            $rootScope.$broadcast('uploadSuccess', response);
        };

    }

})();
