(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('careInstructions', careInstructions);

    careInstructions.$inject = [];

    function careInstructions () {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/infoPlus/careInstructions/careInstructions.html',
            scope: {
                careInstructions: '=',
                oldCareInstructions: '=',
                careInstructionsRepository: '=',
                disabled: '@'
            },
            link: function (scope) {

                scope.readOnly = (scope.disabled === 'true');

                scope.careInstructions = scope.careInstructions || [];

                scope.displayOldCareInstructions = scope.oldCareInstructions && !scope.careInstructions.length;

                scope.toggleCollapse = function () {
                    scope.isCollapsed = !scope.isCollapsed;
                };

                scope.$watch('disabled', function(){
                    scope.readOnly = (scope.disabled === 'true');
                });

            }
        };
    }

})();
