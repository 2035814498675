(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('SignEasyController', SignEasyController);

    SignEasyController.$inject = ['$scope', '$uibModalInstance', 'model', '$window', 'formats', 'smLocale', 'envService', 'modelService'];

    function SignEasyController ($scope, $uibModalInstance, model, $window, formats, smLocale, envService, modelService) {

        $scope.pdfBaseUrl = envService.getModelsContext() + '/' + model.code + '/signKit?locale=' + smLocale + '&versionDate=' + encodeURIComponent(model.versionDate);

        $scope.formats = formats;

        $scope.category = 'MODEL';

        $scope.formatCriteria = {
            format: null,
            type: null,
            key: null,
            price: 100.0
        };

        function selectDefault() {
            var selectedFormat = _(formats).find(function (signEasyFormat) {
                return signEasyFormat.category === $scope.category && signEasyFormat.defaultFormat;
            });

            if (!selectedFormat) {
                selectedFormat = _(formats).find(function (signEasyFormat) {
                    return signEasyFormat.category === $scope.category && signEasyFormat.type.indexOf('PERMANENT') > -1 && signEasyFormat.format.toUpperCase().indexOf('A5') > -1;
                });
            }
            $scope.formatCriteria = {
                format: selectedFormat.format,
                type: selectedFormat.type,
                key: selectedFormat.format + '-' + selectedFormat.signEasyKey,
                price: 100.0
            };
        }

        selectDefault();

        $scope.changeCategory = function () {
            $scope.formatCriteria.format = null;
            selectDefault();
        };

        $scope.getDefaultformat = function () {
            modelService.getDefaultFormat(model.code).then(function (formatFound) {
                if (formatFound != null || formatFound != ''){
                    $scope.formatCriteria = {
                        format: formatFound.template,
                        type: formatFound.type,
                        key: formatFound.template + '-' + formatFound.type,
                        price: 100.0
                    };
                } else {
                    selectDefault();
                }

            });
        };

    }

})();
