(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('masterdatasCache', masterdatasCache);

    masterdatasCache.$inject = ['$cacheFactory'];

    function masterdatasCache ($cacheFactory) {
        return $cacheFactory('masterdatas');
    }

})();
