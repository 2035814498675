(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('spidModal', spidModal);

    spidModal.$inject = ['$uibModal'];

    function spidModal($uibModal) {
        return $uibModal;
    }
})();
