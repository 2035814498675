(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('displayNumberStoreString', displayNumberStoreString);

    function displayNumberStoreString () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                var stringToNumber = function (text) {
                    return parseInt(text);
                };
                var numberToString = function (entier) {
                    return entier.toString();
                };

                ngModel.$parsers.push(numberToString);
                ngModel.$formatters.push(stringToNumber);
            }
        };
    }

})();
