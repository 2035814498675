(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('SmComparatorController', SmComparatorController)
        .constant('smComparatorResolver', smComparatorResolver());

    SmComparatorController.$inject = ['$scope', '$state', '$stateParams', 'smSeasons', 'envService', 'structurations', 'sportsRepository', 'structurationService'];

    function SmComparatorController($scope, $state, $stateParams, smSeasons, envService, structurations, sportsRepository, structurationService) {

        $scope.smSeasons = smSeasons;
        $scope.contentsURL = envService.getContentsURL();

        $scope.smSeasons.forEach(function (smSeason) {
            buildSports(smSeason);
        });

        function findSportById(id) {
            var sportCache;
            sportsRepository.forEach(function (sportGroup) {
                sportGroup.sports.forEach(function (sport) {
                    if (sport.id === id) {
                        sportCache = sport;
                        sportCache.fatherId = sportGroup.id;
                        sportCache.fatherName = sportGroup.name;
                    }
                });
            });
            return sportCache;
        }

        function buildSports(smSeason) {
            var sportsArray = [];
            smSeason.sports.forEach(function (id) {
                var sportCache = findSportById(id);
                if (sportCache) {
                    var sportNew = {
                        id: id,
                        name: sportCache.name,
                        fatherId: sportCache.fatherId,
                        fatherLabel: sportCache.fatherName
                    };
                    sportsArray.push(sportNew);
                }
            });
            smSeason.sports = sportsArray;
            sortSports(smSeason.sports);
        }

        function sortSports(cardSports) {
            cardSports.sort(
                function(a, b) {
                    if (a.sportResponsible) {
                        return -1;
                    }
                    if (b.sportResponsible) {
                        return 1;
                    }
                    return (a.fatherLabel || '').localeCompare(b.fatherLabel || '');
                }
            );
        }

        $scope.init = function() {

            var alfrescoIds = ($stateParams.selectedSmVersionIdsWithLocale.split(',')).map(function (smVersionIdWithLocale) {
                return smVersionIdWithLocale.split('_')[0];
            });

            _(smSeasons).each(function (smSeasonFamily) {

                var relevantModel = _(smSeasonFamily.siblings)
                    .chain()
                    .find(function (model) {
                        return _(alfrescoIds).contains(model.alfrescoId);
                    })
                    .value();

                if (!relevantModel) {
                    relevantModel = _(smSeasonFamily.siblings).chain().sortBy('code').first().value();
                }

                smSeasonFamily.relevantModel = relevantModel;
                smSeasonFamily.$key = smSeasonFamily.smSeasonId + '_' + smSeasonFamily.locale;
            });

            $scope.floatTheadSmOptions = {
                top: 50,
                position: 'fixed',
                autoReflow: true,
                responsiveContainer: function($table){
                    return $table.closest('.table-responsive');
                }
            };

            $scope.scroll = function (score) {
                var infosModelsContainer = angular.element('#smComparatorContainer');
                var start = infosModelsContainer.scrollLeft();
                infosModelsContainer.scrollLeftAnimated(start + score);
            };

            $scope.structurations = structurations;


            initStructurations();
            initCharacteristics();
            initUserBenefits();
        };


        function initCharacteristics() {
            $scope.countCharacteristics =
                _($scope.smSeasons).chain()
                    .map(function (smSeason) {
                        return (_(smSeason.characteristics).isEmpty() ? 0 : smSeason.characteristics.length );
                    })
                    .max()
                    .value();
            $scope.arrayCountCharacteristics = new Array($scope.countCharacteristics);


            _($scope.smSeasons).each(function (smSeason) {
                for (var ord = 1; ord <= $scope.countCharacteristics; ord++) {
                    var existingCharacteristic = _(smSeason.characteristics).find(function (characteristic) {
                        return characteristic.characteristicOrder === ord;
                    });
                    if (!existingCharacteristic) {
                        var emptyCharacteristic = {
                            characteristicType: null,
                            characteristicValue: null,
                            characteristicOrder: ord
                        };
                        smSeason.characteristics.splice(ord - 1, 0, emptyCharacteristic);
                    }
                }
            });
        }

        function initUserBenefits() {
            $scope.countUserBenefits =
                _($scope.smSeasons).chain()
                    .map(function (smSeason) {
                        return (_(smSeason.userBenefits).isEmpty() ? 0 : smSeason.userBenefits.length );
                    })
                    .max()
                    .value();
            $scope.arrayCountUserBenefits = new Array($scope.countUserBenefits);


            _($scope.smSeasons).each(function (smSeason) {
                for (var ord = 1; ord <= $scope.countUserBenefits; ord++) {
                    var existingUserBenefits = _(smSeason.userBenefits).find(function (userBenefit) {
                        return userBenefit.userBenefitOrder === ord;
                    });
                    if (!existingUserBenefits) {
                        var emptyUserBenefit = {
                            userBenefitId: null,
                            userBenefitOrder: ord
                        };
                        smSeason.userBenefits.splice(ord - 1, 0, emptyUserBenefit);
                    }
                }
            });
        }

        $scope.openSmVersion = function (smVersion) {
            $state.go('productCardNg', {
                smCode: smVersion.smCode,
                versionDate: smVersion.versionDate,
                smLocale: smVersion.locale,
            });
        };

        function initStructurations() {
            var allStructurationsValueIds =
                _($scope.smSeasons).chain()
                    .map(function (smSeason) {
                        return smSeason.structurationValueIds;
                    })
                    .flatten()
                    .uniq()
                    .value();
            $scope.allSmStructurations = structurationService.getModelStructuration($scope.structurations, allStructurationsValueIds);
            _($scope.smSeasons).each(function (smSeason) {
                smSeason.structurations = structurationService.getModelStructuration($scope.structurations, smSeason.structurationValueIds);
            });


            $scope.countStructurationTypesByKey = function (structurationsData, structurationId) {
                return $scope.getStructurationTypesByKey(structurationsData, structurationId).length;
            };
        }

        function getStructurationTypesByKey(structurationsData, structurationId) {
            return _(structurationsData)
                .chain()
                .filter(function (ms) {
                    return angular.isDefined(structurationId) ? ms.structurationId === structurationId : true;
                })
                .map(function (ms) {
                    return ms.structurationTypes;
                })
                .flatten()
                .value();
        }

        function getStructurationValuesByType(structurationsData, structurationId, structurationTypeId) {
            var structurationTypes = getStructurationTypesByKey(structurationsData, structurationId);
            if (!_(structurationTypes).isEmpty()) {
                return _(structurationTypes)
                    .chain()
                    .filter(function (structurationType) {
                        return angular.isDefined(structurationTypeId) ? structurationType.structurationTypeId === structurationTypeId : true;
                    })
                    .map(function (structurationType) {
                        return structurationType.structurationValues;
                    })
                    .flatten()
                    .value();
            }
            return [];

        }

        $scope.getStructurationTypesByKey = getStructurationTypesByKey;
        $scope.getStructurationValuesByType = getStructurationValuesByType;
    }

    function smComparatorResolver() {
        return {
            structurations: ['$stateParams', 'structurationService', function ($stateParams, structurationService) {
                return structurationService.get('fr_FR');
            }],
            sportsRepository: ['$stateParams', 'sportService', function ($stateParams, sportService) {
                return sportService.getSports($stateParams.locale);
            }],
            smSeasons: ['$stateParams', '$q', 'superModelService', function ($stateParams, $q, superModelService) {

                if (!$stateParams.selectedSmVersionIdsWithLocale) {
                    return null;
                }

                var rowLanguagesList = [];
                $stateParams.selectedSmVersionIdsWithLocale.split(',').forEach(function (smVersionIdWithLocale) {
                    var smVersion = smVersionIdWithLocale.split('_')[0];
                    var language = smVersionIdWithLocale.split('_')[1];
                    var country = smVersionIdWithLocale.split('_')[2];
                    rowLanguagesList.push(superModelService.getSuperModelsSeasonBySmId(smVersion, language, country));
                });


                return $q.all(rowLanguagesList).then(function (responses) {
                    return _(responses).map(function (response) {
                        return response.data;
                    });
                });

            }]

        };
    }
})();
