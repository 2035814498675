(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('declinaisonService', declinaisonService);

    function declinaisonService () {
        return {
            completeDeclinaison: function (sibling) {
                for (var i = 1; i <= 6; i++) {

                    var existingDeclinaison = _(sibling.declinaisons).find(function (declinaison) {
                        return declinaison.declinaisonOrder == i;
                    });

                    if (!existingDeclinaison) {
                        var emptyDeclinaison = {
                            name: null,
                            value: null,
                            declinaisonOrder: i
                        };
                        emptyDeclinaison = emptyDeclinaison.declinaisonOrder;
                        sibling.declinaisons.splice(i - 1, 0, emptyDeclinaison);
                    }
                }
            }
        };
    }
})();
