(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('pinAffix', pinAffix);

    pinAffix.$inject = ['$window', '$document'];

    function pinAffix ($window, $document) {
        return {
            restrict: 'A',
            scope: {
                pinAffixOffset: '=',
                pinHeightId: '@'
            },
            link: function(scope, element/*, attrs*/) {

                var currentPosition = element.offset().top;

                scope.pinAffixOffset = angular.isDefined(scope.pinAffixOffset) ? scope.pinAffixOffset : 0;


                scope.pinHeightId = angular.isDefined(scope.pinHeightId) ? scope.pinHeightId : null;

                function handleScoll(/*event*/) {
                    if (scope.pinHeightId) {
                        var tHeadHeightElement = angular.element($document[0].querySelector('#' + scope.pinHeightId));
                        var bilouteElement = angular.element($document[0].querySelector('#navigate_container'));
                        if (tHeadHeightElement && bilouteElement && bilouteElement[0].offsetHeight != tHeadHeightElement[0].offsetHeight) {
                            bilouteElement.css('height', (tHeadHeightElement[0].offsetHeight+1) + 'px');
                        }
                    }

                    var isFixed = element.hasClass('pin-affix');

                    currentPosition = isFixed ? currentPosition : element.offset().top; // S'il est 'fixed', l'offset top est bité

                    var currentScrollPosition = angular.element($window).scrollTop();

                    var needToFix = (currentScrollPosition + scope.pinAffixOffset) > currentPosition;


                    if (needToFix && !isFixed) {
                        element.addClass('pin-affix');
                    }
                    else if (!needToFix && isFixed) {
                        element.removeClass('pin-affix');
                    }
                }
                angular.element($window).on('scroll', null, handleScoll);

                scope.$on('$destroy', function (/*event*/) {
                    angular.element($window).off('scroll', null, handleScoll);
                });

            }
        };
    }

})();
