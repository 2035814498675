(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('brightcoveService', brightcoveService);

    brightcoveService.$inject = ['envService'];

    function brightcoveService (envService) {
        return {
            getUrl: function () {
                return envService.getBrightcoveUrl();
            },
            getAccountId: function () {
                return envService.getCurrentEnvironmentProperty('brightCoveAccountId');
            },
            getPlayerId: function () {
                return envService.getCurrentEnvironmentProperty('brightCovePlayerId');
            }
        };
    }

})();
