/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
'use strict'; // eslint-disable-line

var envProperties = {
    // eslint-disable-line
    current: null,
    version: '12.37.2',
    dev: {
        base: 'http://localhost:8080',
        fwkSecurityLogin:
            'http://localhost:8080/spid-webapp/js/fwk/security/login.html',
        serverContext: '/spid-webapp/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.preprod.decathlon.net',
        GAAccount: 'UA-111508656-1',
        beamerAccount: '',
        hotjarAccount: '',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'preprod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.preprod.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.stg.decathlon.net/',
        spoBaseUrl: 'https://spo.preprod.decathlon.net/',
        apiKey: '45305342-cc0b-40bf-a89c-e446b8b91001',
        authAuthority: 'https://preprod.idpdecathlon.oxylane.com',
        authClientId: 'Cfe283ec7facd262dd9f5744311fb6292a3378e3a',
        authChallengeMethod: 'S256',
        authRedirectUri: 'http://localhost:6006',
        authScope: ['openid', 'profile'],
        authAuthServer: 'https://spid-auth-ppd.decathlon.net/auth-server',
        localeLabUrl: 'https://spid-labs-preprod.decathlon.net'
    },
    int: {
        base: 'https://spid-integ.preprod.subsidia.org',
        fwkSecurityLogin:
            'https://spid-integ.preprod.subsidia.org/spid-webapp/js/fwk/security/login.html',
        serverContext: '/spid-webapp',
        serverApiContext: '/springMVC',
        contentsURL: 'https://contents-spid.preprod.decathlon.net',
        GAAccount: 'UA-111508656-1',
        beamerAccount: '',
        hotjarAccount: '',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'preprod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.preprod.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.stg.decathlon.net/',
        spoBaseUrl: 'https://spo.preprod.decathlon.net/',
        apiKey: 'cf574810-d58f-4e46-b6fb-7423135ba574',
        authAuthority: 'https://preprod.idpdecathlon.oxylane.com',
        authClientId: 'Cfe283ec7facd262dd9f5744311fb6292a3378e3a',
        authChallengeMethod: 'S256',
        authRedirectUri: 'https://spid-integ.preprod.subsidia.org',
        authScope: ['openid', 'profile'],
        authAuthServer:
            'https://spid-cpe-staging.ig1.dev-01.creation-content-staging-0xx8.decathlon.io/auth-server',
        localeLabUrl: 'https://spid-labs-preprod.decathlon.net',
    },
    preprod: {
        base: 'https://api.preprod.decathlon.net/spid',
        fwkSecurityLogin:
            'https://spid.preprod.subsidia.org/spid-webapp/js/fwk/security/login.html',
        serverContext: '/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.preprod.decathlon.net',
        GAAccount: 'UA-112504779-1',
        beamerAccount: '',
        hotjarAccount: '',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'preprod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.preprod.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.preprod.decathlon.net/',
        spoBaseUrl: 'https://spo.preprod.decathlon.net/',
        apiKey: '45305342-cc0b-40bf-a89c-e446b8b91001',
        authAuthority: 'https://preprod.idpdecathlon.oxylane.com',
        authClientId: 'Cfe283ec7facd262dd9f5744311fb6292a3378e3a',
        authChallengeMethod: 'S256',
        authRedirectUri: 'https://spid-preprod.decathlon.net',
        authScope: ['openid', 'profile'],
        authAuthServer: 'https://spid-auth-ppd.decathlon.net/auth-server',
        localeLabUrl: 'https://spid-labs-preprod.decathlon.net',
    },
    production: {
        base: 'https://api.decathlon.net/spid',
        fwkSecurityLogin:
            'https://spid.decathlon.net/spid-webapp/js/fwk/security/login.html',
        serverContext: '/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.decathlon.net',
        GAAccount: 'UA-112543234-1',
        beamerAccount: 'OBPmBKlR42153',
        hotjarAccount: '3062281',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'prod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.decathlon.net/',
        spoBaseUrl: 'https://spo.decathlon.net/',
        apiKey: 'cd0b4714-3983-48d0-b38a-c6cd349bea61',
        authAuthority: 'https://idpdecathlon.oxylane.com',
        authClientId: 'C1243618fd26f7524d8a6b3928ac02f86e52d2216',
        authChallengeMethod: 'S256',
        authRedirectUri: 'https://spid.decathlon.net',
        authScope: ['openid', 'profile'],
        authAuthServer:
            'https://spid-auth.decathlon.net/auth-server',
    },
    internalProd: {
        base: 'https://api.decathlon.net/spid',
        fwkSecurityLogin:
            'https://spid3.subsidia.org/spid-webapp/js/fwk/security/login.html',
        serverContext: '/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.decathlon.net',
        GAAccount: 'UA-112543234-1',
        beamerAccount: 'OBPmBKlR42153',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'prod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.decathlon.net/',
        spoBaseUrl: 'https://spo.decathlon.net/',
        apiKey: 'cd0b4714-3983-48d0-b38a-c6cd349bea61',
        authAuthority: 'https://idpdecathlon.oxylane.com',
        authClientId: 'C1243618fd26f7524d8a6b3928ac02f86e52d2216',
        authChallengeMethod: 'S256',
        authRedirectUri:
            'https://spid-webapp.creation-content-prod-c638.decathlon.io',
        authScope: ['openid', 'profile'],
        authAuthServer: 'https://spid-auth.decathlon.net/auth-server',
        localeLabUrl: 'https://spid-labs.decathlon.net'
    },
    urls: {
        authUser: 'user/auth',
        brandLdap: 'user/brands',
        brightcoveUrl:
            '//players.brightcove.net/{account_id}/{Player_id}_default/index.min.js',
        tree: 'treeView',
        countryContext: 'country',
        translation: 'translations',
        massActionsContext: 'massiveActions',
        parametersContext: 'parameters',
        fluxContext: 'flux',
        rightsManagementLink: '/fwkbo/index.html',
        logout: '/saml/logout',
        reportingUrl:
            'http://bil.subsidia.org/analytics/saw.dll?dashboard&PortalPath=%2Fshared%2FPassion%20Brands%2F_portal%2FProduct%20communication',
        notationUnits: 'notations',
        zd: 'zd',
        offerdatas: 'offerdata',
        videoAvailableLanguagesURL: 'repository/availableLanguages',
        seasonsContext: 'seasons',
        brandContext: 'brands',
        modelBrand: 'models/{modelCode}/brand',
        mediasContext: 'medias',
        modelsContext: 'models',
        modelSeasonsContext: 'modelSeasons',
        superModelsContext: 'superModels',
        superModelEditProductNature: 'superModels/{smCode}/productNature',
        productCardContext: 'productCard',
        superModelSeasonsContext: 'superModelSeasons',
        superModelSeasonEvents: 'superModelSeasonEvents',
        superModelCreateVersion: 'superModelCreateVersion',
        userBenefitContext: 'userBenefits',
        translationJobsContext: 'translationJobs',
        urlRightToLeftLocale: 'country/rightToLeft',
        translationMappingsContext: 'translationMappings',
        sseContext: 'sse',
        cacheResetSseUrl: '/cache',
        brandUniverse: 'branduniverses',
        transdiagTranslationState:
            '/transdiag/app/#/cspid_infos/model_code_date_sale',
        toursContext: 'tours',
        propagationContext: 'propagation',
        versionsContext: 'versions',
        productBriefReferenceEndpoint: 'reference/',
        spoUpdateSmEndpoint: 'update-sm-ga',
        timeline: 'timeline',
    },
};

var wediaEnvironments = {
    // eslint-disable-line
    integration: {
        cartContent: 'https://www.mediadecathlon.com/api.jspz',
        cdnBaseUrl: 'https://contents.mediadecathlon.com'
    },
    preprod: {
        cartContent: 'https://www.mediadecathlon.com/api.jspz',
        cdnBaseUrl: 'https://contents.mediadecathlon.com'
    },
    prod: {
        cartContent: 'https://www.mediadecathlon.com/api.jspz',
        cdnBaseUrl: 'https://contents.mediadecathlon.com'
    },
};
