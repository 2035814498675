(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('LocaleGlobalController', LocaleGlobalController);

    LocaleGlobalController.$inject = ['$scope', '$uibModalInstance', '$uibModal', '$http', '$translate', 'sourceSuperModel', 'value', '$rootScope'];

    function LocaleGlobalController($scope, $uibModalInstance, $uibModal, $http, $translate, sourceSuperModel, value) {

        $scope.sourceSuperModel = sourceSuperModel;
        $scope.subject = value;
        $scope.overrideValue = $scope.getOverrideValue;

        $scope.getOverrideValue = function () {
            $scope.overrideValue = sourceSuperModel[value].override;
        };


        $scope.reinitialize = function (modelToDelete) {
            var modalInstance = $uibModal.open({
                templateUrl: 'dialogs/reinitializeLocaleGlobal/confirmReinitializeLocale.html',
                controller: 'ConfirmReinitializeLocaleController',
                size: 'lg',
                resolve: {
                    modelSeason: modelToDelete
                }
            });

            modalInstance.result.then(function () {
                        $scope.sourceSuperModel[value].override = sourceSuperModel[value].value;
                    }, function () {}
                );
        };


    }

})();
