(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('translateSpid', translateSpid);

    translateSpid.$inject = ['$rootScope', 'translationService'];

    function translateSpid ($rootScope, translationService) {
        return {
            restrict: 'A',
            scope: true,
            compile: function () {
                return function linkFn(scope, iElement, iAttrs) {

                    var ihmLanguageWatcher;

                    function watchIhmLanguage() {
                        ihmLanguageWatcher = $rootScope.$watch('ihmLanguage', function (newVal, oldVal) {
                            if (newVal !== oldVal) {
                                determineTranslateLanguage(iAttrs.translateSpid);
                            }
                        });
                    }


                    function determineTranslateLanguage(newTranslateLanguage) {
                        var localeMapping;

                        if (ihmLanguageWatcher) {
                            ihmLanguageWatcher();
                        }

                        if (newTranslateLanguage.indexOf('INT')>-1) {
                            if (newTranslateLanguage === 'int_INT') {
                                localeMapping = $rootScope.ihmLanguage;
                                watchIhmLanguage();
                            }
                            else {
                                localeMapping = translationService.getLocalesMapping(newTranslateLanguage);
                            }
                        }
                        else {
                            localeMapping = newTranslateLanguage;
                        }
                        scope.translateLanguage = localeMapping;
                    }

                    iAttrs.$observe('translateSpid', function (newTranslateLanguage) {
                        determineTranslateLanguage(newTranslateLanguage);
                    });

                    scope.$on('$destroy', function () {
                        if (ihmLanguageWatcher) {
                            ihmLanguageWatcher();
                        }
                    });

                    scope.$watch('translateLanguage', function(){
                        scope.$broadcast('translateLanguageChanged');
                    });
                };
            }
        };
    }

})();
