(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('TreeController', TreeController);

    TreeController.$inject = ['$scope', '$rootScope', 'spidModal', '$state', 'webStorage', 'userSettings', 'treeService', 'seasonService', 'brandService', 'productNatureService', 'toastService', '$translate', 'translationService'];

    function TreeController ($scope, $rootScope, spidModal, $state, webStorage, userSettings, treeService, seasonService, brandService, productNatureService, toastService, $translate, translationService) {

        var profileTreeChanged = $rootScope.$on('profileTreeChanged', function () {
            $scope.initTree();
        });

        $scope.$on('$destroy', function () {
            if (profileTreeChanged) {
                profileTreeChanged();
            }
        });


        $scope.treeData = {
            selectedUniverseId: null,
            selectedDepartmentId: null,
            selectedSubDepartmentId: null,
            selectedFamilyId: null
        };

        var saveTreeSettingsInSession = function (date, universeId, departmentId, subDepartmentId, familyId) {
            var treeSettings = {};
            treeSettings.date = moment(date).format('GGGGWW');
            treeSettings.universeId = universeId;
            treeSettings.departmentId = departmentId;
            treeSettings.subDepartmentId = subDepartmentId;
            treeSettings.familyId = familyId;
            webStorage.session.set('treeSettings', treeSettings);
        };


        $scope.initTree = function () {
            toastService.clearToast();

            var treeSettings = webStorage.session.get('treeSettings');

            if (!treeSettings || Object.keys(treeSettings).length === 0) {
                treeSettings = userSettings.getPreferedTreeSettings();
                webStorage.session.set('treeSettings', treeSettings);
            }

            if (!$scope.applicationDate) {
                if (treeSettings.date) {
                    $scope.applicationDate = moment(treeSettings.date, 'GGGGWW').toDate();
                }
                else {
                    $scope.applicationDate = new Date();
                }
            }

            treeService.getUniverses($scope.applicationDate, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (universes) {
                $scope.universes = universes;
            });

            treeService.getDepartments($scope.applicationDate, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (departments) {
                $scope.departments = departments;
            });

            treeService.getSubDepartments($scope.applicationDate, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (subDepartments) {
                $scope.subDepartments = subDepartments;
            });

            treeService.getFamilies($scope.applicationDate, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (families) {
                $scope.families = families;
            });

            $scope.treeData.selectedUniverseId = treeSettings.universeId;
            $scope.treeData.selectedDepartmentId = treeSettings.departmentId;
            $scope.treeData.selectedSubDepartmentId = treeSettings.subDepartmentId;
            $scope.treeData.selectedFamilyId = treeSettings.familyId;
        };
        var manualCreationPopup;

        $scope.displayCreationDlg = function (version, familyCode, canEditSeason) {
            if (!manualCreationPopup) {
                manualCreationPopup = spidModal.open({
                    templateUrl: 'dialogs/manualCreation/manualCreation.html',
                    controller: 'ManualCreationController',
                    resolve: {
                        modelStartDate: function() {
                            return $scope.applicationDate;
                        },
                        brands: function () {
                            return brandService.getBrands();
                        },
                        productNatures: function () {
                            return productNatureService.getProductNaturesArbo(userSettings.getSelectedWorkingCountry(), userSettings.getSelectedWorkingLanguage());
                        },
                        family: function () {
                            return treeService.getFamilyByCode(familyCode, $scope.applicationDate, userSettings.getSelectedIhmLanguage().toUpperCase());
                        },
                        universeId: function () {
                            return $scope.treeData.selectedUniverseId;
                        },
                        canEditSeason: function () {
                            return canEditSeason;
                        },
                        localeMappings: function () {
                            return translationService.getLocalesMapping();
                        }
                    }
                });


                manualCreationPopup.result.then(function (createdModel) {
                    $state.go('productCardNg', {
                        smCode: createdModel.smCode,
                        versionDate: createdModel.versionDate,
                        smLocale: createdModel.locale,
                        modelCode: createdModel.smCode.substring(1)
                    });
                });

                manualCreationPopup.closed.then(function () {
                    manualCreationPopup = null;
                });

            }
        };


        $scope.updateUniverse = function (deleteChildren) {
            if (deleteChildren) {
                $scope.treeData.selectedDepartmentId = null;
                $scope.treeData.selectedSubDepartmentId = undefined;
                $scope.treeData.selectedFamilyId = null;
            }
            if ($scope.treeData.selectedUniverseId == null) {
                webStorage.session.remove('treeSettings');
            }
            else {
                saveTreeSettingsInSession($scope.applicationDate, $scope.treeData.selectedUniverseId, $scope.treeData.selectedDepartmentId, $scope.treeData.selectedSubDepartmentId, $scope.treeData.selectedFamilyId);
            }
        };

        $scope.updateDepartment = function (deleteChildren) {
            if (deleteChildren) {
                $scope.treeData.selectedSubDepartmentId = undefined;
                $scope.treeData.selectedFamilyId = null;
            }
            if (angular.isDefined($scope.treeData.selectedDepartmentId) && $scope.treeData.selectedDepartmentId != null) {
                var department = _($scope.departments).findWhere({nodeID: $scope.treeData.selectedDepartmentId});
                if ($scope.treeData.selectedUniverseId !== department.fathernodeID) {
                    $scope.treeData.selectedUniverseId = department.fathernodeID;
                    $scope.updateUniverse();
                }
            }
        };

        $scope.updateSubDepartment = function (deleteChildren) {
            if (deleteChildren) {
                $scope.treeData.selectedFamilyId = null;
            }
            if (angular.isDefined($scope.treeData.selectedSubDepartmentId) && $scope.treeData.selectedSubDepartmentId != null) {
                var subDepartment = _($scope.subDepartments).findWhere({nodeID: $scope.treeData.selectedSubDepartmentId});
                if (!subDepartment) {
                    $scope.treeData.selectedSubDepartmentId = undefined;
                }
                else if ($scope.treeData.selectedDepartmentId !== subDepartment.fathernodeID) {
                    $scope.treeData.selectedDepartmentId = subDepartment.fathernodeID;
                    $scope.updateDepartment();
                }
            }
        };

        $scope.updateFamily = function () {
            if (angular.isDefined($scope.treeData.selectedFamilyId) && $scope.treeData.selectedFamilyId != null) {
                var family = _($scope.families).findWhere({nodeID: $scope.treeData.selectedFamilyId});
                if ($scope.treeData.selectedSubDepartmentId !== family.fathernodeID) {
                    $scope.treeData.selectedSubDepartmentId = family.fathernodeID;
                    $scope.updateSubDepartment();
                }
            }
        };

        $scope.checkDisabledState = function (expectedRole) {
            return !userSettings.hasRole(expectedRole) && !userSettings.isFranchisees();
        };
    }

})();
