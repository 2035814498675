(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('materialService', materialService);

    materialService.$inject = ['axiosService', 'envService', 'masterdatasCache'];

    function materialService(axiosService, envService, masterdatasCache) {

        return {

            getMaterials: function (locale) {
                return axiosService
                    .get(
                        envService.getMaterialsUrl() +
                            '?' +
                            Object.entries({ locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: masterdatasCache,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (materials) {
                        return materials.data;
                    });
            },
            isCorrectQuantityValue: function (quantity) {
                var isCorrect = true;
                if (quantity) {
                    // nothing
                }
                return isCorrect;
            },
            hasCorrectProductPercents: function (modelCompositions) {
                if (modelCompositions) {
                    var self = this;
                    modelCompositions.forEach(function (modelComposition) {
                        var somme = 0;
                        modelComposition.rawMaterials.forEach(function (material) {
                            material.hasCorrectMaterialPercents = self.isCorrectQuantityValue(material.quantity);
                            if (material.quantity) {
                                somme += parseFloat(material.quantity);
                            }
                        });
                        modelComposition.rawMaterials.hasCorrectProductPercents = somme;
                    });
                }
            }

        };
    }

})();
