(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MessageBoxController', MessageBoxController);

    MessageBoxController.$inject = ['$scope', 'config'];

    function MessageBoxController ($scope, config) {

        $scope.title = config.title;
        $scope.message = config.message;
        $scope.type = config.type;

        $scope.okLabel = config.labels && config.labels['okLabel'] ? config.labels.okLabel : 'button.ok';
        $scope.cancelLabel = config.labels && config.labels['cancelLabel'] ? config.labels.cancelLabel : 'button.cancel';

    }

})();
