(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MediaController', MediaController);

    MediaController.$inject = ['$scope', '$uibModalInstance', 'axiosService', 'pictures', 'pictureSelected', 'envService', 'contextURL', 'category', 'canUploadPictures', '$rootScope'];

    function MediaController($scope, $uibModalInstance, axiosService, pictures, pictureSelected, envService, contextURL, category, canUploadPictures, $rootScope) {

        $scope.pictures = pictures;
        $scope.pictureSelected = pictureSelected;
        $scope.contextURL = contextURL;
        $scope.category = category;
        $scope.canUploadPictures = canUploadPictures;

        $scope.contentsURL = envService.getContentsURL();

        $scope.submit = function () {
            $uibModalInstance.close($scope.pictureSelected);
            $rootScope.somethingChanged = true;
        };

        $scope.init = function () {
            if ($scope.pictures.length > 0) {
                if (!$scope.pictureSelected) {
                    $scope.pictureSelected = pictures[0].path;
                }
            }
        };

        $scope.removeMedia = function (mediaToDelete) {
            axiosService.delete(
                $scope.contextURL + '/' + mediaToDelete.id,
                {
                    withCredentials: true
                })
                .then(function () {
                    $scope.pictures = _($scope.pictures).reject(function (media) {
                        return media.id === mediaToDelete.id;
                    });
                });
        };

        $scope.selectPicture = function (picture) {
            $scope.pictureSelected = picture.path;
        };

        $scope.$on('uploadSuccess', function (event, media) {
            $scope.$apply(function () {
                $scope.pictures = $scope.pictures || [];
                $scope.pictures.unshift(media);
            });
        });
    }

})();
