(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('images360', images360);

    function images360 () {
        return  {
            restrict: 'E',
            templateUrl: 'productCardNg/imagesVideos/images360/images360.html'
        };
    }

})();
