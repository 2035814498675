(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('notationPopover', notationPopover);

    notationPopover.$inject = ['$compile', '$stateParams', '$q', 'notationUnitsService', 'ratingBars'];

    function notationPopover ($compile, $stateParams, $q, notationUnitsService, ratingBars) {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/productBenefits/notationPopover/notation-popover.html',
            scope: {
                notationType: '=type',
                notationValue: '=value',
                notationUnit: '=unit',
                notationIndex: '=index',
                smLocale: '=locale',
                disabled: '@'
            },
            link: function (scope, element) {

                // Boolean cast

                scope.notationDisabled = (String(scope.disabled) === 'true'); // POURQUOI ?!
                scope.$watch('disabled', function () {
                    scope.notationDisabled = (String(scope.disabled) === 'true');
                });


                scope.notationUnits = [];
                if (!_.isEmpty(scope.smLocale)) {
                    $q.when(notationUnitsService.get(scope.smLocale.split('_')[1], scope.smLocale.split('_')[0])).then(function (notationUnits) {
                        scope.notationUnits = notationUnits;
                    });
                }


                scope.ratingBars = ratingBars;

                scope.notationValueRegExp = new RegExp('\\d+(\\.\\d{0,5})?');

                scope.changeNotationValue = function (newNotationValue) {
                    scope.notationValue = newNotationValue;
                };

                scope.changeNotationUnit = function (newNotationUnit) {
                    scope.notationUnit = newNotationUnit;
                };

                scope.selectNotationType = function (newNotationType) {
                    scope.notationType = newNotationType;
                    setDefaultValues();
                };


                function setDefaultValues() {
                    switch (scope.notationType) {
                        case 'STARS': {
                            scope.notationValue = 1;
                            break;
                        }
                        case 'YESNO': {
                            scope.notationValue = 'true';
                            break;
                        }
                        case 'NUMBER_VALUE': {
                            scope.notationValue = '';
                            scope.notationUnit = _(scope.notationUnits).chain().pluck('id').min().value();
                            break;
                        }
                    }
                }

                scope.removeNotation = function () {
                    scope.notationType = null;
                    scope.notationValue = null;
                    scope.notationUnit = null;
                };

                var popoverContent = angular.element('<notation-popover-content></notation-popover-content>');
                $compile(popoverContent)(scope);

                angular.element(element).find('.displayNotationPopoverButton').popover({
                    trigger: 'focus',
                    html: true,
                    content: popoverContent,
                    placement: 'top'
                });

            }
        };
    }

})();
