(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('reconductionService', reconductionService);

    reconductionService.$inject = ['axiosService', 'envService'];

    function reconductionService(axiosService, envService) {
        return {
            reconductModel: function (
                sourceModelId,
                targetModelId,
                sourceLocale,
                targetLocale
            ) {
                return axiosService.get(
                    envService.getReconductionModelUrl(
                        targetModelId,
                        targetLocale
                    ),
                    {
                        params: {
                            sourceModelId,
                            sourceLocale,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            },
        };
    }
})();
