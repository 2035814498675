(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .filter('familyFilter', familyFilter);

    function familyFilter() {
        return function(families, selectedSubDepartmentId) {
            if(_.isUndefined(selectedSubDepartmentId) || selectedSubDepartmentId === null){
                return families;
            }
            var data = [];
            angular.forEach(families, function(family){
                if(family.fathernodeID == selectedSubDepartmentId){
                    data.push(family);
                }
            });
            return data;
        };
    }

})();
