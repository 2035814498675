(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MessageController', MessageController)
        .constant('messageResolver', messageResolver());

    MessageController.$inject = ['$q', '$scope', 'parameterService', 'moeMessageFr', 'moeMessageEn', 'moaMessageFr', 'moaMessageEn'];

    function MessageController ($q, $scope, parameterService, moeMessageFr, moeMessageEn, moaMessageFr, moaMessageEn) {

        // Param name
        var context = 'support';
        var contentMoeFrParameterName = 'informationPageContentFrench';
        var contentMoeEnParameterName = 'informationPageContentEnglish';
        var contentMoaFrParameterName = 'informationMoaPageContentFrench';
        var contentMoaEnParameterName = 'informationMoaPageContentEnglish';

        $scope.errorProcess = '';
        $scope.resultProcess = '';


        // Initialisation
        $scope.tinymceModelMoeFr = moeMessageFr;
        $scope.tinymceModelMoeEn = moeMessageEn;
        $scope.tinymceModelMoaFr = moaMessageFr;
        $scope.tinymceModelMoaEn = moaMessageEn;

        // Enregistrement des messages.
        $scope.valideMessage = function () {

            var promises = [];
            promises.push(saveParameter(context, contentMoeFrParameterName, $scope.tinymceModelMoeFr));
            promises.push(saveParameter(context, contentMoeEnParameterName, $scope.tinymceModelMoeEn));
            promises.push(saveParameter(context, contentMoaFrParameterName, $scope.tinymceModelMoaFr));
            promises.push(saveParameter(context, contentMoaEnParameterName, $scope.tinymceModelMoaEn));
            $q.all(promises).then(function () {
                $scope.resultProcess = 'ok';
            }, function (e) {
                $scope.errorProcess = e.message;
            });

        };

        $scope.closeAlert = function () {
            $scope.errorProcess = '';
            $scope.resultProcess = '';
        };

        var saveParameter = function (contextToSave, name, value) {
            var parameter = {
                context: contextToSave,
                name: name,
                value: value
            };
            return parameterService.saveParameter(parameter);
        };
    }

    function messageResolver () {
        return {
            moeMessageFr: ['parameterService', function (parameterService) {
                return parameterService.getParameter('support', 'informationPageContentFrench');
            }],
            moeMessageEn: ['parameterService', function (parameterService) {
                return parameterService.getParameter('support', 'informationPageContentEnglish');
            }],
            moaMessageFr: ['parameterService', function (parameterService) {
                return parameterService.getParameter('support', 'informationMoaPageContentFrench');
            }],
            moaMessageEn: ['parameterService', function (parameterService) {
                return parameterService.getParameter('support', 'informationMoaPageContentEnglish');
            }]
        };
    }

})();
