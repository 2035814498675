(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('createVersionModal', createVersionModal);

    createVersionModal.$inject = ['$scope', 'superModelsList', 'fromLocale', 'superModelService', '$sce', '$filter', 'userSettings', 'toastService'];

    function createVersionModal($scope, superModelsList, fromLocale, superModelService, $sce, $filter, userSettings, toastService) {
        $scope.dateDebutAll = null;
        $scope.superModelsList = superModelsList;
        $scope.fromLocale = fromLocale;
        $scope.minStartDate = null;
        $scope.user = userSettings.getUser();

        var errors = {
            'ERR_CREA_VERSION_MSL_NOT_FOUND': 'product.createVersion.error.mslNotFound',
            'ERR_CREA_VERSION_SM_SEASON': 'product.createVersion.error.smSeason.alreadyExists',
            'ERR_CREA_VERSION_MODEL_SEASON': 'product.createVersion.error.modelSeason.alreadyExists',
            'ERR_CREA_VERSION': 'product.createVersion.error.unexpected'
        };

        $scope.superModelsList.forEach(function (superModel) {
            superModel.availableLocales = ['fr_INT', 'en_INT'];
            superModel.newVersionLocale = superModel.locale;

            if ($scope.fromLocale === 'localized' && (!superModel.intLocale || !superModel.intLocale.includes('_INT'))) {
                superModel.availableLocales.push($scope.workingLocale.locale);
            }

            if ($scope.minStartDate == null) {
                $scope.minStartDate = moment(superModel.productSellingStartWeek, 'GGGGWW').add(1, 'week');
            } else {
                if ($scope.minStartDate > moment(superModel.productSellingStartWeek, 'GGGGWW')) {
                    $scope.minStartDate = moment(superModel.productSellingStartWeek, 'GGGGWW').add(1, 'week');
                }
            }

            superModel.editingLocale = false;
            superModel.newLocaleModified = false;
            superModel.isInError = false;
            superModel.creationErrorMessage = null;
            superModel.popoverValue = $sce.trustAsHtml('<b>' + $filter('translate')('product.sm.search.table.sm_brand') + '</b> : ' + superModel.brand.name + '<br>' +
                '<b>' + $filter('translate')('product.sm.search.table.sm_product_nature') + '</b> : ' + $filter('translate')('offerdata.product_natures.' + superModel.productNatureId));

        });

        $scope.startWeekOptions = {
            minDate: new Date($scope.minStartDate),
            initDate: new Date($scope.minStartDate)
        };

        $scope.duplicateDate = function (dateDebut) {
            superModelsList.forEach(function (superModel) {
                superModel.dateDebut = dateDebut;
            });
        };

        $scope.cleanDates = function () {
            superModelsList.forEach(function (superModel) {
                superModel.dateDebut = null;
            });
        };

        $scope.copyForSuperModels = function (dateDebut) {
            superModelsList.forEach(function (superModel) {
                superModel.dateDebut = moment(dateDebut).format('GGGGWW');
            });
        };

        $scope.copyLocaleForModels = function (locale) {
            superModelsList.forEach(function (superModel) {
                superModel.newVersionLocale = locale;
                superModel.newLocaleModified = true;
            });
        };

        $scope.dismiss = function () {
            superModelsList.forEach(function (superModel) {
                superModel.dateDebut = null;
                superModel.newVersionLocale = null;
            });
            $scope.minStartDate = null;
            $scope.$dismiss();
        };

        $scope.removeFromList = function(superModel) {
            superModelsList.splice(superModelsList.indexOf(superModel), 1);
            if (superModelsList.length < 1) {
                $scope.dismiss();
            }
        };

        $scope.checkLocaleModified = function(superModel) {
            if (fromLocale === 'international') {
                superModel.newLocaleModified = superModel.newVersionLocale !== superModel.intLocale;
            } else {
                superModel.newLocaleModified = superModel.newVersionLocale !== superModel.finalLocale;
            }
        };

        $scope.createNewVersionsFromSeasons = function () {
            var mapVersion = {};
            superModelsList.forEach(function (superModel) {
                var smCode = superModel.smCode;
                mapVersion[smCode] = superModel.dateDebut + '_' + superModel.newVersionLocale + '_' + superModel.smSeasonId;
            });
            superModelService.createSuperModelVersion(mapVersion, $scope.user, $scope.workingLocale.locale).then(function (response) {
                var modelsInError =  response.data;
                if (Object.entries(modelsInError).length > 0 && modelsInError.constructor === Object) {
                    Object.keys(modelsInError).forEach(function(key) {
                        superModelsList.forEach(function (sm) {
                            if (sm.smCode === key) {
                                sm.isInError = true;
                                sm.creationErrorMessage = $filter('translate')(errors[modelsInError[key]]);
                            }
                        });
                    });

                    $scope.superModelsList = superModelsList.filter(function (item) {
                        return item.isInError;
                    });
                } else {
                    superModelsList.length = 0;
                    $scope.$dismiss();
                    toastService.displayToast($filter('translate')('product.createVersion.success'), 'success');
                }
            });
        };
    }
})();
