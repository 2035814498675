(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('translationMappingsController', translationMappingsController);

    translationMappingsController.$inject = ['$scope', 'translationMappingsService'];

    function translationMappingsController ($scope, translationMappingsService) {




        function initNewTranslationMapping() {
            $scope.newTranslationMapping = {
                id: null,
                originalLocale: null,
                overrideLocale: null
            };
        }

        initNewTranslationMapping();

        translationMappingsService.get().then(function (data) {
            $scope.translationMappings = data.data;
        })
        .finally(function () {
            $scope.$apply();
        });

        $scope.delete = function (translationMapping) {
            translationMappingsService.delete(translationMapping).then(function () {
                $scope.translationMappings.splice($scope.translationMappings.indexOf(translationMapping), 1);
            })
            .finally(function () {
                $scope.$apply();
            });
        };

        $scope.add = function () {
            translationMappingsService.create($scope.newTranslationMapping).then(function (response) {
                $scope.translationMappings.push(response.data);
                initNewTranslationMapping();
            })
            .finally(function () {
                $scope.$apply();
            });
        };
    }

})();
