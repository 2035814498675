(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('gender', genderDirective);

    genderDirective.$inject = ['$compile', '$filter', 'genderService'];

    function genderDirective($compile, $filter, genderService) {
        return {
            restrict: 'E', templateUrl: 'productCardNg/productIdentity/gender/gender.html', scope: {
                gendersRepository: '=genders',
                selectedItems: '=selectedGenders',
                selectedGender: '=',
                readOnly: '=ngDisabled',
                hasSportResponsible: '='
            }, link: function (scope, elements) {
                scope.enabledItems = [];
                scope.displayPopup = false;
                scope.genders = scope.gendersRepository;

                scope.modifyBasket = modifyBasket;
                scope.reset = reset;
                scope.togglePopup = togglePopup;
                scope.isSelected = isSelected;

                init();

                scope.$watch('selectedItems', function (oldValue, newValue) {
                    if (!angular.equals(oldValue, newValue)) {
                        init();
                    }
                });

                let modalBackground = angular.element('<div data-ng-click="togglePopup()" style="top: 0; left: 0; z-index: 999; width: 100%; height: 100%; position: fixed; background-color: transparent"></div>');

                function init() {
                    updateGenderLabel();
                }

                function modifyBasket(gender) {
                    if (!_(scope.selectedItems).contains(gender.id)) {
                        let tmp = _.uniq(scope.selectedItems);
                        tmp.push(gender.id);
                        scope.selectedItems = tmp;
                    } else {
                        scope.selectedItems = _(scope.selectedItems).without(gender.id);
                    }

                    updateGenderLabel();
                }

                function updateGenderLabel() {
                    let selectedGenders = scope.selectedItems.map(item => genderService.getGender(scope.genders, item));
                    selectedGenders.sort((a, b) => a.id - b.id);
                    scope.displayLabel = selectedGenders.map(gender => gender.label).join(', ') || 'NOT FOUND IN SPO';
                }

                function reset() {
                    scope.selectedItems = [];
                    scope.displayPopup = false;
                    updateGenderLabel();
                }

                function togglePopup() {
                    if (scope.displayPopup) {
                        scope.displayPopup = false;
                        modalBackground.remove();
                    } else {
                        scope.displayPopup = true;
                        elements.append($compile(modalBackground)(scope));
                    }
                }

                function isSelected(item) {
                    return scope.selectedItems ? scope.selectedItems.indexOf(item.id) > -1 : false;
                }
            }

        };
    }
})();
