(function() {
	'use strict';

	angular.module('spidwebApp').factory('toastService', toastService);

	toastService.$inject = [ 'toastr' ];

	function toastService(toastr) {

		var toasts = [];

		return {
			displayTimedToast: function(message, type, hideDelay, extraClass) {
				var delay = hideDelay || 3000;
				var toast = generateToast(message, type, delay, 3000, extraClass, true); // We cannot set permanent sticky toast if there is a timeOut
				toasts.push(toast);
			},
			displayToast: function(message, type, extraClass) {
				var toast = generateToast(message, type, 0, 0, extraClass, true);
                toasts.push(toast);
			},
            displayToastDontCloseOnClick: function(message, type, extraClass) {
                var toast = generateToast(message, type, 0, 0, extraClass, false);
                toasts.push(toast);
            },
			clearToast: function() {
                toasts.forEach(function(toast) {
					toastr.clear(toast);
				});
                toasts= [];
			}
		};

		function generateToast(message, type, hideDelay, extendedTimeOut, extraClass, tapToDismiss) {

			var toastrLauncher = toastr[type];

			return toastrLauncher(message, {
				timeOut: hideDelay,
                extendedTimeOut: extendedTimeOut,
                allowHtml: true,
                toastClass: 'toast ' + (extraClass || ''),
                tapToDismiss: tapToDismiss
			});

		}

	}

})();
