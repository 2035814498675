(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('formatWeekNumber', formatWeekNumber);

    function formatWeekNumber () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                var displayToSpid = function (textWithSlash) {
                    var weekAndYear = textWithSlash.split('/');

                    return weekAndYear[1] + weekAndYear[0];
                };
                var spidToDisplay = function (text) {

                    var year = text.substring(0, 4);
                    var week = text.substring(4, 6);
                    return week + '/' + year;
                };

                ngModel.$parsers.push(displayToSpid);
                ngModel.$formatters.push(spidToDisplay);
            }
        };
    }

})();
