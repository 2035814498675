(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('genderService', genderService);

    genderService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function genderService(axiosService, masterdatasCache, envService) {
        this.getGender = getGender;

        function getGender(allGenders, genderId, onlyIsActive) {
            let filter = {id: genderId};
            if (onlyIsActive) {
                filter.isActive = true;
            }
            return _(allGenders).findWhere(filter);
        }

        return {
            get: function (locale) {
                let self = this;
                return axiosService
                    .get(
                        envService.getGenderUrl() +
                            '?' +
                            Object.entries({ locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: masterdatasCache,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return self.setSelectableGenders(response.data);
                    });
            },

            setSelectableGenders: function (genders) {
                const ignoredGenders = [14, 15];
                const sortedGender = [...genders];
                sortedGender.sort((a, b) => a.id - b.id);
                return sortedGender.filter(gender => !ignoredGenders.includes(gender.id)
                    && gender.childrenIds.length === 0);
            },

            getGender: getGender,
        };

    }

})();
