(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('thumbnail', thumbnail);

    thumbnail.$inject = ['wediaService'];

    function thumbnail (wediaService) {


        return {
            restrict: 'E',
            scope: {
                model: '=',
                imgWidth: '='
            },
            replace: true,
            transclude: true,
            template: '<img ng-src="{{img}}" class="img-thumbnail" err-src="common/styles/img/no-picture.png" style="background:none;border:none;"/>',
            link: function (scope, element, attrs) {
                modelChanged();
                scope.$watch('model', function () {
                    modelChanged();
                }, false);

                function modelChanged() {
                    if (scope.model && !_(scope.model.image).isEmpty()) {
                        scope.img = scope.model.image;
                    } else if (scope.model && !_(scope.model.firstPackShotWeb).isEmpty()) {
                        var packshot = angular.copy(scope.model.firstPackShotWeb.packshot);
                        packshot.type = 'PACKSHOT';
                        setThumb(packshot, element, attrs);
                    }
                    else {
                        scope.img ='common/styles/img/no-picture.png';
                    }
                }

                function setThumb (packshot, elt, at) {
                    scope.img = wediaService.getMediaURL(packshot, scope.imgWidth);
                    elt.attr('class', at.class);
                }

            }
        };
    }

})();
