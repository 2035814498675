(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .filter('momentFormatterDt', momentFormatterDt);

    function momentFormatterDt () {
        return function (input, format, format2) {
            if (!format2) {
                return moment(input);
            } else {
                //special fix oxylane
                return moment(input, format);
            }
        };
    }

})();
