(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('LoadingPageController', LoadingPageController);

    LoadingPageController.$inject = ['$scope', '$window', '$timeout'];

    function LoadingPageController ($scope, $window, $timeout) {

        var loadingProgress;
        var INACTIVITY_TO_PROPOSE_RELOAD = 5000;

        $scope.applicationLoaded = false;
        $scope.requestsCounter = 0;
        $scope.responsesCounter = 0;
        $scope.proposeReload = false;

        function resetWaitingTime () {
            if (loadingProgress) {
                $timeout.cancel(loadingProgress);
            }
            loadingProgress = $timeout(function () {
                $scope.proposeReload = true;
            }, INACTIVITY_TO_PROPOSE_RELOAD);
        }

        $scope.reload = function () {
            $window.location.reload();
        };

        $scope.$on('application_loaded', function () {
            $scope.applicationLoaded = true;
        });

        $scope.$on('application_loading', function (event, counts) {
            resetWaitingTime();
            $scope.requestsCounter = counts.requests;
            $scope.responsesCounter = counts.responses;
        });

    }

})();
