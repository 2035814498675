(function () {
    'use strict';

    angular.module('spidwebApp').factory('zdService', zdService);

    zdService.$inject = ['axiosService', 'envService'];

    function zdService(axiosService, envService) {
        return {
            getAllZd: function () {
                return axiosService.get(
                    envService.getZdUrl(),
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    }).then(function (response) {
                    return Promise.resolve(response.data);
                });
            }
        };
    }
})();
