(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('UserBenefitsController', UserBenefitsController)
        .filter('filterActiveBuOrAlreadySelectedBu', filterActiveBuOrAlreadySelectedBu);

    UserBenefitsController.$inject = ['$scope', 'spidModal', '$timeout', '$stateParams', 'dialogService', 'userBenefitsService', 'ratingBars', 'envService', '$rootScope'];

    function UserBenefitsController($scope, spidModal, $timeout, $stateParams, dialogService, userBenefitsService, ratingBars, envService, $rootScope) {

        $scope.select2Options = {
            dir: $rootScope.isRightToLeft
        };
        $scope.validationMessage = 'Choose an existing User Benefits please.';
        $scope.ratingBars = ratingBars;
        var pristineUserBenefits = angular.copy($scope.productCardUserBenefits);
        $scope.disableEcodesignOverlay = false;
        $scope.addNewUserBenefit = false;

        $scope.initUserBenefits = function () {
            $scope.userBenefitsChanged = false;
        };

        var getPristineModelUserBenefit = function () {
            return {
                id: null,
                userBenefitId: null,
                order: null,
                label: {
                    value: null
                },
                note: {
                    type: null,
                    unit: null,
                    label: null,
                    value: null
                },
                logoPath: null
            };
        };

        $scope.newModelUserBenefit = getPristineModelUserBenefit();

        //RG changement d'ordre
        $scope.sortableOptions = $scope.canEditStructuredData && {
            update: function () {
                $timeout(function () {
                    angular.forEach($scope.productCardUserBenefits, function (ub, index) {
                        ub.order = index + 1;
                    });
                });
            }
        };

        // mise à jour de l'ordre des bénéfices utilisateurs
        var userBenefitsReOrder = function () {
            var modelUbListe = $scope.productCardUserBenefits;
            for (var i = 0; i < modelUbListe.length; i++) {
                var userBenef = modelUbListe[i];
                userBenef.order = i + 1;
            }
        };

        // ajout BU
        $scope.addBU = function () {
            if ($scope.newModelUserBenefit.userBenefitId !== null) {
                //test < 8 bu
                if ($scope.productCardUserBenefits.length < userBenefitsService.maxBuByModel) {

                    userBenefitsService.getBUById($scope.newModelUserBenefit.userBenefitId, $stateParams.smLocale).then(function (selectedUserBenefit) {
                        var modelUserBenefit = getPristineModelUserBenefit();

                        modelUserBenefit.id = null;
                        modelUserBenefit.isNew = true;
                        modelUserBenefit.userBenefitId = selectedUserBenefit.id;
                        modelUserBenefit.order = 0;
                        modelUserBenefit.label.value = $scope.newModelUserBenefit.label.value;
                        modelUserBenefit.note.type = $scope.newModelUserBenefit.note.type;
                        modelUserBenefit.note.value = $scope.newModelUserBenefit.note.value;
                        modelUserBenefit.note.unit = $scope.newModelUserBenefit.note.unit;
                        modelUserBenefit.picto = selectedUserBenefit.userBenefitPictoContentUrl;
                        modelUserBenefit.userBenefitCache = selectedUserBenefit;
                        $scope.productCardUserBenefits = $scope.productCardUserBenefits || [];
                        $scope.productCardUserBenefits.push(modelUserBenefit);
                        modelUserBenefit.userBenefitCache = modelUserBenefit;
                        $scope.newModelUserBenefit = getPristineModelUserBenefit();

                        userBenefitsReOrder();
                        $scope.userBenefitsChanged = !angular.equals(pristineUserBenefits, $scope.productCardUserBenefits);
                        $scope.validationMessage = 'error.bu.choose_existing';
                    });

                } else {
                    $scope.error = 'admin.buMaximum';
                    //dialogService.showMessageBox('admin.warningLabel', 'admin.buMaximum');
                }
            } else {
                $scope.validationMessage = 'Choose an existing User Benefits please.';
            }

        };

        /* supp d'un bu*/
        $scope.removeBU = function (modelUserBenefit) {
            var modal = dialogService.showDeleteBox('admin.warningLabel', 'warning.deleteBU');
            modal.result.then(function () {
                var index = $scope.productCardUserBenefits.indexOf(modelUserBenefit);
                $scope.productCardUserBenefits.splice(index, 1);
                angular.forEach($scope.productCardUserBenefits, function (ub, idx) {
                    $scope.productCardUserBenefits[idx].order = idx + 1;
                });
                $scope.userBenefitsChanged = !angular.equals(pristineUserBenefits, $scope.productCardUserBenefits);
            });
        };

        var addBuPictoModal;

        $scope.displayAddPictoDlg = function (modelUserBenefit) {
            if (!addBuPictoModal) {
                addBuPictoModal = spidModal
                    .open({
                        templateUrl: 'dialogs/mediaChooser/mediaChooser.html',
                        controller: 'MediaController',
                        size: 'lg',
                        resolve: {
                            pictures: function () {
                                return userBenefitsService.getBUPictures(modelUserBenefit.userBenefitId);
                            },
                            pictureSelected: function () {
                                return modelUserBenefit.logoPath;
                            },
                            category: function () {
                                return 'USERBENEFIT_GOODDA';
                            },
                            contextURL: function () {
                                return envService.getUserBenefitContext() + '/' + modelUserBenefit.userBenefitId + '/medias';
                            },
                            canUploadPictures: function () {
                                return $scope.isAdmin;
                            }
                        }
                    });
                addBuPictoModal.result
                    .then(function (picture) {
                        modelUserBenefit.logoPath = picture;
                    });
                addBuPictoModal.closed
                    .then(function () {
                        addBuPictoModal = null;
                    });
            }
        };


        $scope.setPictoIfOnce = function (modelUserBenefit) {
            if (!modelUserBenefit.userBenefitId) {
                return;
            }

            userBenefitsService.getBUPictures(modelUserBenefit.userBenefitId).then(function (pictures) {
                if (angular.isArray(pictures) && pictures.length === 1) {
                    modelUserBenefit.logoPath = pictures[0].path;
                } else {
                    $scope.deletePicto(modelUserBenefit);
                }
            });

            modelUserBenefit.definition = ($scope.userBenefits).find(function (userBenefit) {
                return userBenefit.id === modelUserBenefit.userBenefitId;
            }).definition;
        };

        $scope.changeUserBenefit = function (index) {

            var modelUserBenefit = $scope.productCardUserBenefits[index];
            var selectedUserBenefitId = modelUserBenefit.userBenefitId;

            var selectedUserBenefit = _($scope.userBenefits).findWhere({id: selectedUserBenefitId});

            if (!selectedUserBenefit.isActive) {
                return;
            }

            modelUserBenefit.locale = selectedUserBenefit.locale;
            modelUserBenefit.userBenefit = selectedUserBenefit;
            modelUserBenefit.logoPath = null;

            $scope.setPictoIfOnce(modelUserBenefit);
        };

        // on change l'ordre des UB
        $scope.$watch('model.userBenefits', function () {
            userBenefitsReOrder();
        }, true);

        $scope.deletePicto = function (modelUserBenefit) {
            modelUserBenefit.logoPath = null;
        };

        $scope.maxLimitExceeded = function (index) {
            return index >= userBenefitsService.maxBuByModel;
        };

        $scope.toggleUB = function () {
            $scope.addNewUserBenefit = !$scope.addNewUserBenefit;
        };

    }

    function filterActiveBuOrAlreadySelectedBu() {
        return function (userBenefits, modelUserBenefits, currentUserBenefitId) {
            return _(userBenefits).filter(function (userBenefit) {
                return (userBenefit.id === currentUserBenefitId) || userBenefit.isActive && _(modelUserBenefits).find({userBenefitId: userBenefit.id}) === undefined;
            });
        };
    }
})();
