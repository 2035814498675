(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ReconduireCopierController', ReconduireCopierController);

    ReconduireCopierController.$inject = ['$scope', 'modelService', 'superModelService', 'sourceSuperModel', 'countries'];

    function ReconduireCopierController ($scope, modelService, superModelService, sourceSuperModel, countries) {

        $scope.callInProgress = false;
        $scope.callInProgressForCodeConception = false;
        $scope.callInProgressForSuperModel = false;
        $scope.availableLanguages = [];
        $scope.sourceModel = null;
        $scope.superModel=sourceSuperModel;
        $scope.modelCodeChanged = false;
        $scope.codeConceptionChanged = false;
        $scope.superModelCodeChanged = false;
        $scope.searchCodeConception = null;
        $scope.searchCodeSuperModel = null;
        $scope.typeSearch = null;

        $scope.modelCodeHasChanged = function () {
            $scope.modelCodeChanged = true;
            $scope.codeConceptionChanged = true;
            $scope.superModelCodeChanged = true;
            $scope.availableLanguages = [];
            $scope.modelSeasons = null;
        };

        $scope.smartCopyData = {
            selectedLanguage: null,
            modelNodeId: null,
            action: null
        };

        function resetData() {
            $scope.smartCopyData.selectedLanguage = null;
            $scope.smartCopyData.modelSeason = null;
            $scope.availableLanguages = [];
            $scope.modelSeasons = null;
        }

        var buildAvailableLanguage = function (locales) {
            var result = [];
            var ojectLocal;
            if (locales) {
                locales.forEach(function (locale) {
                    var country = locale.split('_')[1];

                    if (country === 'INT') {
                        ojectLocal={locale: locale, label: 'International - '+locale.split('_')[0], international: false};

                        if (!result.filter(function(element) {
                            return (element.locale === ojectLocal.locale);
                        }).length > 0){
                            result.push(ojectLocal);
                        }
                    }
                    var filtreredCountry = countries.find(function (countryToFilter) {
                        return countryToFilter.code === country;
                    });
                    if (filtreredCountry) {
                        filtreredCountry.listLangages.forEach(function (langage) {
                            if (langage.code === locale.split('_')[0]) {
                                ojectLocal={locale: locale, label: filtreredCountry.label + ' - ' + langage.label, international: false};
                                if (!result.filter(function(element) {return (element.locale === ojectLocal.locale); }).length > 0) {
                                    result.push(ojectLocal);
                                }
                            }
                        });
                    }
                });
            }

            return result;
        };
        $scope.chooseAction = function () {
            resetData();
            $scope.modelCodeChanged = false;
            if ($scope.smartCopyData.action == 'renew') {
                $scope.searchCodeConception =null;
                $scope.searchCodeSuperModel =  sourceSuperModel.code;
                $scope.search(null, $scope.searchCodeSuperModel, null, 'codeSuperModel');
            }

            if ($scope.smartCopyData.action == 'smartCopy') {
                $scope.searchModelSC = null;
                $scope.searchCodeConception = null;
                $scope.searchCodeSuperModel = null;
            }
        };

        $scope.search = function (modelCode, smCode, codeConception, typeSearch) {
            modelCode=(modelCode==null)?'':modelCode;
            smCode=(smCode==null)?'':smCode;
            codeConception=(codeConception==null)?'':codeConception;
            resetData();
            $scope.typeSearch=typeSearch;

            if (!modelCode && !smCode && !codeConception) {
                return;
            }

            if(typeSearch ==='codeSuperModel'){
                $scope.callInProgressForSuperModel= true;
                $scope.searchCodeConception=null;
                $scope.searchModelSC=null;

            }
            if(typeSearch ==='codeConception'){
                $scope.callInProgressForCodeConception = true;
                $scope.searchCodeSuperModel= null;
                $scope.searchModelSC= null;
            }
            if(typeSearch ==='model') {
                $scope.callInProgress = true;
                $scope.searchCodeConception = null;
                $scope.searchCodeSuperModel = null;
            }
            return modelService.getModelSeasonsLocalizedByModelCodeOrCodeConceptionOrSmCode(modelCode, smCode, codeConception, sourceSuperModel).then(
                function (supermodelSeasons) {

                    var result = [];
                    supermodelSeasons.forEach(function(superModelSeason){
                        result.push({id: superModelSeason.smSeasonId, seasonType: superModelSeason.season.type, seasonYear: superModelSeason.season.year, smCode: superModelSeason.smCode, codeConception: superModelSeason.conceptionCode, versionDate: superModelSeason.versionDate});
                    });

                    $scope.superModelSeasons=result;

                    if ($scope.superModelSeasons != null && $scope.superModelSeasons.length > 0) {
                        $scope.smartCopyData.modelSeason = $scope.superModelSeasons[0];
                        if(typeSearch ==='model') {
                            $scope.searchCodeSuperModel = $scope.smartCopyData.modelSeason.smCode;
                            $scope.searchCodeConception = $scope.smartCopyData.modelSeason.codeConception;
                        }
                        superModelService.getAllAvailableLocales($scope.smartCopyData.modelSeason.smCode, $scope.smartCopyData.modelSeason.versionDate).then(function (response) {
                            $scope.modelSeasonChanged(response.data);

                        });
                    }
                    if(typeSearch ==='codeSuperModel'){
                        $scope.callInProgressForSuperModel = $scope.superModelCodeChanged = false;
                    }
                    if(typeSearch ==='codeConception'){
                        $scope.callInProgressForCodeConception = $scope.codeConceptionChanged = false;
                    }
                    if(typeSearch ==='model'){
                        $scope.callInProgress = $scope.modelCodeChanged = false;
                    }
                },
                function () {
                    if(typeSearch ==='codeSuperModel'){
                        $scope.callInProgressForSuperModel = $scope.superModelCodeChanged = false;
                    }
                    if(typeSearch ==='codeConception'){
                        $scope.callInProgressForCodeConception = $scope.codeConceptionChanged = false;
                    }
                    if(typeSearch ==='model'){
                        $scope.callInProgress = $scope.modelCodeChanged = false;
                    }
                });
        };

        $scope.modelSeasonChanged = function (availableLocales) {
            $scope.availableLanguages = buildAvailableLanguage(availableLocales);
            if ($scope.availableLanguages.length > 0) {
                var availableLanguage = _($scope.availableLanguages).find(function (availableLocale) {
                    return availableLocale.locale == sourceSuperModel.locale;
                });
                $scope.smartCopyData.selectedLanguage = availableLanguage && availableLanguage.locale;
            }
        };


        $scope.displayPreviousSeasonModelCopyDlg = function () {
            //Trop bien de passer par 40 000 objets JS
            $scope.$close({
                locale: $scope.smartCopyData.selectedLanguage,
                smSeason: $scope.smartCopyData.modelSeason
            });
        };

    }

})();
