(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('createNewVersionModal', createNewVersionModal);

    createNewVersionModal.$inject = ['$scope', 'versionsList', 'fromLocale', 'superModelService', '$sce', '$filter', 'userSettings', 'toastService', 'lastSearchService'];

    function createNewVersionModal($scope, versionsList, fromLocale, superModelService, $sce, $filter, userSettings, toastService, lastSearchService) {
        $scope.dateDebutAll = null;
        $scope.versionsList = versionsList;
        $scope.fromLocale = fromLocale;
        $scope.user = userSettings.getUser();

        var errors = {
            'ERR_CREA_VERSION_MSL_NOT_FOUND': 'product.createVersion.error.mslNotFound',
            'ERR_CREA_VERSION_SM_SEASON': 'product.createVersion.error.smSeason.alreadyExists',
            'ERR_CREA_VERSION_MODEL_SEASON': 'product.createVersion.error.modelSeason.alreadyExists',
            'ERR_CREA_VERSION': 'product.createVersion.error.unexpected',
            'ERR_CREA_VERSION_SM_UNAVAILABLE_FOR_DATE': 'product.createVersion.error.superModel.notAvailable'
        };

        $scope.versionsList.forEach(function (version) {
            version.availableLocales = ['fr_INT', 'en_INT'];
            version.newVersionLocale = version.masterLocale;

            if ($scope.fromLocale === 'localized' && (!version.masterLocale || !version.masterLocale.includes('_INT'))) {
                version.availableLocales.push($scope.workingLocale.locale);
            }

            version.editingLocale = false;
            version.newLocaleModified = false;
            version.isInError = false;
            version.creationErrorMessage = null;
            version.popoverValue = $sce.trustAsHtml('<b>' + $filter('translate')('product.sm.search.table.sm_brand') + '</b> : ' + $filter('translate')('brand.label.' + version.superModel.brand) + '<br>' +
                '<b>' + $filter('translate')('product.sm.search.table.sm_product_nature') + '</b> : ' + $filter('translate')('offerdata.product_natures.' + version.superModel.productNature));

        });

        let smWorldStartDates = versionsList.map(version => new Date(version.superModel.worldStartDate));
        smWorldStartDates.push(new Date());
        let smWorldEndDates = versionsList.map(version => new Date(version.superModel.worldEndDate));
        let minDate = _.max(smWorldStartDates);
        minDate.setDate(minDate.getDate() + 7);
        let maxDate = _.min(smWorldEndDates);

        $scope.startWeekOptions = {
            minDate: minDate,
            initDate: minDate,
            maxDate: maxDate
        };

        $scope.duplicateDate = function (dateDebut) {
            versionsList.forEach(function (superModel) {
                superModel.dateDebut = dateDebut;
            });
        };

        $scope.cleanDates = function () {
            versionsList.forEach(function (superModel) {
                superModel.dateDebut = null;
            });
        };

        $scope.copyForSuperModels = function (dateDebut) {
            versionsList.forEach(function (superModel) {
                superModel.dateDebut = moment(dateDebut).format('YYYY-MM-DDTHH:mm:ss');
            });
        };

        $scope.copyLocaleForModels = function (locale) {
            versionsList.forEach(function (superModel) {
                superModel.newVersionLocale = locale;
                superModel.newLocaleModified = true;
            });
        };

        $scope.dismiss = function () {
            versionsList.forEach(function (superModel) {
                superModel.dateDebut = null;
                superModel.newVersionLocale = null;
            });
            $scope.minStartDate = null;
            $scope.$dismiss();
        };

        function removeFromList(superModel) {
            $scope.versionsList.splice($scope.versionsList.indexOf(superModel), 1);
            if ($scope.versionsList.length < 1) {
                $scope.dismiss();
            }
        }

        $scope.removeFromList = removeFromList;

        $scope.checkLocaleModified = function (superModel) {
            if (fromLocale === 'international') {
                superModel.newLocaleModified = superModel.newVersionLocale !== superModel.intLocale;
            } else {
                superModel.newLocaleModified = superModel.newVersionLocale !== superModel.finalLocale;
            }
        };

        $scope.createNewVersions = function () {
            var mapVersions = {};
            versionsList.forEach(function (version) {
                mapVersions[version.superModel.code] = {
                    dateToCreate: version.dateDebut,
                    locale: version.newVersionLocale,
                    smSeasonId: version.id
                };
            });
            superModelService.createSuperModelVersion(mapVersions, $scope.user, $scope.workingLocale.locale).then(function (response) {
                var modelsInError = response.data;
                var filterSM = true;
                if (Object.entries(modelsInError).length > 0 && modelsInError.constructor === Object) {
                    Object.keys(modelsInError).forEach(function (key) {
                        versionsList.forEach(function (version) {
                            if (version.superModel.code === key) {
                                version.isInError = true;
                                version.creationErrorMessage = $filter('translate')(errors[modelsInError[key]]);
                                if (modelsInError[key] === 'ERR_CREA_VERSION_SM_UNAVAILABLE_FOR_DATE') {
                                    filterSM = false;
                                }
                            }
                        });
                    });

                    $scope.versionsList = filterSM ? versionsList.filter(item => item.isInError) : versionsList;
                } else {
                    var versionDate = versionsList[0].dateDebut;
                    versionsList.length = 0;
                    $scope.$dismiss();
                    lastSearchService.goToLastSearchWithVersionDate(versionDate).then(function () {
                        toastService.displayTimedToast($filter('translate')('product.createVersion.success'), 'success');
                    });
                }
            });
        };
    }
})();
