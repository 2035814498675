(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('SmartCopyController', SmartCopyController);

    SmartCopyController.$inject = ['$scope', 'modelService', 'sourceModel', 'countries'];

    function SmartCopyController ($scope, modelService, sourceModel, countries) {

        $scope.callInProgress = false;
        $scope.availableLanguages = [];
        $scope.sourceModel = sourceModel;
        $scope.modelCodeChanged = false;

        $scope.modelCodeHasChanged = function () {
            $scope.modelCodeChanged = true;
            $scope.availableLanguages = [];
            $scope.modelSeasons = null;
        };

        $scope.smartCopyData = {
            selectedLanguage: null,
            modelNodeId: null,
            action: null
        };

        function resetData() {
            $scope.smartCopyData.selectedLanguage = null;
            $scope.smartCopyData.modelSeason = null;
            $scope.availableLanguages = [];
            $scope.modelSeasons = null;
        }

        var buildAvailableLanguage = function (locales) {
            var result = [];
            if (locales) {
                locales.forEach(function (locale) {
                    var country = locale.split('_')[1];

                    if (country === 'INT') {
                        result.push({locale: locale, international: true});
                    }
                    else {
                        var filtreredCountry = countries.find(function (countryToFilter) {
                            return countryToFilter.code == country;
                    });
                        if (filtreredCountry) {
                            filtreredCountry.listLangages.forEach(function (langage) {
                        if (langage.code == locale.split('_')[0]) {
                                    result.push({locale: locale, label: filtreredCountry.label + ' - ' + langage.label, international: false});
                        }
                    });
                        }
                    }
                });
            }

            return result;
        };

        $scope.chooseAction = function () {
            resetData();
            $scope.modelCodeChanged = false;
            if ($scope.smartCopyData.action == 'renew') {
                $scope.searchModelSC = sourceModel.code;
                $scope.search($scope.searchModelSC);
            }

            if ($scope.smartCopyData.action == 'smartCopy') {
                $scope.searchModelSC = null;
            }
        };

        $scope.search = function (modelCode) {
            resetData();

            if (!modelCode) {
                return;
            }

            $scope.callInProgress = true;

            return modelService.getModelSeasonsLocalized(modelCode).then(
                function (modelSeasonLocalizeds) {

                    $scope.modelSeasons =
                    _(modelSeasonLocalizeds)
                        .chain()
                        .map(function (modelSeasonLocalized) {
                            return modelSeasonLocalized.modelSeason.id;
                        })
                        .uniq()
                        .map(function (modelSeasonId) {

                            var modelSeason = _(modelSeasonLocalizeds).find(function (modelSeasonLocalized) {
                                return modelSeasonLocalized.modelSeason.id === modelSeasonId;
                            }).modelSeason;

                            modelSeason.modelSeasonLocalizeds = _(modelSeasonLocalizeds).filter(function (modelSeasonLocalized) {
                                return modelSeasonLocalized.modelSeason.id === modelSeason.id;
                            });

                            return modelSeason;
                        })
                        .sortBy(function (modelSeason) {
                            return - parseInt(modelSeason.productSellingStartWeek, 10);
                        })
                        .value();


                    if ($scope.modelSeasons != null && $scope.modelSeasons.length > 0) {
                        if ($scope.smartCopyData.action == 'renew') {
                            $scope.smartCopyData.modelSeason = _($scope.modelSeasons).find(function (modelSeason) {
                                return modelSeason.productSellingStartWeek < sourceModel.productSellingStartWeek;
                            });
                        }
                        else {
                            $scope.smartCopyData.modelSeason = $scope.modelSeasons[0];
                        }

                        if ($scope.smartCopyData.modelSeason) {
                            $scope.modelSeasonChanged();
                        }
                    }

                    $scope.callInProgress = $scope.modelCodeChanged = false;
                },
                function () {
                    $scope.callInProgress = $scope.modelCodeChanged = false;
                });
        };

        $scope.modelSeasonChanged = function () {
            var availableLocales = _($scope.smartCopyData.modelSeason.modelSeasonLocalizeds)
                .map(function (modelSeasonLocalized) {
                return modelSeasonLocalized.modelLocale;
                });

            $scope.availableLanguages = buildAvailableLanguage(availableLocales);

            if ($scope.availableLanguages.length > 0) {

                var availableLanguage = _($scope.availableLanguages).find(function (availableLocale) {
                    return availableLocale.locale == sourceModel.locale;
                });

                $scope.smartCopyData.selectedLanguage = availableLanguage && availableLanguage.locale;
            }

        };


        $scope.displayPreviousSeasonModelCopyDlg = function () {
            //Trop bien de passer par 40 000 objets JS
            $scope.$close({
                locale: $scope.smartCopyData.selectedLanguage,
                smSeasonId: $scope.smartCopyData.modelSeason.superModelSeason.id,
                modelCode: $scope.smartCopyData.modelSeason.modelCode
            });
        };

    }

})();
