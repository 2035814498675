(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ConfirmReinitializeLocaleController', ConfirmReinitializeLocaleController);

    ConfirmReinitializeLocaleController.$inject = ['$scope', 'modelSeason'];

    function ConfirmReinitializeLocaleController ($scope, value) {
        $scope.value = value;
    }

})();
