(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ErrorController', ErrorController);

    ErrorController.$inject = ['$scope', 'errors'];
    function ErrorController ($scope, errors) {

        $scope.errors = errors;
        $scope.errorResponse = errors[0];
        $scope.collapseCallstack = false;

    }

})();
