(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('searchService', searchService);

    searchService.$inject = ['axiosService', 'envService'];

    function searchService(axiosService, envService) {


        var getModelCriteria = function () {
            return {
                locale: null,
                codeConception: null,
                codeModele: null,
                smCode: null,
                seasonType: null,
                seasonYear: null,
                familyCodes: null,
                statuses: [],
                productSellingStartWeek: null,
                user: null,
                minProductSellingStartWeek: null,
                maxProductSellingStartWeek: null,
                published: null
            };
        };

        return {
            getModelCriteria: getModelCriteria,
            search: function (modelCode, locale) {

                var modelCriteria = getModelCriteria();

                modelCriteria.codeModele = modelCode;
                modelCriteria.codeConception = modelCode;
                modelCriteria.smCode = modelCode;
                modelCriteria.locale = locale;

                return this.searchModels(modelCriteria).then(function (response) {
                    return response.data;
                }, function () {
                    return [];
                });
            },
            searchForSupport: function (modelCode) {
                var modelCriteria = getModelCriteria();

                modelCriteria.codeModele = modelCode;
                modelCriteria.codeConception = modelCode;
                modelCriteria.smCode = modelCode;

                return this.searchModels(modelCriteria).then(function (response) {
                    return response.data;
                }, function () {
                    return [];
                });
            },
            searchModels: function (modelCriteria) {
                return axiosService.get(
                    envService.getModelsContext(),
                    {
                        params: modelCriteria,
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
            }
        };

    }

})();
