(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('fluxService', fluxService);

    fluxService.$inject = ['axiosService', 'envService'];

    function fluxService(axiosService, envService) {

        return {
            saveFlux: saveFlux,
            getFlux: getFlux
        };

        function saveFlux(flux) {
            return axiosService
                .post(envService.getFluxContext(), flux, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        }

        function getFlux() {
            return axiosService
                .get(envService.getFluxContext(), {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                })
                .then(function (response) {
                    return response.data;
                });
        }

    }

})();
