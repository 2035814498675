(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ImagesController', ImagesController);

    ImagesController.$inject = ['$scope', 'spidModal', '$stateParams', '$q', '$rootScope', '$analytics', 'mediaService', 'envService', 'wediaService', 'dialogService', 'axiosService', 'parameterService'];

    function ImagesController($scope, spidModal, $stateParams, $q, $rootScope, $analytics, mediaService, envService, wediaService, dialogService, axiosService, parameterService) {

        $scope.baseUrl = envService.getBaseUrl();
        $scope.imagesChanged = false;


        if (angular.isUndefined($scope.pixlPanelEnabled)) {
            $scope.pixlPanelEnabled = true;
        }

        $scope.hasObsoleteMedias = function (code) {
            return mediaService.hasObsoleteMedias($scope.productCardDto.models, code);
        };

        $scope.syncPixlMedias = function () {
            let filterOnName = false;
            parameterService.getParameter('featureFlags', 'pixl.autoaffect.filterOnName')
                .then(parameterValue => {
                    filterOnName = parameterValue === 'true';
                })
                .catch(() => {
                    console.error('Error while getting parameter feature.pixl.autoaffect.filterOnName');
                })
                .finally(() => {
                    downloadPixlMediasAndAutoAffect();
                    _($scope.productCardDto.models)
                        .chain()
                        .forEach(model => {
                            mediaService.fixMediaDisplayOrder(model.medias, filterOnName);
                        });
                });
        };

        function downloadPixlMediasAndAutoAffect(timeout) {
            return axiosService.get(
                envService.getPixlUrl() + '/getCartContent',
                {
                    params: {
                        searchCriteria: $scope.superModel.code,
                        type: 'SM',
                        mediaType: 'ALL'
                    },
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json;charset=UTF-8'
                    },
                    timeout: timeout || 60000
                })
                .then(function (pixlResult) {
                    let videoAvailableLanguages = [];
                    mediaService.getAvailableLanguages($stateParams.smLocale.split('_')[1], $stateParams.smLocale.split('_')[0])
                        .then(function (response) {
                            videoAvailableLanguages = response;
                        });

                    // group the medias by model
                    const modelMediasGroupedBy = pixlResult.data.reduce(function (r, a) {
                        r[a.cm[0].name] = r[a.cm[0].name] || [];
                        r[a.cm[0].name].push(a);
                        return r;
                    }, Object.create(null));

                    _($scope.productCardDto.models)
                        .chain()
                        .forEach(spidModel => {
                            const modelMediasGroupedByElement = modelMediasGroupedBy[spidModel.code];
                            let model = wediaService.mapPixlResultToCart(modelMediasGroupedByElement);
                            _(model.videos).each(function (film) {
                                var mostRelevantVideo = _(film.languages)
                                    .chain()
                                    .each(function (video) {
                                        var foundLocaleCodeVideoMapping = _(videoAvailableLanguages).find(function (localeCodeVideoMapping) {
                                            return video.code.toUpperCase() === localeCodeVideoMapping.codeLangue;
                                        });
                                        video.priority = angular.isDefined(foundLocaleCodeVideoMapping) ? foundLocaleCodeVideoMapping.priority : null;
                                    })
                                    .filter(function (video) {
                                        return video.priority !== null;
                                    })
                                    .sortBy('priority')
                                    .first()
                                    .value();

                                film.videos = _(film.languages)
                                    .chain()
                                    .each(function (video) {
                                        video.name = film.name;
                                        video.pixlId = film.pixlId;
                                        video.language = video.code;
                                        video.brightcoveId = 'p' + film.pixlId;
                                        video.validateFromDate = film.validateFromDate;
                                        video.validateToDate = film.validateToDate;
                                        video.type = film.subType;
                                    })
                                    .value();

                                film.mostRelevantVideo = angular.isDefined(mostRelevantVideo) ? mostRelevantVideo : null;
                                if (film.mostRelevantVideo) {
                                    film.mostRelevantVideo.name = film.name;
                                    film.mostRelevantVideo.pixlId = film.pixlId;
                                    film.mostRelevantVideo.brightcoveId = 'p' + film.pixlId;
                                    film.mostRelevantVideo.validateFromDate = film.validateFromDate;
                                    film.mostRelevantVideo.validateToDate = film.validateToDate;
                                }
                            });

                            let cart = model;
                            let mediaTypes = ['PACKSHOT', 'PACKSHOT_SPORT', 'MEDIA360', 'FILM'];
                            $scope.affectAuto(mediaTypes, cart, spidModel);
                        });
                }).catch(function (error) {
                    console.error(error);
                });
        }

        $scope.selectedModelCode = undefined;

        /********
         * INIT *
         ********/

        $scope.initMedias = function (reconduction) {

            $scope.cartCriteria = {
                media_type: 'ALL' // eslint-disable-line camelcase
            };
            if (!reconduction) {
                $scope.retrieveObsoleteMedias(_.union($scope.productCardDto.models, $scope.pristineSiblings));
            }
            applySiblingChange();
            if ($scope.properties.pixlPanelVisible === null) {
                $scope.properties.pixlPanelVisible = true;
            }

            $scope.$watch('focusedSibling.code', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    applySiblingChange();
                }
            }, true);

        };

        $scope.retrieveObsoleteMedias = function (siblings) {
            return mediaService.retrieveObsoleteMedias(siblings);
        };

        var copyMediasModal;
        $scope.openCopyMediasPopup = function () {

            if (!copyMediasModal) {
                copyMediasModal = spidModal
                    .open({
                        templateUrl: 'productCardNg/imagesVideos/cart/autoAffect/autoAffectModal.html',
                        controller: 'AffectationAutoController',
                        size: 'lg',
                        resolve: {
                            popupMode: function () {
                                return 'copy_medias';
                            },
                            mediasCart: function () {
                                return {
                                    productMedias: _($scope.focusedSibling.medias.masterModelPackshots).filter(function (modelPackshot) {
                                        return modelPackshot.zone === 'PRODUCT';
                                    }),
                                    productContext: _($scope.focusedSibling.medias.masterModelPackshots).filter(function (modelPackshot) {
                                        return modelPackshot.zone === 'CONTEXT';
                                    }),
                                    medias360: $scope.focusedSibling.medias.modelMedia360,
                                    videos: $scope.focusedSibling.medias.modelMediaFilms
                                };
                            }
                        }
                    });
                copyMediasModal.result
                    .then(function (mediaTypes) {
                        if (copyMedias(mediaTypes)) {
                            $analytics.eventTrack('Copie de médias', {
                                category: 'Fiche SM',
                                label: 'Copie de médias ' + mediaTypes.join(',')
                            });
                        }
                    });
                copyMediasModal.closed
                    .then(function () {
                        copyMediasModal = null;
                    });
            }
        };

        function copyMedias(mediaTypes) {

            if (mediaTypes.length === 0) {
                return false;
            }

            var siblings = _($scope.siblings).filter(function (sibling) {
                return sibling.code !== $scope.focusedSibling.code;
            });


            //var productMedias = _($scope.focusedSibling.medias.modelPackshots).filter(function (siblingMedias) {return siblingMedias.zone == 'PRODUCT';});
            var contextMedias = _($scope.focusedSibling.medias.modelPackshots).filter(function (modelPackshot) {
                return modelPackshot.zone === 'CONTEXT';
            });
            var images360 = $scope.focusedSibling.medias.modelMedia360;
            var films = $scope.focusedSibling.medias.modelMediaFilms;

            _(siblings).each(function (sibling) {

                var newPackshots = [];
                var newMedias360 = [];
                var newVideos = [];

                /*
                 if (_(mediaTypes).contains('PACKSHOT')) {
                    newPackshots = newPackshots.concat(_(productMedias).map(function (productMedia) {
                 var copy = angular.copy(productMedia);
                 copy.modelCode = sibling.modelCode;
                 return copy;
                 }));
                 }*/

                if (_(mediaTypes).contains('PACKSHOT_SPORT')) {
                    newPackshots = newPackshots.concat(_(contextMedias).map(function (productContext) {
                        var copy = angular.copy(productContext);
                        copy.modelCode = sibling.code;
                        return copy;
                    }));
                }

                if (_(mediaTypes).contains('MEDIA360')) {
                    newMedias360 = _(images360).map(function (image360) {
                        var copy = angular.copy(image360);
                        copy.modelCode = sibling.code;
                        return copy;
                    });
                }

                if (_(mediaTypes).contains('FILM')) {
                    newVideos = _(films).map(function (film) {
                        var copy = angular.copy(film);
                        copy.modelCode = sibling.code;
                        return copy;
                    });
                }

                if (newPackshots.length > 0) {
                    sibling.medias.modelPackshots = _(sibling.medias.modelPackshots).reject(function (modelPackshot) {
                        return (_(mediaTypes).contains('PACKSHOT_SPORT') && modelPackshot.zone === 'CONTEXT') || (_(mediaTypes).contains('PACKSHOT') && modelPackshot.zone === 'PRODUCT');
                    }).concat(newPackshots);
                }
                if (newMedias360.length > 0) {
                    sibling.medias.modelMedia360 = newMedias360;
                }
                if (newVideos.length > 0) {
                    sibling.medias.modelMediaFilms = newVideos;
                }

                setNgModified();

            });

            return true;
        }

        $scope.affectAuto = function (mediaTypes, cart, model) {
            if (!model) {
                model = $scope.focusedSibling;
            }

            if (_(mediaTypes).contains('PACKSHOT')) {
                $scope.modelMediaChanged(cart.packshots);

                model.medias.modelPackshots = _(model.medias.modelPackshots).reject(function (modelPackshot) {
                    return modelPackshot.zone === 'PRODUCT';
                });


                var packshotNotScene =
                    _(cart.packshots)
                        .chain()
                        .filter(function (packshot) {
                            return packshot.subType !== 'SCENE';
                        })
                        .map(function (packshot) {
                            packshot.zone = 'PRODUCT';
                            return packshot;
                        })
                        .value();

                Array.prototype.push.apply(model.medias.modelPackshots, packshotNotScene.slice(0, 100));

                if (model.master && packshotNotScene.length > 0) {

                    model.medias.modelPackshots = _(model.medias.modelPackshots).reject(function (modelPackshot) {
                        return modelPackshot.zone === 'SIGN_KIT';
                    });

                    var newBalisage = angular.copy(packshotNotScene[0]);
                    newBalisage.zone = 'SIGN_KIT';
                    newBalisage.order = 1;
                    model.medias.modelPackshots.push(newBalisage);
                }
            }
            if (_(mediaTypes).contains('PACKSHOT_SPORT')) {

                model.medias.modelPackshots = _(model.medias.modelPackshots).reject(function (modelPackshot) {
                    return modelPackshot.zone === 'CONTEXT';
                });


                var packshotScene =
                    _(cart.packshots)
                        .chain()
                        .filter(function (packshot) {
                            return packshot.subType === 'SCENE';
                        })
                        .map(function (packshot) {
                            packshot.zone = 'CONTEXT';
                            return packshot;
                        })
                        .value();
                Array.prototype.push.apply(model.medias.modelPackshots, packshotScene.slice(0, 10));
            }
            if (_(mediaTypes).contains('MEDIA360')) {
                model.medias.modelMedia360.splice(0, model.medias.modelMedia360.length);


                var modelMedias360 =
                    _(cart.images360.slice(0, 100))
                        .map(function (image360) {
                            return image360;
                        });
                Array.prototype.push.apply(model.medias.modelMedia360, modelMedias360);
            }


            if (_(mediaTypes).contains('FILM')) {
                model.medias.modelMediaFilms.splice(0, model.medias.modelMediaFilms.length);


                var modelMediaFilms =
                    _(cart.videos.slice(0, 100))
                        .chain()
                        .filter(function (film) {
                            return angular.isDefined(film.mediaType) && film.mediaType != null && film.mediaType.length > 0;
                        })
                        .map(function (film) {
                            return film;
                        })
                        .value();
                Array.prototype.push.apply(model.medias.modelMediaFilms, modelMediaFilms);
            }

            if (_(mediaTypes).contains('PACKSHOT') || _(mediaTypes).contains('PACKSHOT_SPORT')) {
                modelPackshotChanged();
                setNgModified();
            }
        };

        /**********************************
         * Siblings Models Tab Management *
         **********************************/
        $scope.switchSibling = function (sibling) {
            $scope.focusSibling(sibling);
        };

        function applySiblingChange() {
            if ($scope.focusedSibling && $scope.focusedSibling.available) {
                modelPackshotChanged();
                $scope.cartCriteria.search_type = 'CM'; // eslint-disable-line camelcase
                $scope.cartCriteria.search_value = $scope.focusedSibling.code; // eslint-disable-line camelcase
                $scope.selectedModelCode = $scope.focusedSibling.code;
            }
        }

        function modelPackshotChanged() {
            var packshotsByZone = _($scope.focusedSibling.medias.modelPackshots).groupBy('zone');
            $scope.packshotsProduct = _(packshotsByZone['PRODUCT']).sortBy('order') || [];
            $scope.packshotsContext = _(packshotsByZone['CONTEXT']).sortBy('order') || [];
            $scope.packshotsSign = _(packshotsByZone['SIGN_KIT']).sortBy('order') || [];

            $scope.focusedSibling.medias.modelMedia360 = _($scope.focusedSibling.medias.modelMedia360).sortBy('order') || [];
            $scope.focusedSibling.medias.modelMediaFilms = _($scope.focusedSibling.medias.modelMediaFilms).sortBy('order') || [];
        }

        var mediaNgMetadataModal;

        $scope.openMediaNgMetadata = function (media) {

            if (!mediaNgMetadataModal) {

                if (media.type === 'FILM' && !media.mostRelevantVideo) {
                    return;
                }

                mediaNgMetadataModal = spidModal.open({
                    templateUrl: 'productCardNg/imagesVideos/mediaMetadata/mediaMetadata.html',
                    controller: 'MediaMetadataController',
                    size: 'lg',
                    resolve: {
                        media: function () {
                            return media;
                        }
                    }
                });

                mediaNgMetadataModal.closed.then(function () {
                    mediaNgMetadataModal = null;
                });
            }

        };

        $scope.resetForm = function () {
            _($scope.siblings).each(function (sibling) {
                var pristineSibling = _($scope.pristineSiblings).find(function (pSibling) {
                    return pSibling.code === sibling.code;
                });
                sibling.medias = angular.copy(pristineSibling.medias);
            });
            setNgModified();
            applySiblingChange();
        };

        function setNgModified() {
            $scope.imagesChanged = false;
            _($scope.siblings).each(function (sibling) {
                var pristineSibling = _($scope.pristineSiblings).find(function (pSibling) {
                    return pSibling.code === sibling.code;
                });
                sibling.$somethingChanged = !angular.equals(sibling.medias, pristineSibling.medias);

                $scope.imagesChanged = $scope.imagesChanged || sibling.$somethingChanged || (sibling.medias && sibling.medias.hadTechnicalReorder);
            });
        }

        /***************
         * Drag config *
         ***************/
        $scope.draggedOver = function (zone) {
            $scope.lastDraggedOver = zone;
            return true;
        };

        $scope.removeModelPackshot = function (media, modelPackshots) {
            modelPackshots.splice(modelPackshots.indexOf(media), 1);
            $scope.modelMediaChanged(modelPackshots);
        };

        $scope.removeModelMediaFilm = function (media) {
            $scope.focusedSibling.medias.modelMediaFilms = _($scope.focusedSibling.medias.modelMediaFilms).reject(function (modelMediaFilm) {
                return modelMediaFilm.containerId === media.containerId;
            });
            setNgModified();
        };

        $scope.removeModelMedia360 = function (media) {
            $scope.focusedSibling.medias.modelMedia360 = _($scope.focusedSibling.medias.modelMedia360).reject(function (modelMedia360) {
                return modelMedia360.pixlId === media.pixlId;
            });
            setNgModified();
        };


        $scope.onDrop = function (srcList, srcIndex, targetList, targetIndex, item /*, zone*/) {

            var alreadyAssignedTo = _($scope.focusedSibling.medias.modelPackshots)
                .chain()
                .filter(function (modelPackshot) {
                    return modelPackshot.pixlId === item.pixlId;
                })
                .map(function (modelPackshot) {
                    return modelPackshot.zone;
                })
                .value();


            if (_(alreadyAssignedTo).contains($scope.lastDraggedOver)) {

                if (srcIndex === targetIndex) {
                    console.debug('Le media est déjà affecté dans cette zone ', $scope.lastDraggedOver);
                    return false;
                } else {
                    targetList.splice(targetIndex, 0, targetList.splice(srcIndex, 1)[0]);
                    $scope.modelMediaChanged(targetList);
                    return true;
                }
            } else if ($scope.lastDraggedOver === 'SIGN_KIT') {
                targetList.splice(targetIndex, 0, _(angular.copy(item)).extend({
                    mediaId: null,
                    zone: $scope.lastDraggedOver
                }));
                $scope.modelMediaChanged(targetList);
                return true;
            }
            if ($scope.lastDraggedOver === 'CONTEXT') {

                if (_(alreadyAssignedTo).contains('PRODUCT')) {
                    $scope.removeModelPackshot(_($scope.focusedSibling.medias.modelPackshots).find(function (modelPackshot) {
                        return modelPackshot.zone === 'PRODUCT' && modelPackshot.pixlId === item.pixlId;
                    }), $scope.packshotsProduct);
                }
                targetList.splice(targetIndex, 0, _(angular.copy(item)).extend({
                    mediaId: null,
                    zone: $scope.lastDraggedOver
                }));
                $scope.modelMediaChanged(targetList);
                return true;
            } else if ($scope.lastDraggedOver === 'PRODUCT') {
                if (_(alreadyAssignedTo).contains('CONTEXT')) {
                    $scope.removeModelPackshot(_($scope.focusedSibling.medias.modelPackshots).find(function (modelPackshot) {
                        return modelPackshot.zone === 'CONTEXT' && modelPackshot.pixlId === item.pixlId;
                    }), $scope.packshotsContext);
                }
                targetList.splice(targetIndex, 0, _(angular.copy(item)).extend({
                    mediaId: null,
                    zone: $scope.lastDraggedOver
                }));
                $scope.modelMediaChanged(targetList);
                return true;
            }
        };

        $scope.onDropCartItem = function (srcList, srcIndex, targetList, targetIndex, item /*, zone*/) {
            item = angular.copy(item);
            var alreadyAssignedTo = _($scope.focusedSibling.medias.modelPackshots)
                .chain()
                .filter(function (modelPackshot) {
                    return modelPackshot.pixlId === item.pixlId;
                })
                .map(function (modelPackshot) {
                    return modelPackshot.zone;
                })
                .value();

            if (alreadyAssignedTo.length > 0) {

                if (_(alreadyAssignedTo).contains($scope.lastDraggedOver)) {
                    console.debug('Le media est déjà affecté dans cette zone ', $scope.lastDraggedOver);
                    return false;
                } else if ($scope.lastDraggedOver === 'SIGN_KIT') {
                    item.zone = $scope.lastDraggedOver;
                    item.order = targetIndex;
                    targetList.splice(targetIndex, 0, item);
                    return true;
                } else if ($scope.lastDraggedOver === 'CONTEXT') {
                    if (_(alreadyAssignedTo).contains('PRODUCT')) {
                        $scope.removeModelPackshot(_($scope.focusedSibling.medias.modelPackshots).find(function (modelPackshot) {
                            return modelPackshot.zone === 'PRODUCT' && modelPackshot.pixlId === item.pixlId;
                        }), $scope.packshotsProduct);
                    }
                    item.zone = $scope.lastDraggedOver;
                    item.order = targetIndex;
                    targetList.splice(targetIndex, 0, item);
                    return true;
                } else if ($scope.lastDraggedOver === 'PRODUCT') {
                    if (_(alreadyAssignedTo).contains('CONTEXT')) {
                        $scope.removeModelPackshot(_($scope.focusedSibling.medias.modelPackshots).find(function (modelPackshot) {
                            return modelPackshot.zone === 'CONTEXT' && modelPackshot.pixlId === item.pixlId;
                        }), $scope.packshotsContext);
                    }
                    item.zone = $scope.lastDraggedOver;
                    item.order = targetIndex;
                    targetList.splice(targetIndex, 0, item);
                    return true;
                }
            } else {
                item.zone = $scope.lastDraggedOver;
                item.order = targetIndex;
                targetList.splice(targetIndex, 0, item);

                return true;
            }
        };

        $scope.modelMediaChanged = function (modelMedias /*, dropEffect, event, external, index, item, type*/) {
            var reorderModelMedias = function (modelMedia, index) {
                modelMedia.order = index + 1;
            };

            _(modelMedias).each(reorderModelMedias);

            reassignModelPackshots();
        };

        function reassignModelPackshots() {
            $scope.focusedSibling.medias.modelPackshots = [].concat($scope.packshotsContext).concat($scope.packshotsProduct).concat($scope.packshotsSign);
            setNgModified();
        }

        function getZoneInfosFromType(type) {
            switch (type) {
                case 'MEDIA360':
                    return {
                        medias: $scope.focusedSibling.medias.modelMedia360,
                        equalsMedia: function (m1, m2) {
                            return m1.pixlId === m2.pixlId;
                        }
                    };
                case 'FILM':
                    return {
                        medias: $scope.focusedSibling.medias.modelMediaFilms,
                        equalsMedia: function (m1, m2) {
                            return m1.pixlId === m2.pixlId;
                        }
                    };
            }
        }

        $scope.doDrop = function (item, targetIndex) {
            var zoneInfos = getZoneInfosFromType(item.type);
            var existingMedia = _(zoneInfos.medias).find(function (modelMedia360) {
                return zoneInfos.equalsMedia(modelMedia360, item);
            });

            if (existingMedia) {
                var srcIndex = item.order - 1;
                if (srcIndex === targetIndex) {
                    return false;
                } else {
                    zoneInfos.medias.splice(targetIndex, 0, zoneInfos.medias.splice(srcIndex, 1)[0]);
                    return true;
                }
            } else {
                item.order = targetIndex;
                zoneInfos.medias.splice(targetIndex, 0, item);
                return true;
            }
        };

        var deleteMediaModal;

        $scope.cleanZone = function (modelMedias) {
            if (!deleteMediaModal) {
                deleteMediaModal = dialogService.showDeleteBox('warning.warning', 'warning.deleteMedias');
                deleteMediaModal.result.then(function () {
                    modelMedias.length = 0;
                    reassignModelPackshots();
                });
                deleteMediaModal.closed.then(function () {
                    deleteMediaModal = null;
                });
            }
        };
    }
})();
