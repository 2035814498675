(function () {
    'use strict';

    angular.module('spidwebApp', [
        'ui.router',
        'ui.bootstrap',
        'ui.bootstrap.tooltip',
        'ui.bootstrap.pagination',
        'pascalprecht.translate',
        'webStorageModule',
        'ngMaterial',
        'angulartics',
        'angulartics.google.analytics',
        'ngSanitize',
        'ui.select',
        'textAngular',
        'ngAnimate',
        'tmh.dynamicLocale',
        'templates',
        'angularFileUpload',
        'angular.filter',
        'angular-owl-carousel',
        'duScroll',
        'floatThead',
        'ngInputModified',
        'toastr',
        'angular-intro',
        'angularMoment',
        'dndLists',
        'chart.js',
        'ngclipboard',
        'ngResource'
    ]);

    angular
        .module('spidwebApp')
        .constant('spidLocales', ['fr_FR', 'en_GB'])
        .config(spidwebAppConfig)
        .run(spidwebAppRun)
        .value('ratingBars', ratingBars())
        .value('duScrollBottomSpy', true)
        .config(function ($mdIconProvider) {
            $mdIconProvider
                .iconSet('call', 'img/icons/sets/communication-icons.svg', 24)
                .iconSet('social', 'img/icons/sets/social-icons.svg', 24);
        })
        .controller('MenuCtrl', function MenuCtrl() {

            this.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };

            this.closeMenu = function ($mdMenu, ev) {
                $mdMenu.close(ev);
            };
        });

    angular.module('templates', []);


    moment.tz.setDefault('Europe/Paris');

    /*eslint-disable */
    angular.module('ui.select').run(function ($animate) {
        var overridden = $animate.enabled;
        $animate.enabled = function (elem) {
            if (elem && elem.className && elem.className.includes('ui-select-choices')) {
                return false;
            }
            return overridden(elem);
        };
    });
    /*eslint-enable */

    spidwebAppConfig.$inject =['$stateProvider', '$windowProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$locationProvider', '$mdThemingProvider', '$provide', '$animateProvider', 'inputModifiedConfigProvider', '$mdAriaProvider',

            'envServiceProvider', 'uiSelectConfig', 'messageResolver', 'productListSupport', 'productCardNgResolver', 'tmhDynamicLocaleProvider', 'smComparatorResolver', 'smVersionSearchResolver', 'smVersionSearchByCodeResolver'];

    function spidwebAppConfig($stateProvider, $windowProvider, $urlRouterProvider, $httpProvider, $translateProvider, $locationProvider, $mdThemingProvider, $provide, $animateProvider, inputModifiedConfigProvider, $mdAriaProvider,
        envServiceProvider, uiSelectConfig, messageResolver, productListSupport, productCardNgResolver, tmhDynamicLocaleProvider, smComparatorResolver, smVersionSearchResolver, smVersionSearchByCodeResolver) {


        $mdAriaProvider.disableWarnings();

        tmhDynamicLocaleProvider.localeLocationPattern('/components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.defaultLocale('fr');

        $animateProvider.classNameFilter(/animate/);
        $locationProvider.html5Mode(false).hashPrefix('');

        inputModifiedConfigProvider.disableGlobally();

        uiSelectConfig.theme = 'select2'; // bootstrap, select2, selectize

        $mdThemingProvider.theme('default')
            .primaryPalette('orange')
            .accentPalette('green')
            .warnPalette('red');

        $urlRouterProvider
            .when('/productCard/:id/:language/:country', '/productCard/:id/:language/:country/productIdentity') // Define default Productcard subview
            .otherwise('/home');

        $stateProvider
            .state('home', {
                url: '/home',
                templateUrl: 'home/main.html',
                controller: 'MainController'
            })
            .state('tree', {
                url: '/tree',
                templateUrl: 'tree/tree.html',
                controller: 'TreeController'
            })
            .state('flux', {
                url: '/flux',
                templateUrl: 'admin/flows/flux.html',
                controller: 'FluxController'
            })
            .state('massActionQueue', {
                url: '/massActionQueue',
                templateUrl: 'massAction/massActionQueue/massActionQueue.html',
                controller: 'MassActionQueueController as ctrl'
            })
            .state('massActionClosed', {
                url: '/massActionClosed',
                templateUrl: 'massAction/massActionClosed/massActionClosed.html',
                controller: 'MassActionClosedController as ctrl'
            })
            .state('manualSendManagement', {
                url: '/manual-send-management',
                templateUrl: 'admin/manualSendManagement/manualSendManagement.html',
                controller: 'manualSendManagementController'
            })
            .state('migratedLocales', {
                url: '/migrated-locales',
                templateUrl: 'admin/migratedLocales/migratedLocales.html',
                controller: 'migratedLocalesController'
            })
            .state('massRepublication', {
                url: '/massRepublication',
                templateUrl: 'admin/massRepublication/massRepublication.html',
                controller: 'massRepublicationController'
            })
            .state('message', {
                url: '/message',
                templateUrl: 'admin/informationMessage/message.html',
                controller: 'MessageController',
                resolve: messageResolver
            })
            .state('translation', {
                url: '/translation',
                templateUrl: 'admin/translation/translation.html',
                controller: 'TranslationController'
            })
            .state('countries', {
                url: '/countries/:locale',
                templateUrl: 'admin/country/countries.html',
                controller: 'AddCountryController',
                params: {
                    locale: {value: null}
                }
            })
            .state('agec', {
                url: '/agec',
                templateUrl: 'admin/agec/agec.html',
                controller: 'AgecController',
            })
            .state('productListSupport-search', {
                url: '/productListSupport/search/:code',
                templateUrl: 'productListSupport/productListSupport.html',
                controller: 'ProductListSupportController',
                resolve: productListSupport
            })
            .state('productCardNg', {
                url: '/productCardNg/:smCode?versionDate&smLocale&modelCode&initLocale',
                templateUrl: 'productCardNg/productCardNg.html',
                controller: 'ProductCardNgController',
                //reloadOnSearch: true,
                resolve: productCardNgResolver
            })
            .state('translationMappings', {
                url: '/translationMappings',
                templateUrl: 'admin/translationMappings/translationMappings.html',
                controller: 'translationMappingsController'
            })
            .state('smComparator', {
                url: '/smComparator/:selectedSmVersionIdsWithLocale?locale',
                templateUrl: 'comparator/smComparator.html',
                controller: 'SmComparatorController',
                resolve: smComparatorResolver
            })
            .state('universesManagement', {
                url: '/universesManagement',
                templateUrl: 'admin/universes/universes.html',
                controller: 'UniversesController'
            })
            .state('smVersionSearch', {
                url: '/smVersionSearch?locale&from&to&treeDate&universe&family',
                templateUrl: 'search/smVersionSearch/smVersionSearch.html',
                controller: 'SmVersionSearchController',
                resolve: smVersionSearchResolver
            })
            .state('smVersionSearchByCode', {
                url: '/smVersionSearchByCode?code',
                templateUrl: 'search/smVersionSearch/smVersionSearch.html',
                controller: 'SmVersionSearchController',
                resolve: smVersionSearchByCodeResolver
            });

        $translateProvider
            .useLoader('spidi18n')
            .useSanitizeValueStrategy('sanitizeParameters');


        var env;

        if (envServiceProvider.properties.currentServer) { // Fichier JS hors GIT
            env = envServiceProvider.properties.currentServer;
        } else {
            if (envServiceProvider.properties.current != null) {
                env = envServiceProvider.properties.current;
            } else if ($windowProvider.$get().location.origin === 'http://localhost:6006') {
                env = 'dev';
            } else if ($windowProvider.$get().location.origin === envServiceProvider.properties.int.base) {
                env = 'int';
            } else if (
                [
                    envServiceProvider.properties.preprod.base,
                    'https://spid-webapp.creation-content-staging-0xx8.decathlon.io',
                    'https://spid-preprod.decathlon.net',
                ].indexOf($windowProvider.$get().location.origin) > -1
            ) {
                env = 'preprod';
            } else if (
                [
                    envServiceProvider.properties.production.base,
                    'https://spid.decathlon.net',
                ].indexOf($windowProvider.$get().location.origin) > -1
            ) {
                env = 'production';
            } else if ([
                envServiceProvider.properties.internalProd.base,
                'https://spid-webapp.creation-content-prod-c638.decathlon.io',
            ].indexOf($windowProvider.$get().location.origin) > -1) {
                env = 'internalProd';
            }
        }

        envServiceProvider.setCurrentEnvironment(env);

        // We set the title of the document regarding the current environment
        switch (env) {
            case 'dev':
                $windowProvider.$get().document.title = 'SPID DEV';
                break;
            case 'int':
                $windowProvider.$get().document.title = 'SPID INTEGRATION';
                break;
            case 'preprod':
                $windowProvider.$get().document.title = 'SPID PRE PRODUCTION';
                break;
            case 'production':
                $windowProvider.$get().document.title = 'SPID';
                break;
            default:
                $windowProvider.$get().document.title = 'SPID AUTRE';
                break;
        }

        if (['production', 'preprod'].includes(env)) {
            $windowProvider.$get().DD_RUM.onReady(function () {
                $windowProvider.$get().DD_RUM.init({
                    applicationId: '37780fbd-b7e8-4996-97a0-1a1fa66b29c5',
                    clientToken: 'pub6e0a76b726de9060179629555a0404ba',
                    site: 'datadoghq.eu',
                    service: 'product-information-spid-webapp-frontend',
                    env: env === 'production' ? env : 'staging',
                    sessionSampleRate: 100,
                    trackResources: true,
                    trackLongTasks: true,
                    trackUserInteractions: true,
                    version: envServiceProvider.getVersion(),
                    allowedTracingUrls: [
                        {
                            match: 'https://api.preprod.decathlon.net/spid/web',
                            propagatorTypes: [
                                'datadog'
                            ]
                        }, {
                            match: 'https://api.decathlon.net/spid/web',
                            propagatorTypes: [
                                'datadog'
                            ]
                        }
                    ]
                });
                $windowProvider.$get().DD_RUM.startSessionReplayRecording();
            });
        }


        $httpProvider.interceptors.push('axiosService');

        //Configure default text-angular options
        $provide.decorator('taOptions', taOptions);
        taOptions.$inject = ['$delegate'];

        function taOptions(options) {
            options.toolbar = [
                ['h1', 'h2', 'h3', 'p'],
                ['bold', 'italics', 'underline'],
                ['ul', 'ol']
            ];
            return options;
        }
    }

    spidwebAppRun.$inject = ['$document', '$templateCache', '$timeout', '$anchorScroll', 'wediaService', '$window', '$rootScope', 'spidModal', 'envService', 'userSettings', '$state', 'superModelService', 'dialogService', '$translate', 'countriesService', 'translationService', 'spidLocales', 'toastService', 'parameterService', 'authService', 'axiosService'];

    function spidwebAppRun($document, $templateCache, $timeout, $anchorScroll, wediaService, $window, $rootScope, spidModal, envService, userSettings, $state, superModelService, dialogService, $translate, countriesService, translationService, spidLocales, toastService, parameterService) {

        /*eslint-disable angular/no-run-logic */
        $window.ga('create', envService.getGAAccount(), 'auto');
        $window.setInterval(function() {
            $translate.refresh();
        }, 900000); // refresh translations every 15 minutes

        translationService.initLocalesMapping();
        $rootScope.logged = false;
        $rootScope.spidLocales = spidLocales;
        $rootScope.environment = envService.getCurrentEnvironment();

        userSettings.switchIhmLocale();

        $rootScope.migratedLocales = [];
        parameterService.getParameter('featureFlags', 'locales').then(function(response) {
            $rootScope.migratedLocales = response.split(';');
        });

        $templateCache.put('select2/select-multiple.tpl.html', '<div class="ui-select-container ui-select-multiple select2 select2-container select2-container-multi" ng-class="{\'select2-container-active select2-dropdown-open open\': $select.open, \'select2-container-disabled\': $select.disabled}"><ul class="select2-choices"><span class="ui-select-match"></span><li class="select2-search-field"><input ng-model-options="{ debounce: 500 }" type="search" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="combobox" aria-expanded="true" aria-owns="ui-select-choices-{{ $select.generatedId }}" aria-label="{{ $select.baseTitle }}" aria-activedescendant="ui-select-choices-row-{{ $select.generatedId }}-{{ $select.activeIndex }}" class="select2-input ui-select-search" placeholder="{{$selectMultiple.getPlaceholder()}}" ng-disabled="$select.disabled" ng-hide="$select.disabled" ng-model="$select.search" ng-click="$select.activate()" style="width: 34px;" ondrop="return false;"></li></ul><div class="ui-select-dropdown select2-drop select2-with-searchbox select2-drop-active" ng-class="{\'select2-display-none\': !$select.open || $select.items.length === 0}"><div class="ui-select-choices"></div></div></div>');
        $templateCache.put('select2/select.tpl.html', '<div class="ui-select-container select2 select2-container" ng-class="{\'select2-container-active select2-dropdown-open open\': $select.open, \'select2-container-disabled\': $select.disabled, \'select2-container-active\': $select.focus, \'select2-allowclear\': $select.allowClear && !$select.isEmpty()}"><div class="ui-select-match"></div><div class="ui-select-dropdown select2-drop select2-with-searchbox select2-drop-active" ng-class="{\'select2-display-none\': !$select.open}"><div class="search-container" ng-class="{\'ui-select-search-hidden\':!$select.searchEnabled, \'select2-search\':$select.searchEnabled}"><input ng-model-options="{debounce: 500}" type="search" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" ng-class="{\'select2-active\': $select.refreshing}" role="combobox" aria-expanded="true" aria-owns="ui-select-choices-{{ $select.generatedId }}" aria-label="{{ $select.baseTitle }}" class="ui-select-search select2-input" ng-model="$select.search"></div><div class="ui-select-choices"></div><div class="ui-select-no-choice"></div></div></div>');

        /*eslint-disable angular/no-run-logic */
        $translate.use(userSettings.getSelectedIhmLocale());

        userSettings.authenticate();

        $rootScope.contentsURL = envService.getContentsURL();


        var internationalModal;

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams/*, options*/) {

                var leavingProductCardWithoutSaving = $rootScope.somethingChanged && (toState.name !== 'productCardNg' || (toState.name === 'productCardNg' && !angular.equals(fromParams, toParams)));
                var openingProductCardIntWithoutInitLocale = toState.name === 'productCardNg' && toParams.smLocale === 'int_INT' && !toParams.initLocale;

                function proceed(stateName, params) {
                    $state.go(stateName, params);
                }

                if (leavingProductCardWithoutSaving) {
                    event.preventDefault();

                    var modalInstance = dialogService.showConfirmBox(
                        'product.pending_modifications.popup.title',
                        'product.pending_modifications.popup.description',
                        'product.something_changed.ok',
                        'product.something_changed.cancel'
                    );
                    modalInstance.result
                        .then(function (/*data*/) {
                            $rootScope.somethingChanged = false;
                            proceed(toState.name, toParams);
                        })
                        .then(null, function (/*reason*/) {

                        });
                }

                if (openingProductCardIntWithoutInitLocale) {

                    var internationalLocaleChoice = (userSettings.getFullUser() && userSettings.getUser().userProperties.internationalLocaleChoice) || null;

                    if (internationalLocaleChoice == null) {
                        event.preventDefault();

                        if (!internationalModal) {
                            internationalModal = spidModal.open({
                                templateUrl: 'search/internationalModal/internationalModal.html',
                                controller: 'internationalModal',
                                controllerAs: 'vm',
                                size: 'md'
                            });
                            internationalModal.result.then(function (popupData) {
                                if (popupData.rememberMyChoice) {
                                    userSettings.addUserAdditionalProperty('internationalLocaleChoice', popupData.selectedLocalesInternationalMapping).then(function () {
                                            toParams.initLocale = popupData.selectedLocalesInternationalMapping;
                                            proceed(toState.name, toParams);
                                        });
                                } else {
                                    toParams.initLocale = popupData.selectedLocalesInternationalMapping;
                                    proceed(toState.name, toParams);
                                }
                            });
                            internationalModal.closed.then(function () {
                                internationalModal = null;
                            });
                        }
                    } else {
                        toParams.initLocale = internationalLocaleChoice;
                        proceed(toState.name, toParams);
                    }
                }

            });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState/*, fromParams, options*/) {
                toastService.clearToast();
                if (fromState.name && toState.name !== fromState.name) {
                    $window.scrollTo(0, 0);
                }


                $timeout(function () {
                    $anchorScroll.yOffset = 100;
                    $anchorScroll();
                });
            });

        $rootScope.$on('auth', function (/*event*/) {
            countriesService.getLocales(userSettings.getSelectedIhmLanguage()).then(function (locales) {
                    $rootScope.workingLocale = _(locales)
                    .findWhere({locale: userSettings.getSelectedWorkingLocale()});
                });
            wediaService.initWediaPrivateKey();
            $rootScope.beamerAccount = envService.getBeamerAccount();
            var user = userSettings.getFullUser().user;
            if ($rootScope.beamerAccount !== '' && user) {
                var beamerConfig = angular.element(`<script>
                var beamer_config = {
                    product_id : '${$rootScope.beamerAccount}',
                    selector: 'beamerButton',
                    user_firstname: '${user.additionalsProperties.givenName[0]}',
                    user_lastname: '${user.additionalsProperties.sn}',
                    user_email:'${user.email}',
                    user_id: '${user.uid}/${user.jobName}/${user.country}'
                };</script>`);
                $document.find('body').append(beamerConfig);
                var beamerScript = angular.element('<script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer"></script>');
                $document.find('body').append(beamerScript);
            }
            $window.medalliaCustomParameters = {
                user_firstname: `${user.additionalsProperties.givenName[0]}`,
                user_lastname: `${user.additionalsProperties.sn}`,
                user_email: `${user.email}`,
                user_id: `${user.uid}`,
                user_country: `${user.country}`,
                user_jobs: `${user.additionalsProperties.alljobs.join('@')}`,
                user_job: `${user.jobName}`,
                user_locale: `${user.preferredLanguage}`,
                user_working_locale: `${userSettings.getSelectedWorkingLocale()}`,
            };
            $rootScope.hotjarAccount = envService.getHotjarAccount();
            if ($rootScope.hotjarAccount !== '') {
                var hotjarScript = angular.element(`<script>
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:'${$rootScope.hotjarAccount}',hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                </script>`);
                $document.find('body').append(hotjarScript);
            }
        });

    }

    function ratingBars() {
        return [
            {stateOn: 'icon-bar icon-bar-1', stateOff: 'icon-bar icon-bar-1-empty'},
            {stateOn: 'icon-bar icon-bar-2', stateOff: 'icon-bar icon-bar-2-empty'},
            {stateOn: 'icon-bar icon-bar-3', stateOff: 'icon-bar icon-bar-3-empty'},
            {stateOn: 'icon-bar icon-bar-4', stateOff: 'icon-bar icon-bar-4-empty'},
            {stateOn: 'icon-bar icon-bar-5', stateOff: 'icon-bar icon-bar-5-empty'}
        ];
    }

})();
