(function() {
    'use strict';
    /*eslint-disable */

    angular
        .module('spidwebApp')
        .filter('orderByI18N', orderByI18N);

    orderByI18N.$inject = ['orderByFilter'];

    function orderByI18N (orderByFilter) {

        function Normalize (string) {
            // apply toLowerCase() function
            string = string.toLowerCase();
            // specified letters for replace
            var from = 'àáäâèéëêěìíïîòóöôùúüûñçčřšýžďť';
            var to = 'aaaaeeeeeiiiioooouuuunccrsyzdt';
            // replace each special letter
            for (var i = 0; i < from.length; i++) {
                string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }
            // return normalized string
            return string;
        }

        return function (array, sortPredicate, reverseOrder) {
            // check while array is not undefined
            if (!Array.isArray(array)) { return array; }
            if (!sortPredicate) { return array; }
            // make copy of original array
            var copyOfArray = angular.copy(array);
            // add original items to copied array
            for (var i = 0; i < array.length; i++) {
                // add original value to copied array
                copyOfArray[i]['original'] = array[i];
                // split sortPredicate because of "Entity.Property" values
                var propertyName = sortPredicate.split('.');
                // if greater than 1 then it is object for sure
                if (propertyName.length > 1) {
                    // get object value
                    var object = copyOfArray[i][propertyName[0]];
                    // if object is null then continue
                    if (object == null) {
                        continue;
                    }
                    // get value from object
                    var objectValue = object[propertyName[1]];
                    // if property is null then set to empty string
                    objectValue = objectValue == null ? '' : objectValue;
                    // get specified property and apply toString() (for boolean) and toLowerCase() functions
                    objectValue = objectValue.toString().toLowerCase();
                    // normalize object property and update in array
                    copyOfArray[i][propertyName[0]][propertyName[1]] = Normalize(objectValue);
                    // skip code below and continue from start of loop
                    continue;
                }
                // get property value
                var value = copyOfArray[i][sortPredicate];
                // if property is null then set to empty string
                value = value == null ? '' : value;
                // get specified property and apply toString() (for boolean) and toLowerCase() functions
                value = value.toString().toLowerCase();
                // remove diacritics from property
                copyOfArray[i][sortPredicate] = Normalize(value);
            }
            // apply order by to copied and normalized array
            var normalizedSortedArray = orderByFilter(copyOfArray, sortPredicate, reverseOrder);
            // create empty array for result
            var normalizedSortedOriginalArray = [];
            // get original items from copied and normalized array
            angular.forEach(normalizedSortedArray, function (arrayObject) {
                // push original item to created array
                normalizedSortedOriginalArray.push(arrayObject.original);
            });
            // return original array with diacritics but ordered without diacritics
            return normalizedSortedOriginalArray;

        };

    }

})();
