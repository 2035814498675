(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('WorkspaceController', WorkspaceController);

    WorkspaceController.$inject = [
        'axiosService',
        '$rootScope',
        '$state',
        '$scope',
        'spidModal',
        '$analytics',
        'envService',
        'userSettings',
        'countriesService',
        'parameterService',
    ];

    function WorkspaceController(
        axiosService,
        $rootScope,
        $state,
        $scope,
        spidModal,
        $analytics,
        envService,
        userSettings,
        countriesService,
        parameterService
    ) {
        $scope.lastOpenedItemsIsOpen = false;

        $scope.rightsManagementLink = envService.getRightsManagementLink();
        $rootScope.isAdmin = $scope.isAdmin =
            userSettings.hasRole('ROLE_ADMIN');
        $scope.logoutUrl = envService.getLogoutUrl();
        $scope.version = envService.getVersion();

        $scope.user = userSettings.getUser();
        $scope.fakeUser = null;

        $scope.openReportingUrl = envService.getReportingUrl();

        parameterService
            .getParameter('briefProduct', 'url')
            .then(function (response) {
                $scope.briefProductUrl = response;
            });
        parameterService.getParameter('spo', 'url').then(function (response) {
            $scope.spoUrl = response;
        });
        parameterService
            .getParameter('rangeBook', 'url')
            .then(function (response) {
                $scope.rangeBookUrl = response;
            });
        parameterService
            .getParameter('feature', 'callstack.broadcast')
            .then(function (response) {
                $scope.enableCallstack = response;
            });

        $scope.getLastSearch = function () {
            return userSettings.getLastSearch();
        };

        $scope.search = function (state, code) {
            if (code) {
                $state.go(state, {
                    code: code,
                    locale: userSettings.getSelectedWorkingLocale(),
                });
            }
        };

        $scope.goToSmSearch = function (userInput) {
            if (_.isEmpty(userInput)) {
                return;
            }
            $rootScope.sessionSmSearchLocale = null;
            userSettings.setLastSearch(userInput);

            $analytics.eventTrack('Recherche', {
                category: 'Recherche',
                label: 'Recherche ' + userInput,
            });

            $state.go(
                'smVersionSearchByCode',
                {
                    code: userInput,
                },
                { reload: true }
            );
        };

        $scope.switchIhmLocale = function (locale) {
            userSettings.switchIhmLocale(locale);
            countriesService
                .getLocales(userSettings.getSelectedIhmLanguage())
                .then(function (locales) {
                    $rootScope.workingLocale = _(locales).findWhere({
                        locale: userSettings.getSelectedWorkingLocale(),
                    });
                });
        };

        $scope.lastOpenedItems = [];

        $scope.openItem = function (item) {
            $scope.lastOpenedItemsIsOpen = false;
            $state.go('productCardNg', {
                smCode: item.smCode,
                versionDate: item.versionDate,
                smLocale: item.locale,
                modelCode: item.modelCode,
            });
        };

        $scope.deleteItem = function (item) {
            var idx = $scope.lastOpenedItems.indexOf(item);
            $scope.lastOpenedItems.splice(idx, 1);
        };

        var profilModal, advancedSearchModal;

        $scope.switchIhmLanguage = function (ihmLocale) {
            if (ihmLocale == 'fr_FR') {
                this.switchIhmLocale('en_GB');
            } else if (ihmLocale == 'en_GB') {
                this.switchIhmLocale('fr_FR');
            }
        };

        $scope.displayProfilDlg = function () {
            if (!profilModal) {
                profilModal = spidModal.open({
                    templateUrl: 'user/profile/profile.html',
                    controller: 'ProfilController as vm',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        user: function () {
                            return angular.copy($scope.user);
                        },
                    },
                });
                profilModal.result.then(function () {
                    countriesService
                        .getLocales(userSettings.getSelectedIhmLanguage())
                        .then(function (locales) {
                            $rootScope.workingLocale = _(locales).findWhere({
                                locale: userSettings.getSelectedWorkingLocale(),
                            });
                        });
                });

                profilModal.closed.then(function () {
                    profilModal = null;
                });
            }
        };

        $scope.toggleAdvancedSearchModal = function () {
            if (!advancedSearchModal) {
                advancedSearchModal = spidModal.open({
                    templateUrl:
                        'common/directives/advanced-search/advanced-search.html',
                    controller: 'AdvancedSearchController as ac',
                    size: 'lg',
                });
                advancedSearchModal.result.then(function () {
                    advancedSearchModal = null;
                });

                advancedSearchModal.closed.then(function () {
                    advancedSearchModal = null;
                });
            }
        };

        /* -- END POPUP SUPPORT */

        $rootScope.hasAuthority = function (role) {
            return userSettings.hasAuthority(role);
        };

        $scope.triggerError = function () {
            return axiosService
                .get(envService.getInvalidUrl(), {
                    withCredentials: true,
                })
                .then(function (response) {
                    return response.data;
                });
        };
    }
})();
