(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('modelComposition', modelCompositionDirective)
        .filter('autoExclusiveProductParts', autoExclusiveProductParts)
        .filter('autoExclusiveMaterial', autoExclusiveMaterial);

    modelCompositionDirective.$inject = ['materialService'];

    function modelCompositionDirective (materialService) {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/infosModels/modelComposition/modelComposition.html',
            scope: {
                modelCompositions: '=',
                oldModelComposition: '=',
                oldCompositionMaxSize: '=',
                materialsRepository: '=',
                productPartsRepository: '=',
                readOnly: '=disabled'
            },
            link: function (scope) {
                scope.displayOldModelComposition = !scope.modelCompositions.length;

                scope.modelCompositions = fixCompositionsOrders(scope.modelCompositions);

                scope.toggleCollapse = function () {
                    scope.isCollapsed = !scope.isCollapsed;
                };

                function fixCompositionsOrders (modelCompositions) {

                    /**
                     * orderBy "order" ASC, "quantity" DESC, "id" ASC
                     */
                    modelCompositions = _(modelCompositions)
                        .chain()
                        .sortBy(function(modelComposition){ return modelComposition.id; })
                        .sortBy(function(modelComposition){ return -1 * modelComposition.quantity; })
                        .sortBy('order')
                        .value();

                    var i = 1;
                    modelCompositions.forEach(function (modelComposition) {
                        modelComposition.order = i++;

                        /**
                         * orderBy "quantity" DESC, "id" ASC
                         */
                        modelComposition.rawMaterials = _(modelComposition.rawMaterials)
                            .chain()
                            .sortBy(function(material) { return material.id; })
                            .sortBy(function(material) { return -1 * material.quantity; })
                            .value();
                    });

                    return modelCompositions;

                }

                scope.$watch('modelCompositions', function (newModelCompositions) {

                    materialService.hasCorrectProductPercents(newModelCompositions);

                }, true);

                scope.addNewProductPart = function () {
                    scope.modelCompositions.push({
                        id: null,
                        order: scope.modelCompositions.length + 1,
                        quantity: 0,
                        rawMaterials: [{
                            id: null,
                            quantity: 0,
                            hidePercentage: false
                        }]
                    });
                };

                scope.addNewMaterial = function (materials) {
                    materials.push({
                        id: null,
                        quantity: 0,
                        hidePercentage: false
                    });
                };

                scope.removeMaterial = function (modelComposition, materialToRemove) {

                    if (isTheLastMaterial(modelComposition)) {
                        scope.modelCompositions = _(scope.modelCompositions).without(modelComposition);
                    } else {
                        modelComposition.rawMaterials = _(modelComposition.rawMaterials).without(materialToRemove);
                    }

                    scope.modelCompositions = fixCompositionsOrders(scope.modelCompositions);

                    function isTheLastMaterial (modelCompositionToCheck) {
                        return modelCompositionToCheck.rawMaterials.length === 1;
                    }

                };

            }
        };
    }

    function autoExclusiveProductParts () {
        return function(productPartsRepository, productPartsAffected, actualProductPartId) {
            return _(productPartsRepository).reject(function (productPart) {
                if (actualProductPartId === productPart.id) {
                    return false;
                } else {
                    return !!_(productPartsAffected).findWhere({partOfProductId: productPart.id});
                }
            });
        };
    }

    function autoExclusiveMaterial () {
        return function(materialsRepository, materialsAffected, actualMaterialId) {
            return _(materialsRepository).reject(function (material) {
                if (actualMaterialId === material.id) {
                    return false;
                } else {
                    return !!_(materialsAffected).findWhere({materialId: material.id});
                }
            });
        };
    }

})();
