(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ChangeUserController', ChangeUserController);

    ChangeUserController.$inject = ['$scope'];

    function ChangeUserController ($scope) {

        $scope.changeUser = null;


    }

})();
