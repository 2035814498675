(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('seasonService', seasonService);

    seasonService.$inject = ['axiosService', 'envService'];

    function seasonService(axiosService, envService) {

        return {
            getSeasons: getSeasons
        };

        function getSeasons(query) {
            return axiosService
                .get(
                    envService.getSeasonsContext() +
                        '?' +
                        Object.entries(query)
                            .map(function (e) {
                                return e.join('=');
                            })
                            .join('&'),
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then(function (response) {
                    return response.data;
                });
        }
    }

})();
