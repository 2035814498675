(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('warnings', warnings);

    warnings.$inject = ['envService'];

    function warnings (envService) {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/infoPlus/warnings/warnings.html',
            scope: {
                list: '=',
                repo: '=',
                disabled: '@'
            },
            link: function (scope) {
                scope.readOnly = (scope.disabled === 'true');
                scope.warnings = scope.warnings || [];
                scope.baseUrl = envService.getBaseUrl();

                scope.$watch('disabled', function(){
                    scope.readOnly = (scope.disabled === 'true');
                });
            }
        };
    }
})();
