(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ProfilController', ProfilController);

    ProfilController.$inject = ['$scope', '$q', '$rootScope', '$analytics', '$uibModalInstance', 'user', 'userSettings', 'treeService', 'countriesService', 'translationService'];

    function ProfilController($scope, $q, $rootScope, $analytics, $uibModalInstance, user, userSettings, treeService, countriesService, translationService) {

        $scope.localesInternationalMappings = translationService.getLocalesMapping();
        $scope.user = user;

        $scope.vm.internationalLocaleChoice = (user.userProperties.internationalLocaleChoice) || null;

        var rowLanguagesList = [];
        var colLanguageList = [];

        $scope.profilTree = {
            selectedUniverse: undefined,
            selectedDepartment: undefined,
            selectedSubDepartment: undefined,
            selectedFamily: undefined
        };

        var constructCountriesList = function (country) {
            for (var i = 0; i < country.listLangages.length; i++) {
                if (colLanguageList.length == 6) {
                    rowLanguagesList.push(colLanguageList);
                    colLanguageList = [];
                }
                colLanguageList.push(
                    {
                        label: country.label,
                        language: country.listLangages[i].label,
                        code: country.listLangages[i].code + '_' + country.code
                    });

            }

        };

        $scope.profilLocale = {
            defaultWorkingLocale: userSettings.getSelectedWorkingLocale()
        };

        treeService.getUniverses(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (universes) {
            $scope.universes = universes;
        });

        var treeSettings = userSettings.getPreferedTreeSettings();

        $scope.loadSettings = function () {

            var treePromises = [];
            $scope.init = true;

            countriesService.getCountries(userSettings.getSelectedIhmLanguage()).then(function (data) {
                var deferred = $q.defer();
                for (var i = 1; i <= data.length; i++) {
                    constructCountriesList(data[i - 1], i);
                }
                rowLanguagesList.push(colLanguageList);
                deferred.resolve(rowLanguagesList);

                deferred.promise.then(function (spidCountries) {
                    $scope.spidCountries = [];
                    var countries = _(spidCountries)
                        .chain()
                        .flatten()
                        .sortBy(function (country) {
                            return country.label;
                        })
                        .value();
                    while (countries.length > 0) {
                        $scope.spidCountries.push(countries.splice(0, 4));
                    }
                });

            });

            countriesService.getLocales(userSettings.getSelectedIhmLanguage()).then(function (locales) {
                $scope.locales = locales;
            });

            if (treeSettings.universeId !== null) {
                treeService.getDepartments(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase(), treeSettings.universeId).then(function (departments) {
                    $scope.departments = departments;
                });
                treePromises.push($scope.departments);
            }

            if (treeSettings.departmentId !== null) {
                treeService.getSubDepartments(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase(), treeSettings.departmentId).then(function (subDepartments) {
                    $scope.subDepartments = subDepartments;
                });
                treePromises.push($scope.subDepartments);
            }

            if (treeSettings.subDepartmentId !== null) {
                treeService.getFamilies(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase(), treeSettings.subDepartmentId).then(function (families) {
                    $scope.families = families;
                });
                treePromises.push($scope.families);
            }

            $q.all(treePromises).then(function () {
                $scope.profilTree.selectedUniverse = treeSettings.universeId;
                $scope.profilTree.selectedDepartment = treeSettings.departmentId;
                $scope.profilTree.selectedSubDepartment = treeSettings.subDepartmentId;
                $scope.profilTree.selectedFamily = treeSettings.familyId;

                $scope.init = false;
            });
        };

        $scope.submit = function () {

            $analytics.eventTrack('Changement de locale', {category: 'Profil', label: 'Changement de locale de ' + userSettings.getSelectedWorkingLocale() + ' vers ' + $scope.profilLocale.defaultWorkingLocale});

            if ($scope.profilLocale.defaultWorkingLocale != userSettings.getSelectedWorkingLocale()) {
                userSettings.setSelectedWorkingLocale($scope.profilLocale.defaultWorkingLocale);
            }

            var newTreeSettings = {
                universeId: $scope.profilTree.selectedUniverse,
                departmentId: $scope.profilTree.selectedDepartment,
                subDepartmentId: $scope.profilTree.selectedSubDepartment,
                familyId: $scope.profilTree.selectedFamily
            };
            userSettings.setPreferedTreeSettings(newTreeSettings);

            if ($scope.vm.internationalLocaleChoice == null) {

                userSettings.deleteUserAdditionalProperty('internationalLocaleChoice').then(function () {
                    $uibModalInstance.close();
                });
            }
            else {

                userSettings.addUserAdditionalProperty('internationalLocaleChoice', $scope.vm.internationalLocaleChoice).then(function () {
                    $uibModalInstance.close();
                });
            }

        };

        $scope.getDepartments = function (selectedUniverse) {
            if (!$scope.init) {
                if (selectedUniverse !== null) {
                    treeService.getDepartments(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase(), selectedUniverse).then(function (departments) {
                        $scope.departments = departments;
                    });
                }

                $scope.profilTree.selectedDepartment = null;
                $scope.subDepartments = [];
                $scope.profilTree.selectedSubDepartment = null;
                $scope.families = [];
                $scope.profilTree.selectedFamily = null;
            }
        };

        $scope.getSubDepartments = function (selectedDepartment) {
            if (!$scope.init) {
                if (selectedDepartment !== null) {
                    treeService.getSubDepartments(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase(), selectedDepartment).then(function (subDepartments) {
                        $scope.subDepartments = subDepartments;
                    });
                }
                $scope.subDepartments = [];
                $scope.profilTree.selectedSubDepartment = null;
                $scope.families = [];
                $scope.profilTree.selectedFamily = null;
            }
        };

        $scope.getFamilies = function (selectedSubDepartment) {
            if (!$scope.init) {
                if (selectedSubDepartment !== '') {
                    treeService.getFamilies(getDateOfTheDay(), userSettings.getSelectedIhmLanguage().toUpperCase(), selectedSubDepartment).then(function (families) {
                        $scope.families = families;
                    });
                }
                $scope.families = [];
                $scope.profilTree.selectedFamily = null;
            }
        };

        function getDateOfTheDay() {
            return moment().format('YYYY-MM-DD');
        }

        $scope.filterLocales = function (query) {
            return function (item) {
                if (item.locale === 'en_CN') { return false; }

                query = query.toLowerCase();
                const localeLabel = item.localeLabel.toLowerCase();
                const locale = item.locale.toLowerCase();

                const isInLocaleLabel = localeLabel.indexOf(query) !== -1;
                const isInLocale = locale.indexOf(query) !== -1;
                return isInLocale || isInLocaleLabel;
            };
        };
    }

})();
