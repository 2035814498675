(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('PropagationController', propagationController);

    propagationController.$inject = ['$scope', '$uibModalInstance', 'smVersionId', 'locale', 'datasToPropagateForSm', 'datasToPropagateForModels'];

    function propagationController($scope, $uibModalInstance, smVersionId, locale, datasToPropagateForSm, datasToPropagateForModels) {
        $scope.datasToPropagateForSm = datasToPropagateForSm;
        $scope.datasToPropagateForModels = datasToPropagateForModels;
        $scope.enablePropagation = function () {
            enablePropagation($uibModalInstance, smVersionId, locale, $scope.datasToPropagateForSm, $scope.datasToPropagateForModels);
        };
        $scope.disablePropagation = function ()  {
            disablePropagation($uibModalInstance);
        };
    }

    /**
     * callback function if user choose to propagate datas
     * @param modalInstance
     *          the instance of the propagation modal
     * @param smVersionId
     *          the smVersionId that has the datas to propagate
     * @param locale
     * @param datasToPropagateForSm
     * @param datasToPropagateForModels
     */
    function enablePropagation(modalInstance, smVersionId, locale, datasToPropagateForSm, datasToPropagateForModels) {
        var propagationRequest = {
            smSeasonId: smVersionId,
            locale: locale,
            smDatas: datasToPropagateForSm,
            modelsDatas: datasToPropagateForModels
        };
        var responsePropagation = {
            mustPropagate: true,
            propagationRequest: propagationRequest
        };
        modalInstance.close(responsePropagation);
    }

    /**
     * callback function if user choose to not propagate datas
     * @param modalInstance
     *          the instance of the propagation modal
     */
    function disablePropagation(modalInstance) {
        var responsePropagation = {
            mustPropagate: false
        };
        modalInstance.close(responsePropagation);
    }
})();
