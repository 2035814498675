(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('productNatureService', productNatureService);

    productNatureService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function productNatureService(axiosService, masterdatasCache, envService) {

        var callUrl = function (url, params) {
            return axiosService
                .get(
                    url +
                        '?' +
                        Object.entries(params)
                            .map(([key, val]) => `${key}=${val}`)
                            .join('&'),
                    {
                        withCredentials: true,
                        cache: masterdatasCache,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                )
                .then(function (response) {
                    return response.data;
                });
        };

        return {
            getProductNatureType: function (productNature) {
                return !productNature ? 'OTHER' : productNature.type;
            },
            getProductNature: function (id, locale) {
                var url = envService.getProductNaturesUrl() + '/' + id;
                return callUrl(url, {locale: locale, id: id});
            },
            getProductNatures: function (locale) {
                return callUrl(envService.getProductNaturesUrl(), {locale: locale});
            },
            getProductNaturesArbo: function (country, language) {
                return axiosService
                    .get(
                        envService.getProductNaturesUrl() +
                            '?' +
                            Object.entries({ locale: language + '_' + country })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: masterdatasCache,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        var productNatures = response.data;

                        return _(productNatures)
                            .chain()
                            .sortBy(function (productNature) {
                                return productNature.label.toLowerCase();
                            })
                            .value();
                    });

            }
        };

    }

})();




