(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('AffectationAutoController', AffectationAutoController);

    AffectationAutoController.$inject = ['$scope', 'popupMode', 'mediasCart'];

    function AffectationAutoController ($scope, popupMode, mediasCart) {

        $scope.mediasCart = mediasCart;
        $scope.mediaTypes = [];
        $scope.popupMode = popupMode;

        function init () {

            if (popupMode == 'auto_assign') {
                if (mediasCart.productMedias.length > 0) {
                    $scope.mediaTypes.push('PACKSHOT');
                }
            }
            else if (popupMode == 'copy_medias') {
                if (mediasCart.productContext.length > 0) {
                    $scope.mediaTypes.push('PACKSHOT_SPORT');
                }
                if (mediasCart.videos.length > 0) {
                    $scope.mediaTypes.push('FILM');
                }
            }
        }

        init();

        $scope.changeMediaType = function (mediaType) {
            if (_($scope.mediaTypes).contains(mediaType)) {
                $scope.mediaTypes.splice($scope.mediaTypes.indexOf(mediaType), 1);
            }
            else {
                $scope.mediaTypes.push(mediaType);
            }
        };
    }

})();
