(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('versionService', versionService);

    versionService.$inject = ['axiosService', 'envService'];

    function versionService(axiosService, envService) {

        return {
            hasFuturesVersions: function (smSeasonId, locale, strictCheckModels, modelCodes) {
                return axiosService.get(
                    envService.getVersionsContext() + '/hasFuturesVersions',
                    {
                        params: {
                            smSeasonId,
                            locale,
                            strictModelsCheck: strictCheckModels,
                            modelCodes: modelCodes.join(', ')
                        },
                        data: {},
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            },
            search: function (criteria) {
                return axiosService.get(
                    envService.getVersionsContext(),
                    {
                        params: criteria,
                        data: {},
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            },
            searchByCode: function (criteria) {
                return axiosService.get(
                    envService.getVersionsContext() + '/search',
                    {
                        params: criteria,
                        data: {},
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            },
            getManuallySendableModelVersions: function (smVersionIdAndModelCodes, workingLocale) {
                return axiosService.get(
                    envService.getVersionsContext() + '/manualSendTranslation?smVersionIdAndModelCodes=' + encodeURIComponent(JSON.stringify(smVersionIdAndModelCodes)) + '&workingLocale=' + workingLocale,
                    {
                        data: {},
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            },
            sendManualTranslation: function (modelsToSend, workingLocale) {
                return axiosService.post(
                    envService.getVersionsContext() + '/manualSendTranslation',
                    angular.toJson({
                        manualSendTranslationModels: modelsToSend,
                        workingLocale: workingLocale
                    }),
                    {
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8',
                            'Content-Type': 'application/json;charset=UTF-8'
                        }
                    });
            },
            deleteVersion: function (code, versionDate, versionLocale, modelCodes, userLocale) {
                return axiosService.delete(
                    envService.getVersionsContext(),
                    {
                        withCredentials: true,
                        data: {
                            code: code,
                            versionDate: versionDate,
                            versionLocale: versionLocale,
                            modelCodes: modelCodes,
                            userLocale: userLocale,
                        },
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            },
            deleteManualCreation: function (smCode) {
                return axiosService.delete(
                    envService.getProductCardContext() + '/' + smCode,
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            }
        };
    }
})();
