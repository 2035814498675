/* eslint-disable camelcase */
/* eslint-disable angular/window-service */
/* eslint-disable angular/document-service */
/* eslint-disable angular/timeout-service */
/* eslint-disable strict */
'use strict';

const STORAGE_PREFIX = 'authjs__';

function storeState(state) {
    localStorage.setItem(STORAGE_PREFIX + 'state', JSON.stringify(state));
}

function retrieveState() {
    return JSON.parse(localStorage.getItem(STORAGE_PREFIX + 'state') || 'null');
}

function clearState() {
    localStorage.removeItem(STORAGE_PREFIX + 'state');
}

function storePath(path) {
    localStorage.setItem(STORAGE_PREFIX + 'path', path);
}

function retrievePath() {
    return localStorage.getItem(STORAGE_PREFIX + 'path');
}

function clearPath() {
    localStorage.removeItem(STORAGE_PREFIX + 'path');
}

function storeTokens(tokens) {
    localStorage.setItem(STORAGE_PREFIX + 'tokens', JSON.stringify(tokens));
}

function retrieveTokens() {
    return JSON.parse(
        localStorage.getItem(STORAGE_PREFIX + 'tokens') || 'null'
    );
}

function clearTokens() {
    localStorage.removeItem(STORAGE_PREFIX + 'tokens');
}

function generateRandomString(length) {
    const array = new Uint32Array(length || 28);
    window.crypto.getRandomValues(array);
    return [...array]
        .map(function (x) {
            return x.toString(16);
        })
        .join('');
}

function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(str) {
    return window
        .btoa(String.fromCharCode.apply(null, [...new Uint8Array(str)]))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

function pkceChallengeFromVerifier(verifier) {
    return new Promise(function (resolve, reject) {
        sha256(verifier)
            .then(function (hashed) {
                resolve(base64urlencode(hashed));
            })
            .catch(reject);
    });
}

function decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

function isErrorResponse(input) {
    return Object.keys(input).indexOf('error_code') >= 0;
}

function post(endpoint, body) {
    return new Promise(function (resolve, reject) {
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                if (response.status < 200 || response.status > 299) {
                    return response
                        .json()
                        .then(function (json) {
                            return reject({
                                error_code: response.status || 500,
                                error_message:
                                    json.message ||
                                    response.statusText ||
                                    'Invalid request',
                            });
                        })
                        .catch(reject);
                }
                return response.json();
            })
            .then(resolve)
            .catch(reject);
    });
}

function getToken({
    auth_server,
    code_verifier,
    challengeMethod,
    code,
    state,
    redirect_uri,
}) {
    return post(`${auth_server}/v1/access-tokens`, {
        code_verifier,
        challengeMethod,
        code,
        state,
        redirect_uri,
    });
}

function refreshToken({ auth_server, token }) {
    return post(`${auth_server}/v1/access-tokens/${token}/refresh`, { token });
}

function getAuthorizationCode(options) {
    window.location.href = `${options.authority.replace(
        /\/$/,
        ''
    )}/as/authorization.oauth2?response_type=code&client_id=${
        options.clientId
    }&scope=${encodeURIComponent(
        options.scope.join(' ')
    )}&redirect_uri=${encodeURIComponent(options.redirectUri)}&state=${
        options.state
    }&code_challenge_method=${options.challengeMethod}&code_challenge=${
        options.challenge
    }`;
}

function revokeToken({ auth_server, token }) {
    return post(`${auth_server}/v1/revoke-access-token`, {
        token,
    });
}

function AuthProvider(options) {
    var authority = options.authority;
    var challengeMethod = options.challengeMethod;
    var clientId = options.clientId;
    var redirectUri = options.redirectUri;
    var scope = options.scope;
    var authServer = options.authServer;
    var maxRefreshInterval = options.maxRefreshInterval || 30000;
    var onBeforeSignIn = options.onBeforeSignIn || function () {};
    var onSignIn = options.onSignIn || function () {};
    var onSignOut = options.onSignOut || function () {};

    var accessToken = null;
    var expires = -1;
    var user = null;

    var searchParams = new URLSearchParams(window.location.search);

    var queryCode = searchParams.get('code') || '';
    var queryState = searchParams.get('state') || '';

    var initRefreshTimeout = function (expiresAt) {
        window.setTimeout(() => {
            handleRefresh().catch(console.error);
        }, expiresAt - Date.now() - maxRefreshInterval);
    };

    var handleRefresh = function () {
        var token = retrieveTokens();
        // eslint-disable-next-line
        if (!!token) {
            return refreshToken({
                auth_server: authServer,
                token: token.refresh_token,
            })
                .then(handleToken)
                .catch(function (error) {
                    var statusCode = error.error_code;
                    var message = error.error_message || '';
                    if (
                        isErrorResponse(error) &&
                        (statusCode === 500 ||
                            (statusCode === 400 &&
                                message.indexOf('expired') >= 0))
                    ) {
                        clearTokens();
                        loginRedirect();
                    }
                    return error;
                });
        }
        return null;
    };

    var getTokenCatch = function () {
        var storedPath = retrievePath();
        var storedState = retrieveState();
        if (storedPath) {
            clearPath();
        }
        if (storedState) {
            clearState();
        }
        var redirectionPath = storedPath || '/';
        window.history.replaceState({}, document.title, redirectionPath);
    };

    var buildAndSetToken = function (authResponse, expiresIn, revoke_token) {
        storeTokens({
            access_token: authResponse.access_token,
            refresh_token: authResponse.refresh_token,
            expires: expiresIn,
            revoke_token,
        });
    };

    var getRevokeToken = function (authResponse) {
        var storedToken = retrieveTokens();
        if (storedToken) {
            return storedToken.revoke_token;
        }
        const idToken = authResponse.id_token;
        var _user = decodeJWT(idToken);
        return _user['pi.sri'];
    };

    var handleToken = function (response, newConnection) {
        const authResponse = response;
        const userData = decodeJWT(
            authResponse.id_token || authResponse.access_token
        );
        const _revokeToken = getRevokeToken(response);
        const expiresAt = Date.now() + authResponse.expires_in * 1000;
        const storedPath = retrievePath();
        const redirectionPath = storedPath || '/';
        storeTokens({
            access_token: authResponse.access_token,
            expires: expiresAt,
            refresh_token: authResponse.refresh_token,
            revoke_token: _revokeToken,
        });
        accessToken = authResponse.access_token;
        user = userData;
        if (storedPath) {
            clearPath();
        }
        clearState();
        buildAndSetToken(authResponse, expiresAt, _revokeToken);
        if (newConnection) {
            window.location.href = redirectionPath;
        } else {
            const storedTokens = retrieveTokens();
            if (storedTokens && checkTokenValidity()) {
                accessToken = storedTokens.access_token;
                user = decodeJWT(
                    authResponse.id_token || authResponse.access_token
                );
            }
            initRefreshTimeout(expiresAt);
        }
        if (window.DD_RUM) {
            window.DD_RUM.setUser({
                id: userData.sub
            });
        }
        onSignIn({ user: userData, path: redirectionPath });
        return response;
    };

    var checkTokenValidity = function () {
        var token = retrieveTokens();
        return (
            token &&
            token.expires > Date.now() &&
            token.expires - Date.now() > maxRefreshInterval
        );
    };

    var redirect = function (state, challenge) {
        const path = `${window.location.pathname}${window.location.search}${window.location.hash}`;
        storePath(path);
        onBeforeSignIn(path);
        return getAuthorizationCode({
            authority: authority,
            challengeMethod: challengeMethod,
            clientId: clientId,
            redirectUri: redirectUri,
            scope: scope,
            challenge,
            state,
        });
    };

    var loginRedirect = function () {
        var state = generateRandomString();
        var verifier = generateRandomString(12);
        pkceChallengeFromVerifier(verifier)
            .then(function (challenge) {
                storeState({
                    state,
                    challenge,
                    verifier,
                });
                redirect(state, challenge);
            })
            .catch(console.error);
    };

    var init = function () {
        var storedTokens = retrieveTokens();
        var storedState = retrieveState();
        var isTokenValid = checkTokenValidity();
        if (!storedTokens) {
            if (!storedState && !(queryCode || queryState)) {
                loginRedirect();
            } else {
                const errors = [];
                if (!storedState.verifier) {
                    errors.push('No verifier found in stored state');
                }
                if(!queryCode) {
                    errors.push('No code found in query string');
                }
                if (!queryState) {
                    errors.push('No state found in query string');
                }
                if (errors.length > 0) {
                    console.error(errors.join(', '));
                }
                getToken({
                    auth_server: authServer,
                    code_verifier: storedState.verifier,
                    challengeMethod: challengeMethod,
                    code: queryCode,
                    state: queryState,
                    redirect_uri: redirectUri,
                })
                    .then(function (response) {
                        handleToken(response, true).catch(function (error) {
                            console.error(error);
                        });
                    })
                    .catch(getTokenCatch);
            }
        } else if (storedTokens.expires <= Date.now()) {
            clearTokens();
            loginRedirect();
        } else if (storedTokens.expires - Date.now() <= maxRefreshInterval) {
            handleRefresh().catch(function (error) {
                console.error(error);
            });
        } else {
            if (isTokenValid) {
                accessToken = storedTokens.access_token;
                expires = storedTokens.expires;
                handleRefresh().catch(function (error) {
                    console.error(error);
                });
            }
            initRefreshTimeout(storedTokens.expires);
        }
    };

    init();

    return {
        accessToken,
        expires,
        user,
        signOut: function () {
            return new Promise(function (resolve, reject) {
                revokeToken({
                    auth_server: authServer,
                    token: accessToken || '',
                })
                    .then(function () {
                        clearPath();
                        clearState();
                        clearTokens();
                        onSignOut({ token: accessToken });
                        resolve();
                    })
                    .catch(reject);
            });
        },
    };
}

angular.module('spidwebApp').factory('authService', authService);

authService.$inject = ['envService'];

function authService(envServiceProvider) {
    const env =
        envServiceProvider.properties[
            envServiceProvider.getCurrentEnvironment()
        ];
    return AuthProvider({
        authority: env.authAuthority,
        clientId: env.authClientId,
        redirectUri: env.authRedirectUri,
        scope: env.authScope,
        authServer: env.authAuthServer,
        challengeMethod: env.authChallengeMethod,
        onSignIn: function () {},
        onBeforeSignIn: function () {},
        onSignOut: function () {},
    });
}
