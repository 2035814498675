(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('PublishValidationController', PublishValidationController);

    PublishValidationController.$inject = ['$scope', '$uibModalInstance', 'model', 'translationRequest'];

    function PublishValidationController ($scope, $uibModalInstance, model, translationRequest) {

        $scope.translationRequest = translationRequest;
        $scope.model = model;

        $scope.close = function () {
            $uibModalInstance.dismiss();
        };

        $scope.validatePublication = function (launchIt) {
            $uibModalInstance.close(launchIt);
        };

    }
 })();
