(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('brandService', brandService);

    brandService.$inject = ['axiosService', 'envService'];

    function brandService(axiosService, envService) {

        return {
            getBrands: function () {
                return axiosService
                    .get(envService.getBrandURL(), {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    })
                    .then(function (response) {
                        return response.data;
                    });
            },
            getBrandLogos: function (brandId) {
                return axiosService
                    .get(envService.getBrandURL() + '/' + brandId + '/logos', {
                        withCredentials: true,
                        cache: false,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    })
                    .then(function (response) {
                        return response.data;
                    });
            },
            saveBrand: function (modelCode, brandId) {
                return axiosService
                    .get(
                        envService.getBrandURL() +
                            '/model/' +
                            modelCode +
                            '?' +
                            Object.entries({
                                brandId: brandId,
                            })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: false,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            }
        };
    }

})();
