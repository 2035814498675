(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('productLocale', productLocale);

    function productLocale () {
        return {
            link: function(scope, element, attrs) {

                var el;
                var locale = attrs['productLocale'];
                updateSrc();

                element.bind('error', function() {
                    setError();
                });

                var productLocaleObserver = attrs.$observe('productLocale', function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        locale = newValue;

                        updateSrc();
                    }
                });

                function updateSrc() {
                    if (!locale) {
                        setError();
                        attrs.$set('title', undefined);
                        attrs.$set('src', undefined);

                        return;
                    }
                    if (locale === 'int_INT') {
                        attrs.$set('src', 'common/styles/img/international/international_int_INT.png');
                    } else {
                        attrs.$set(
                            'src',
                            `common/styles/img/flags/${locale
                                .split('_')[locale.indexOf('INT') > -1 ? 0 : 1]
                                .toLowerCase()}.svg`
                        );
                    }
                }
                scope.$on('$destroy', function () {
                    if (productLocaleObserver) {
                        productLocaleObserver();
                    }
                });

                function setError() {

                    if (!el) {
                        el = angular.element('<span>'+locale+'</span>');
                    }

                    element.parent().append(el);
                }
            }
        };
    }

})();
