(function(){
    'use strict';

    angular.module('spidwebApp').controller('AddCountryController', AddCountryController);

    AddCountryController.$inject = [ '$scope', '$q', '$filter', '$timeout', '$state', '$stateParams', 'toastService', 'zdService', 'countriesService'];

    function AddCountryController($scope, $q, $filter, $timeout, $state, $stateParams, toastService, zdService, countriesService){

        $scope.edit = !!$stateParams.locale;
        $scope.choosenLoc = $stateParams.locale;

        $scope.changeLocale = function (newLocale) {
            $state.go('countries', {locale: newLocale}, {notify: false});
            $scope.edit = true;
            $scope.locChange(newLocale);
        };
        $scope.createCountry = function () {
            $state.go('countries', {locale: null}, {notify: false});
            $scope.choosenLoc = null;
            init();
            $scope.edit = false;
        };


        function init() {
            $scope.videoCode = '';
            $scope.mediaMaster = '';


            var promises = [zdService.getAllZd(), countriesService.getLanguageDependencies(), countriesService.getCountries('FR')];

            $q.all(promises).then(function (promisesTab) {
                var zds = promisesTab[0];
                $scope.zds = [];
                for( var i in zds) {
                    $scope.zds.push(zds[i].id);
                }

                var dependencies = promisesTab[1];
                var displayName = function(dependency) {
                    return (dependency.father != null ? displayName(dependency.father) + ' > ' : '') + dependency.locale;
                };

                $scope.dependencies = _(dependencies)
                    .chain()
                    .each(function (dependency) {
                        if (dependency.fatherLocale != null) {
                            dependency.father = _(dependencies).findWhere({
                                locale: dependency.fatherLocale,
                                level: dependency.level - 1
                            });
                        }
                    })
                    .map(function (dependency) {
                        return {
                            dependency: dependency,
                            name: displayName(dependency),
                            selected: false
                        };
                    })
                    .sortBy(function (dependency) {
                        return dependency.name;
                    })
                    .value();

                var countries = promisesTab[2];
                $scope.allLanguages = [];
                countries.forEach(function (country) {
                    country.listLangages.forEach(function (language) {
                        $scope.allLanguages.push(language.code + '_' + country.code);
                    });
                });
                $scope.allLanguages.sort();




            }).then(function () {

                if (!$scope.edit) {
                    $scope.newCountry = {
                        locale: '',
                        zd: '',
                        fullrange: 0,
                        rightToLeft: false,
                        fullTranslation: false,
                        transline: '',
                        labelCountryFR: '',
                        labelCountryEN: '',
                        labelLangueFR: '',
                        labelLangueEN: '',
                        sendToTransline: false,
                        receiveFromTransline: false,
                        dependencies: [],
                        videoMapping: [],
                        media: {
                            def: false,
                            masterCountry: []

                        },
                        opening: 0,
                        season: [],
                        responsible: []
                    };
                    $scope.newCountry.zd = $scope.zds[0];
                }
                else {

                    $scope.locChange($scope.choosenLoc).then(function () {
                        $scope.newCountry.zd = $scope.zds[0];
                    });
                }
            });



        }


        init();

        $scope.addNewVideoCodeButtonDisable = function(){
            return !$scope.videoCode;
        };

        $scope.addNewMediaMasterButtonDisable = function(){
            return !$scope.mediaMaster;
        };

        $scope.addNewOpeningResponsibleButtonDisable = function () {
            return !$scope.openingResponsible;
        };

        // ajout video priority
        $scope.addVideoCode = function(){
            var video = {
                locale: '',
                codeLangue: $scope.videoCode,
                priority: ''
            };
            $scope.newCountry.videoMapping.push(video);
            $scope.videoCode = '';

            videoCodeReOrder();
        };

        // ajout media master
        $scope.addMediaMaster = function(){
            $scope.newCountry.media.masterCountry.push($scope.mediaMaster);
            $scope.mediaMaster = '';
        };

        // ajout season
        $scope.addSeason = function () {
            $scope.newCountry.season.push($scope.seasonCode);
            $scope.seasonCode = '';
        };

        // ajout responsible
        $scope.addResponsible = function () {
            $scope.newCountry.responsible.push($scope.responsibleMail);
            $scope.responsibleMail = '';
        };
        $scope.removeVideoCode = function(index){
            $scope.newCountry.videoMapping.splice(index, 1);
            angular.forEach($scope.newCountry.videoMapping, function(code, idx){
                $scope.newCountry.videoMapping[idx].priority = idx + 1;
            });
        };

        $scope.removeMediaMaster = function(index){
            $scope.newCountry.media.masterCountry.splice(index, 1);
        };

        $scope.removeSeason = function (index) {
            $scope.newCountry.season.splice(index, 1);
        };

        $scope.removeResponsible = function (index) {
            $scope.newCountry.responsible.splice(index, 1);
        };

        // mise à jour de l'ordre des priorités
        var videoCodeReOrder = function(){
            var videoCodes = $scope.newCountry.videoMapping;
            for(var i = 0; i < videoCodes.length; i++) {
                var videoCode = videoCodes[i];
                videoCode.priority = i + 1;
            }
        };

        // changement d'ordre
        $scope.sortableOptions = {
            update: function(){
                $timeout(function(){
                    angular.forEach($scope.newCountry.videoMapping, function(code, index){
                        code.priority = index + 1;
                    });
                });
            }
        };

        $scope.locChange = function(locale) {
            return countriesService.getCountryData(locale).then(function (data) {
                // On remonte au parent
                $scope.newCountry = data;
                $scope.dependencies.forEach(function(dependency) {
                    dependency.selected = angular.isDefined(_($scope.newCountry.dependencies).findWhere({
                        locale: dependency.dependency.locale,
                        level: dependency.dependency.level
                    }));
                });
                for(var j in $scope.newCountry.videoMapping) {
                    var prio = $scope.newCountry.videoMapping[j].priority;
                    $scope.newCountry.videoMapping[j].priority = prio + 1;
                }
            })
            .finally(function () {
                $scope.$apply();
            });
        };

        $scope.addCountry = function() {
            // recupérer les noeuds d'arbo language dependencies
            $scope.newCountry.dependencies = [];

            for(var i in $scope.dependencies) {
                if ($scope.dependencies[i].selected) {
                    $scope.newCountry.dependencies.push($scope.dependencies[i].dependency);
                }
            }

            // remplir la locale dans les videos
            for(var j in $scope.newCountry.videoMapping) {
                $scope.newCountry.videoMapping[j].locale = $scope.newCountry.locale;
                var prio = $scope.newCountry.videoMapping[j].priority;
                $scope.newCountry.videoMapping[j].priority = prio - 1;
            }





            if($scope.edit){
                countriesService.saveCountry($scope.newCountry).then(function() {
                    toastService.displayToast($filter('translate')('admin.editCountry.success'), 'success');
                    $scope.locChange($scope.newCountry.locale).then(function () {
                        init();
                    })
                    .finally(function () {
                        $scope.$apply();
                    });
                });
            }
            else {
                countriesService.createCountry($scope.newCountry).then(function() {
                    $state.go('countries', {locale: $scope.newCountry.locale}).then(function () {
                        toastService.displayToast($filter('translate')('admin.addCountry.success'), 'success');
                    });
                });
            }
        };

    }
})();
