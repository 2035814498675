(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('pixlMedia', pixlMedia);

    pixlMedia.$inject = ['wediaService'];

    function pixlMedia (wediaService) {

        return {
            restrict: 'E',
            scope: {
                media: '=',
                mediaClick: '&',
                mediaRemove: '&',
                removable: '=',
                language: '='
            },
            replace: true,
            transclude: true,
            templateUrl: 'common/directives/pixl/pixl-media.html',
            link: function (scope) {
                scope.mediaURL =  wediaService.getMediaURL(scope.media, 50);
                scope.willExpireSoon = wediaService.willExpireInMonths(scope.media, 6);
                scope.hasExpired = wediaService.hasExpired(scope.media);
            }
        };
    }

})();
