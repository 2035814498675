(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .filter('momentFormatter', momentFormatter)
        .filter('momentParse', momentParse);

    function momentFormatter () {
        return function (input, format, format2) {
            var dateTime;
            if (!format2) {
                dateTime = moment(input);
                return dateTime.format(format);
            } else {
                //special fix oxylane
                dateTime = moment(input, format);
                return dateTime.format(format2);
            }
        };
    }

    function momentParse () {
        return function (input, parseFormat) {
            return moment(input, parseFormat);
        };
    }

})();
