(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('TranslationController', translationController);

    translationController.$inject = ['$scope', 'spidModal', '$translate', 'dialogService', 'translationService'];

    function translationController ($scope, spidModal, $translate, dialogService, translationService) {


        $scope.translationValueChanged = function (value) {
            $scope.hasHTML = new RegExp(/(<([^>]+)>)/ig).test(value);
        };


        $scope.criteria = {
            context: null,
            key: null,
            language: null,
            value: null
        };

        $scope.searchTranslations = function () {

            translationService.search($scope.criteria).then(function (data) {
                $scope.translations = data.data;
            }).catch(function () {
                $scope.translations = [];
            })
            .finally(function () {
                $scope.$apply();
            });
        };

        $scope.deleteTranslation = function (translation) {
            dialogService.showConfirmBox('admin.translation.delete.confirm.title', 'admin.translation.delete.confirm.message').result.then(function () {
            translationService.delete(translation).then(function () {
                $scope.searchTranslations();
            })
            .finally(function () {
                $scope.$apply();
            });
            });
        };

        var translationModal;

        $scope.openTranslation = function (originalTranslation, editMode) {
            if (!translationModal) {
            $scope.translation = angular.copy(originalTranslation);
            $scope.editMode = editMode;

                $scope.translationValueChanged(originalTranslation.value);

                translationModal = spidModal
                .open({
                    templateUrl: 'admin/translation/popup/translationPopup.html',
                    size: 'lg',
                    scope: $scope
                    });
                translationModal.result
                .then(function (modifiedTranslation) {
                    if (editMode) {
                        translationService.edit(modifiedTranslation).then(function (response) {
                            $scope.translations = [response.data];
                        })
                        .finally(function () {
                            $scope.$apply();
                        });
                    }
                    else {
                        translationService.create(modifiedTranslation).then(function (response) {
                            $scope.translations = [response.data];
                        })
                        .finally(function () {
                            $scope.$apply();
                        });
                    }
                }, function () {

                });
                translationModal.closed.then(function () {
                    translationModal = null;
                });
            }
        };


        $scope.clearCache = function () {
            return translationService.clearCache().then(function () {
                return $translate.refresh();
            });
        };

    }
})();
