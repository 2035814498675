(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('mediaService', mediaService);

    mediaService.$inject = ['axiosService', 'envService'];

    function mediaService(axiosService, envService) {

        return {
            hasObsoleteMedias: function(siblings, code) {
                return _(siblings || [])
                    .chain()
                    .filter(function (sibling) {
                        return code ? sibling.code === code : true;
                    })
                    .map(function (sibling) {
                        return []
                            .concat(
                                _(sibling.medias.modelPackshots).map(function (
                                    modelPackshot
                                ) {
                                    return modelPackshot;
                                })
                            )
                            .concat(
                                _(sibling.medias.modelMedia360).map(function (
                                    modelMedia360
                                ) {
                                    return modelMedia360;
                                })
                            )
                            .concat(
                                _(sibling.medias.modelMediaFilms).map(function (
                                    modelMediaFilm
                                ) {
                                    return modelMediaFilm.mostRelevantVideo;
                                })
                            );
                    })
                    .flatten()
                    .compact()
                    .some(function (media) {
                        return media.obsolete;
                    })
                    .value();
            },
            getObsoleteMedias: function(pixlIds, modelCodes) {
                let url = envService.getPixlUrl() + '/obsoleteMedias?';
                if(pixlIds.length) {
                    url += 'pixlIds=' + pixlIds.join(',') + '&';
                }
                if(modelCodes && modelCodes.length) {
                    url += 'modelCodes=' + modelCodes.join(',') + '&';
                }
                return axiosService.get(url,
                    {
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        },
                        timeout: 10000
                    }).then(function (response) {
                        return Promise.resolve(response.data);
                    });
            },
            checkForBalisage: function (modelMedias) {
                var hasBalisage = _(modelMedias.modelPackshots).some({
                    zone: 'SIGN_KIT'
                });

                if (!hasBalisage) {
                    var packshotProduct = _(modelMedias.modelPackshots).find({
                        zone: 'PRODUCT',
                        order: 1
                    });

                    if (angular.isDefined(packshotProduct)) {
                        var newBalisage = angular.copy(packshotProduct);
                        newBalisage.zone = 'SIGN_KIT';
                        newBalisage.order = 1;
                        newBalisage.mediaId = null;
                        modelMedias.modelPackshots.push(newBalisage);
                        return true;
                    }
                }
                return false;
            },
            getMedias: function (alfrescoId, locale) {
                return axiosService.get(
                    envService.getModelsContext() + '/' + alfrescoId + '/medias',
                    {
                        params: { locale },
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    }).then(function (response) {
                        return Promise.resolve(response.data);
                    });
            },
            getMasterModelPackshots: function (modelSeasonId) {

                var self = this;

                return axiosService.get(
                    envService.getModelSeasonsContext() + '/' + modelSeasonId + '/masterModelPackshots',
                    {
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    }).then(function (response) {
                        return Promise.resolve(self.wrapModelMedias({masterModelPackshots: response.data}.masterModelPackshots));
                    });
            },
            duplicateMedias: function (modelNodeId, country, language) {
                return axiosService.get(
                    envService.getDuplicateMediasUrl(modelNodeId),
                    {
                        params: { targetLocale: language + '_' + country },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    }
                )
                .then(function (response) {
                    return Promise.resolve(response.data);
                });

            },
            wrapModelMedias: function(modelMedias) {
                modelMedias.masterModelPackshots = _(modelMedias.masterModelPackshots).each(function (modelPackshot){
                    if(!modelPackshot.obsolete){
                        modelPackshot.obsolete = false;
                    }
                    modelPackshot.type = 'PACKSHOT';
                });
                modelMedias.modelPackshots = _(modelMedias.modelPackshots).each(function (modelPackshot) {
                    if(!modelPackshot.obsolete) {
                        modelPackshot.obsolete = false;
                    }
                    modelPackshot.type = 'PACKSHOT';
                });
                modelMedias.modelMedia360 = _(modelMedias.modelMedia360).each(function (modelMedia360) {
                    if (!modelMedia360.obsolete) {
                        modelMedia360.obsolete = false;
                    }
                    modelMedia360.type = 'MEDIA360';
                });
                modelMedias.modelMediaFilms = _(modelMedias.modelMediaFilms).each(function (modelMediaFilm) {
                    modelMediaFilm.languages = [];
                    modelMediaFilm.videos.forEach(function (video) {
                        modelMediaFilm.languages.push({
                            code: video.language
                        });
                    });
                    if (modelMediaFilm.mostRelevantVideo) {
                        if(!modelMediaFilm.mostRelevantVideo.obsolete) {
                            modelMediaFilm.mostRelevantVideo.obsolete = false;
                        }
                    }
                    modelMediaFilm.type = 'FILM';
                });

                return modelMedias;
            },
            fixMediaDisplayOrder: function(siblingMedias, sortByName = false) {

                _(siblingMedias.modelPackshots)
                    .chain()
                    .filter(function (modelPackshot) {
                        return modelPackshot.zone === 'PRODUCT';
                    })
                    .sortBy(function (modelPackshot) {
                        if (sortByName) {
                            return modelPackshot.name;
                        }
                        return modelPackshot.order;
                    })
                    .each(function (modelPackshot, index) {
                        modelPackshot.order = index + 1;
                    });

                _(siblingMedias.modelPackshots)
                    .chain()
                    .filter(function (modelPackshot) {
                        return modelPackshot.zone === 'CONTEXT';
                    })
                    .sortBy(function (modelPackshot) {
                        if (sortByName) {
                            return modelPackshot.name;
                        }
                        return modelPackshot.order;
                    })
                    .each(function (modelPackshot, index) {
                        modelPackshot.order = index + 1;
                    });

                _(siblingMedias.modelPackshots)
                    .chain()
                    .filter(function (modelPackshot) {
                        return modelPackshot.zone === 'SIGN_KIT';
                    })
                    .sortBy(function (modelPackshot) {
                        if (sortByName) {
                            return modelPackshot.name;
                        }
                        return modelPackshot.order;
                    })
                    .each(function (modelPackshot, index) {
                        modelPackshot.order = index + 1;
                    });

                _(siblingMedias.modelMedia360)
                    .chain()
                    .sortBy(function (modelMedia) {
                        if (sortByName) {
                            return modelMedia.name;
                        }
                        return modelMedia.order;
                    })
                    .each(function (modelMedia, index) {
                        modelMedia.order = index + 1;
                    });

                _(siblingMedias.modelMediaFilms)
                    .chain()
                    .sortBy(function (modelMedia) {
                        if (sortByName) {
                            return modelMedia.name;
                        }
                        return modelMedia.order;
                    })
                    .each(function (modelMedia, index) {
                        modelMedia.order = index + 1;
                    });

                return siblingMedias;
            },
            getAvailableLanguages: function (country, language) {
                return axiosService.get(
                    envService.getVideoAvailableLanguages(),
                    {
                        params: { locale: language + '_' + country },
                        withCredentials: true,
                        cache: true,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    }).then(function (response) {
                        return Promise.resolve(response.data);
                    });
            },

            retrieveObsoleteMediasReconduction: function (sibling) {

                if (sibling.length === 0) {
                    return sibling;
                }

                var allPixlIds =
                    _(sibling.medias.modelPackshots).map(function (modelPackshot) {
                        return !(modelPackshot.obsolete = false) && modelPackshot.pixlId;
                    })
                        .concat(
                            _(sibling.medias.masterModelPackshots).map(function (modelPackshot) {
                                return !(modelPackshot.obsolete = false) && modelPackshot.pixlId;
                            }))
                        .concat(
                            _(sibling.medias.modelMedia360).map(function (modelMedia360) {
                                return !(modelMedia360.obsolete = false) && modelMedia360.pixlId;
                            }))
                        .concat(
                            _(sibling.medias.modelMediaFilms).map(function (modelMediaFilm) {
                                return modelMediaFilm.mostRelevantVideo && !(modelMediaFilm.mostRelevantVideo.obsolete = false) && modelMediaFilm.mostRelevantVideo.pixlId;
                            }));
                if (allPixlIds.length == 0) {
                    return;
                }
                return this.getObsoleteMedias(allPixlIds).then(function (response) {
                    var mediasRightsEndDate = response.itemsExpiration;
                    var obsoletePixlIds = response.obsoletePixlIds;
                    _(sibling.medias.modelPackshots)
                        .chain()
                        .each(function (modelPackshot) {
                            modelPackshot.validateToDate = mediasRightsEndDate[modelPackshot.pixlId];
                        })
                        .filter(function (modelPackshot) {
                            return _(obsoletePixlIds).contains(modelPackshot.pixlId);
                        })
                        .each(function (modelPackshot) {
                            modelPackshot.obsolete = true;
                        });

                    _(sibling.medias.masterModelPackshots)
                        .chain()
                        .each(function (modelPackshot) {
                            modelPackshot.validateToDate = mediasRightsEndDate[modelPackshot.pixlId];
                        })
                        .filter(function (modelPackshot) {
                            return _(obsoletePixlIds).contains(modelPackshot.pixlId);
                        })
                        .each(function (modelPackshot) {
                            modelPackshot.obsolete = true;
                        });

                    _(sibling.medias.modelMedia360)
                        .chain()
                        .each(function (modelMedia360) {
                            modelMedia360.validateToDate = mediasRightsEndDate[modelMedia360.pixlId];
                        })
                        .filter(function (modelMedia360) {
                            return _(obsoletePixlIds).contains(modelMedia360.pixlId);
                        })
                        .each(function (modelMedia360) {
                            modelMedia360.obsolete = true;
                        });

                    _(sibling.medias.modelMediaFilms)
                        .chain()
                        .each(function (modelMediaFilm) {
                            if (modelMediaFilm.mostRelevantVideo) {
                                modelMediaFilm.mostRelevantVideo.validateToDate = mediasRightsEndDate[modelMediaFilm.mostRelevantVideo.pixlId];
                            }
                        })
                        .filter(function (modelMediaFilm) {
                            return modelMediaFilm.mostRelevantVideo && _(obsoletePixlIds).contains(modelMediaFilm.mostRelevantVideo.pixlId);
                        })
                        .each(function (modelMediaFilm) {
                            modelMediaFilm.mostRelevantVideo.obsolete = true;
                        });
                    return sibling;
                }, function () {
                    return sibling;
                });
            },

            retrieveObsoleteMedias: function(siblings = []) {

                if (siblings.length === 0) {
                    return siblings;
                }

                var allPixlIds = _(siblings)
                    .chain()
                    .map(function (sibling) {

                        return []
                            .concat(
                                _(sibling.medias.modelPackshots).map(function (modelPackshot) {
                                    return !(modelPackshot.obsolete = false) && modelPackshot.pixlId;
                                }))
                            .concat(
                                _(sibling.medias.masterModelPackshots).map(function (modelPackshot) {
                                    return !(modelPackshot.obsolete = false) && modelPackshot.pixlId;
                                }))
                            .concat(
                                _(sibling.medias.modelMedia360).map(function (modelMedia360) {
                                    return !(modelMedia360.obsolete = false) && modelMedia360.pixlId;
                                }))
                            .concat(
                                _(sibling.medias.modelMediaFilms).map(function (modelMediaFilm) {
                                    return modelMediaFilm.mostRelevantVideo && !(modelMediaFilm.mostRelevantVideo.obsolete = false) && modelMediaFilm.mostRelevantVideo.pixlId;
                                }));
                    })
                    .flatten()
                    .uniq()
                    .compact()
                    .value();

                if (allPixlIds.length == 0) {
                    return;
                }

                return this.getObsoleteMedias(allPixlIds).then(function (response) {

                    var mediasRightsEndDate = response.itemsExpiration;
                    var obsoletePixlIds = response.obsoletePixlIds;
                    _(siblings)
                        .each(function (sibling) {
                            _(sibling.medias.modelPackshots)
                                .chain()
                                .each(function (modelPackshot) {
                                    modelPackshot.validateToDate = mediasRightsEndDate[modelPackshot.pixlId];
                                })
                                .filter(function (modelPackshot) {
                                    return _(obsoletePixlIds).contains(modelPackshot.pixlId);
                                })
                                .each(function (modelPackshot) {
                                    modelPackshot.obsolete = true;
                                });

                            _(sibling.medias.masterModelPackshots)
                                .chain()
                                .each(function (modelPackshot) {
                                    modelPackshot.validateToDate = mediasRightsEndDate[modelPackshot.pixlId];
                                })
                                .filter(function (modelPackshot) {
                                    return _(obsoletePixlIds).contains(modelPackshot.pixlId);
                                })
                                .each(function (modelPackshot) {
                                    modelPackshot.obsolete = true;
                                });

                            _(sibling.medias.modelMedia360)
                                .chain()
                                .each(function (modelMedia360) {
                                    modelMedia360.validateToDate = mediasRightsEndDate[modelMedia360.pixlId];
                                })
                                .filter(function (modelMedia360) {
                                    return _(obsoletePixlIds).contains(modelMedia360.pixlId);
                                })
                                .each(function (modelMedia360) {
                                    modelMedia360.obsolete = true;
                                });

                            _(sibling.medias.modelMediaFilms)
                                .chain()
                                .each(function (modelMediaFilm) {
                                    if (modelMediaFilm.mostRelevantVideo) {
                                        modelMediaFilm.mostRelevantVideo.validateToDate = mediasRightsEndDate[modelMediaFilm.mostRelevantVideo.pixlId];
                                    }
                                })
                                .filter(function (modelMediaFilm) {
                                    return modelMediaFilm.mostRelevantVideo && _(obsoletePixlIds).contains(modelMediaFilm.mostRelevantVideo.pixlId);
                                })
                                .each(function (modelMediaFilm) {
                                    modelMediaFilm.mostRelevantVideo.obsolete = true;
                                });
                        });

                    return siblings;
                }, function () {
                    return siblings;
                });
            },

            modelPackshotChanged: function(scope) {
                var packshotsByZone = _(scope.focusedSibling.medias.modelPackshots).groupBy('zone');
                scope.packshotsProduct = _(packshotsByZone['PRODUCT']).sortBy('order') || [];
                scope.packshotsContext = _(packshotsByZone['CONTEXT']).sortBy('order') || [];
                scope.packshotsSign = _(packshotsByZone['SIGN_KIT']).sortBy('order') || [];

                scope.focusedSibling.medias.modelMedia360 = _(scope.focusedSibling.medias.modelMedia360).sortBy('order') || [];
                scope.focusedSibling.medias.modelMediaFilms = _(scope.focusedSibling.medias.modelMediaFilms).sortBy('order') || [];
            }
        };
    }

})();
