(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('massActionService', massActionService);

    massActionService.$inject = ['axiosService', 'envService'];

    function massActionService(axiosService, envService) {
        var getPool = function (state) {
            return axiosService.get(
                envService.getMassActionsContext(),
                {
                    params: {state},
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                }
            );
        };

        return {
            addToPool: function (request) {
                return axiosService.post(envService.getMassActionsContext(), request, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            },
            removeFromPool: function (requestId) {
                return axiosService.delete(
                    envService.getMassActionsContext() + '/' + requestId,
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            },
            getActivePool: function () {
                return getPool('ACTIVE');
            },
            getClosedPool: function () {
                return getPool('OLD');
            },
            getTranslationCode: function (expression) {
                switch (expression) {
                    case 'CANCELED':
                        return 'massAction.list.states.cancelled';
                    case 'DONE':
                        return 'massAction.list.states.finished';
                    case 'IN_PROGRESS':
                        return 'massAction.list.states.onGoing';
                    case 'WAIT':
                        return 'massAction.list.states.waiting';
                    case 'REC':
                        return 'massAction.productList.selection.carryOver';
                    case 'PUB':
                        return 'massAction.productList.selection.publication';
                    case 'REC_PUB':
                        return 'massAction.productList.selection.carryOverAndPublication';
                }
            },
            massActionFilterComparator: function (actual, expected) {
                if (expected) {
                    return angular.equals(expected, actual);
                }
                return true;
            },
        };
    }
})();
