(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('transline', transline);

    transline.$inject = ['$rootScope', '$compile'];

    function transline ($rootScope, $compile) {
        return {
            scope: {
                ngModel: '=',
                translineBefore: '=',
                isMaster: '='
            },
            require: '?ngModel',
            link: function(scope, element, attrs) {

                var masterValueIsSet = false;
                var needTransline = false;
                var el = null;
                var before;

                attrs.$observe('translineBefore', function  (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        initBeforeValue();
                    }
                });

                element.bind('blur keyup change', function () {
                    compareBeforeAfter(scope.ngModel);
                });

                scope.$watch('ngModel', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        compareBeforeAfter(newValue);
                    }
                });



                function initBeforeValue() {
                    // eslint-disable-next-line
                    if (attrs.hasOwnProperty('translineBefore')) {
                        before = angular.copy(scope.translineBefore);
                    }
                    else {
                        before = angular.copy(scope.ngModel);
                    }
                    masterValueIsSet = true;
                }

                function compareBeforeAfter(newValue) {

                    if (!masterValueIsSet) {
                        initBeforeValue();
                    }

                    var compare1 = before == null ? '' : before;
                    var compare2 = newValue == null ? '' : newValue;

                    var newNeedTransline = (compare1 != compare2);

                    if (newNeedTransline == needTransline) {
                        return;
                    }

                    needTransline = newNeedTransline;
                        if (compare1 != compare2) {
                            var left = parseInt(element.width(), 10) - 7;
                            if (!el) {
                                if(scope.isMaster) {
                                    el = angular.element('<img uib-tooltip="{{\'product.translation_will_be_required\' | translate}}" tooltip-append-to-body="true" style="position:absolute; left:' + left + 'px; top: -5px; z-index: 3" src="common/styles/img/transline-warning.png" />');
                                }
                                else {
                                    el = angular.element('<img uib-tooltip="{{\'product.translation_will_be_required_for_locale\' | translate}}" tooltip-append-to-body="true" style="position:absolute; left:' + left + 'px; top: -5px; z-index: 3" src="common/styles/img/transline-warning.png" />');
                                }
                            }

                            element.parent().css('position', 'relative');
                            element.parent().append(el);
                            element.addClass('transline');
                        } else {
                            element.removeClass('transline');
                            el.remove();
                        }
                    $compile(el)(scope);

                }
            }
        };
    }

})();
