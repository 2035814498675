(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('countriesService', countriesService);

    countriesService.$inject = ['axiosService', 'envService'];

    function countriesService(axiosService, envService) {

        return {
            getMasterDependencies: function () {
                return axiosService
                    .get(
                        envService.getCountryContext() + '/masterDependencies',
                        {
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json',
                            },
                            cache: true,
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getCountries: function (language) {
                return axiosService
                    .get(
                        envService.getUrlCountriesListUrl() +
                            '?' +
                            Object.entries({ language: language })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json',
                            },
                            cache: true,
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getLanguageDependencies: function () {
                return axiosService
                    .get(envService.getUrlLanguageDependencies(), {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                        cache: false,
                    })
                    .then(function (response) {
                        return response.data;
                    });
            },
            getCountryData: function (locale) {
                return axiosService
                    .get(
                        envService.getCountryContext() +
                            '?' +
                            Object.entries({ locale: locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            headers: {
                                Accept: 'application/json',
                            },
                            cache: false,
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            getLocales: function (locale) {
                return this.getCountries(locale).then(function (countriesWithLocales) {
                    return _(countriesWithLocales)
                        .chain()
                        .map(function (countryWithLocales) {
                            return _(countryWithLocales.listLangages).map(function (language) {
                                return {
                                    country: countryWithLocales.code,
                                    countryLabel: countryWithLocales.label,
                                    language: language.code,
                                    languageLabel: language.label,
                                    localeLabel: countryWithLocales.label + ' / ' + language.label,
                                    locale: language.code + '_' + countryWithLocales.code
                                };
                            });

                        })
                        .flatten()
                        .value();
                });
            },
            isRightToLeftLocale: function (locale) {
                return axiosService
                    .get(
                        envService.getUrlRightToLeftLocale() +
                            '?' +
                            Object.entries({ locale: locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: true,
                            headers: {
                                Accept: 'application/json',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            },
            createCountry: function (country) {
                return axiosService.post(envService.getCountryContext(), country, {
                    withCredentials: true,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                    },
                });
            },
            saveCountry: function (country) {
                return axiosService.put(envService.getCountryContext(), country, {
                    withCredentials: true,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                    },
                });
            }
        };

    }

})();
