(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('productPartService', productPartService);

    productPartService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function productPartService(axiosService, masterdatasCache, envService) {

        function getLocalizedProductParts(locale) {
            return axiosService.get(
                envService.getProductPartsUrl(),
                {
                    params: {locale},
                    withCredentials: true,
                    cache: masterdatasCache,
                    headers: {
                        'Accept': 'application/json;charset=UTF-8'
                    }
                })
                .then(function (productParts) {
                    return Promise.resolve(productParts.data);
                });
        }

        return {

            getProductParts: function (locale) {
                return getLocalizedProductParts(locale);
            },
            getProductPart: function (id, locale) {
                return getLocalizedProductParts(locale).then(function (productParts) {
                    return _(productParts).findWhere({id: id});
                });
            }

        };
    }

})();
