(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('FluxController', FluxController);

    FluxController.$inject = ['$scope', 'fluxService'];

    function FluxController($scope, fluxService) {

        fluxService.getFlux().then(function (result) {
            $scope.fluxList = result;
            $scope.$apply();
        });


        $scope.changeFlow = function (flux, state) {
            var fluxToSave = {};
            angular.copy(flux, fluxToSave);
            fluxToSave.fluxActivated = state;

            fluxService.saveFlux(fluxToSave).then(function () {
                flux.fluxActivated = state;
                $scope.$apply();
            });
        };
    }

})();
