(function () {
    'use strict';

    angular.module('spidwebApp').controller('AgecController', AgecController);

    AgecController.$inject = ['$scope', 'toastService', 'envService', 'axiosService'];

    function AgecController($scope, toastService, envService, axiosService) {
        $scope.handleSubmit = function ($event) {
            const fileType = $event.target['agec-file-type'].value;
            const file =  $event.target['agec-file'].files[0];

            if (!fileType) {
                toastService.displayToast('file type is required', 'error');
                return;
            }
            if (!file) {
                toastService.displayToast('file is required', 'error');
                return;
            }

            const url = `${envService.getAgecUploadUrl()}?fileType=${fileType}`;
            const formData = new FormData();
            formData.append('file', file);
            axiosService.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                maxBodyLength: 5242880, // 5mb
                maxContentLength: 5242880, // 5mb
            }).then(() => {
                toastService.displayToast('file uploaded successfully', 'success');
            }).catch(error => {
                console.error('AGEC file upload failed', error);
                toastService.displayToast('file upload failed', 'error');
            });
        };
    }
})();
