(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('careSymbolService', careSymbolService);

    careSymbolService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function careSymbolService(axiosService, masterdatasCache, envService) {

        return {
            getCareSymbols: function (locale) {
                return axiosService
                    .get(
                        envService.getCareSymbolsUrl() +
                            '?' +
                            Object.entries({ locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: masterdatasCache,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (careSymbols) {
                        return careSymbols.data;
                    });
            }
        };

    }

})();
