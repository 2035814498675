(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('translationStatusModal', translationStatusModal);

    translationStatusModal.$inject = ['$scope', 'smVersion', 'worldWide', 'userSettings', '$filter'];

    function translationStatusModal($scope, smVersion, worldWide, userSettings, $filter) {

        $scope.userLocale = userSettings.getSelectedWorkingLocale();
        $scope.worldWide = worldWide;
        $scope.smVersion = smVersion;
        $scope.setTitle = function (createdAt, locale) {
            if (createdAt === null) {
                return locale;
            }

            return ( locale + ' ' + $filter('translate')('product.sm.search.translation.created_date') + ' ' +$filter('date')(createdAt, 'yyyy/MM/dd'));
        };
        $scope.dismiss = function () {
            $scope.$dismiss();
        };
    }
})();
