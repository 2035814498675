(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('lastSearch', lastSearch);

    lastSearch.$inject = ['$rootScope', 'lastSearchService'];


    function lastSearch($rootScope, lastSearchService) {
        return {
            restrict: 'A',
            link: function (scope, element) {

                element.hide();

                element.on('click', function () {
                    lastSearchService.goToLastSearch();
                });

                $rootScope.$on(lastSearchService.getLastSearchEventName(), function () {
                    element.show();
                });
            }
        };
    }
})();
