(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MassActionClosedController', MassActionClosedController);

    MassActionClosedController.$inject = ['$scope', '$filter', 'envService', 'massActionService'];

    function MassActionClosedController ($scope, $filter, envService, massActionService) {

        var self = this;

        self.massActionsContext = envService.getMassActionsContext();

        massActionService.getClosedPool().then(function (massActions) {
            self.massActions = massActions.data;
            _(self.massActions).each(function (massAction) {
                massAction.requestDateFormated = $filter('date')(massAction.requestDate, 'dd/MM/yyyy HH:mm:ss');
                massAction.closingDateFormated = $filter('date')(massAction.closingDate, 'dd/MM/yyyy HH:mm:ss');
            });
        });

        self.massActionFilterComparator = function (actual, expected) {
            return massActionService.massActionFilterComparator(actual, expected);
        };

        self.getTranslationCode = function (expression) {
            return massActionService.getTranslationCode(expression);
        };
    }

})();
