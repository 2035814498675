(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('noticeService', noticeService);

    function noticeService() {
        // eslint-disable-next-line
        var urlPattern = new RegExp('^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}', 'i');
        return {
            urlPattern: urlPattern,
            noticeIsValid: function (notice) {
                return urlPattern.test(notice);
            }
        };
    }

})();
