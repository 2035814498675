(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('emptyToNull', emptyToNull);

    emptyToNull.$inject = [];

    function emptyToNull() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function(viewValue) {
                    if (viewValue === '') {
                        return null;
                    }
                    return viewValue;
                });
            }
        };
    }
})();
