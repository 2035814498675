(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('elemReady', elemReady);

    function elemReady ($parse) {
        return {
            restrict: 'A',
            link: function($scope, elem, attrs) {
                elem.ready(function() {
                    $scope.$apply(function() {
                        var func = $parse(attrs.elemReady);
                        func($scope);
                    });
                });
            }
        };
    }

})();
