(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('warningService', warningService);

    warningService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function warningService(axiosService, masterdatasCache, envService) {
        return {
            getWarnings: function (locale) {
                return axiosService
                    .get(
                        envService.getWarningsUrl() +
                            '?' +
                            Object.entries({ locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: masterdatasCache,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (warnings) {
                        return warnings.data;
                    });
            }
        };
    }
})();
