(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('cart', cart);

    cart.$inject = ['spidModal', '$stateParams', '$analytics', 'wediaService', 'mediaService'];


    function cart (spidModal, $stateParams, $analytics, wediaService, mediaService) {
        return  {
            restrict: 'E',
            templateUrl: 'productCardNg/imagesVideos/cart/cart.html',
            scope: {
                criteria: '=',
                selectedModelCode: '@',
                selectedCodeConception: '@',
                selectedSuperModelCode: '@',
                searchMedias: '&',
                clickOnMedia: '&',
                affectAutoEnabled: '=',
                affectAuto: '&',
                onDropCartItem: '&'
            },
            link: function (scope) {

                scope.language = $stateParams.language;

                scope.searching = false;
                scope.tooManyResult = false;

                var videoAvailableLanguages = [];

                scope.searchTypeChangedFor = function () {
                    if (scope.criteria.search_type === 'CM') {
                        scope.criteria.search_value = scope.selectedModelCode; // eslint-disable-line camelcase
                    }
                    else if (scope.criteria.search_type === 'CC') {
                        scope.criteria.search_value = scope.selectedCodeConception; // eslint-disable-line camelcase
                    }
                    else if (scope.criteria.search_type === 'NAME') {
                        scope.criteria.search_value = ''; // eslint-disable-line camelcase
                    }
                    else if (scope.criteria.search_type === 'SM') {
                        scope.criteria.search_value = scope.selectedSuperModelCode; // eslint-disable-line camelcase
                    }
                };

                scope.searchOnPixL = function(timeout) {
                    if (!scope.searching) {
                        scope.errorMessage = null;
                        scope.infoMessage = null;
                        scope.searching = true;
                        scope.tooManyResult = false;
                        scope.searchMedias(timeout).then(function () {
                            scope.searching = false;
                        }, function () {
                            scope.errorMessage = 'error.pixl.unavailable';
                            scope.tooManyResult = false;
                            scope.searching = false;
                        });
                    }
                };

                scope.searchMedias = function (timeout) {
                    return mediaService.getAvailableLanguages($stateParams.smLocale.split('_')[1], $stateParams.smLocale.split('_')[0])
                        .then(function (response) {
                            videoAvailableLanguages = response;
                        })
                        .then(function () {
                            return wediaService.getCartSize(scope.criteria, timeout).then(function (size) {

                                if (size == 0) {
                                    scope.cart = emptyCart();
                                    return;
                                }

                                if (size > 200) {
                                    scope.tooManyResult = size;
                                }
                                else {
                                    scope.getCartContent(timeout);
                                }
                            },
                            // Return error message when timeout is reached
                            function () {
                                scope.errorMessage = 'error.pixl.unavailable';
                                scope.tooManyResult = false;
                                scope.searching = false;
                            });
                        });
                };

                scope.$watch('selectedModelCode', function (newValue) {
                    if (newValue) {
                        scope.infoMessage = 'global.pixl.cart.search';
                        scope.tooManyResult = false;
                        scope.searching = false;
                        scope.cart = null;
                        //scope.searchOnPixL(5000);
                    }
                });

                scope.cancelSearch = function () {
                    scope.tooManyResult=false;
                };

                function emptyCart() {
                    return {
                        packshots: [],
                        images360: [],
                        videos: []
                    };
                }

                scope.getCartContent = function(timeout) {
                    scope.tooManyResult=false;
                    scope.cart = emptyCart();

                    return wediaService.getCartContent(scope.criteria, timeout).then(function (cartMedias) {

                        _(cartMedias.videos).each(function (film) {

                            var mostRelevantVideo = _(film.languages)
                                .chain()
                                .each(function (video) {
                                    var foundLocaleCodeVideoMapping = _(videoAvailableLanguages).find(function (localeCodeVideoMapping) {
                                        return video.code.toUpperCase() === localeCodeVideoMapping.codeLangue;
                                    });
                                    video.priority = angular.isDefined(foundLocaleCodeVideoMapping) ? foundLocaleCodeVideoMapping.priority : null;
                                })
                                .filter(function (video) {
                                    return video.priority !== null;
                                })
                                .sortBy('priority')
                                .first()
                                .value();

                            film.videos = _(film.languages)
                                .chain()
                                .each(function (video) {
                                    video.name = film.name;
                                    video.pixlId = film.pixlId;
                                    video.language = video.code;
                                    video.brightcoveId = 'p'+film.pixlId;
                                    video.validateFromDate = film.validateFromDate;
                                    video.validateToDate = film.validateToDate;
                                    video.type = film.subType;
                                })
                                .value();

                            film.mostRelevantVideo = angular.isDefined(mostRelevantVideo) ? mostRelevantVideo : null;
                            if (film.mostRelevantVideo) {
                                film.mostRelevantVideo.name = film.name;
                                film.mostRelevantVideo.pixlId = film.pixlId;
                                film.mostRelevantVideo.brightcoveId = 'p'+film.pixlId;
                                film.mostRelevantVideo.validateFromDate = film.validateFromDate;
                                film.mostRelevantVideo.validateToDate = film.validateToDate;
                            }
                        });
                        scope.cart = cartMedias;
                    }).catch(
                    // Return error message when timeout is reached
                    function () {
                        scope.errorMessage = 'error.pixl.unavailable';
                        scope.tooManyResult = false;
                        scope.searching = false;
                    })
                        .finally(function () {
                            scope.$apply();
                        });
                };

                scope.getMediaURL = function (media, size) {
                    return wediaService.getMediaURL(media, size);
                };

                var autoAffectModal;

                scope.openAutoAffectPopup = function () {

                    if (!autoAffectModal) {

                        autoAffectModal = spidModal
                            .open({
                                templateUrl: 'productCardNg/imagesVideos/cart/autoAffect/autoAffectModal.html',
                                controller: 'AffectationAutoController',
                                size: 'lg',
                                resolve: {
                                    popupMode: function () {
                                        return 'auto_assign';
                                    },
                                    mediasCart: function () {
                                        return {
                                            productMedias: _(scope.cart.packshots).filter(function (packshot) {
                                                return packshot.subType !== 'SCENE';
                                            }),
                                            productContext: _(scope.cart.packshots).filter(function (packshot) {
                                                return packshot.subType === 'SCENE';
                                            }),
                                            medias360: scope.cart.images360,
                                            videos: scope.cart.videos
                                        };
                                    }
                                }
                            });
                        autoAffectModal.result
                            .then(function (mediaTypes) {
                                scope.affectAuto({mediaTypes: mediaTypes, cart: scope.cart});
                                $analytics.eventTrack('Affectation automatique des médias', {category: 'Fiche SM', label: 'Affectation de ' + mediaTypes.join(',')});
                            });
                        autoAffectModal.closed
                            .then(function () {
                                autoAffectModal = null;
                            });
                    }
                };

            }
        };
    }

})();
