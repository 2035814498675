(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ModalTextController', ModalTextController);

    ModalTextController.$inject = ['$scope', '$uibModalInstance', 'title', 'textHtml'];

    function ModalTextController ($scope, $uibModalInstance, title, textHtml) {

        $scope.textHtml = textHtml;
        $scope.title = title;

        $scope.close = function () {
            $uibModalInstance.close();
        };
        $scope.dismiss = function () {
            $uibModalInstance.dismiss();
        };
    }

})();
