(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('packshotsProduct', packshotsProduct);

    function packshotsProduct () {
        return  {
            restrict: 'E',
            templateUrl: 'productCardNg/imagesVideos/packshotsProduct/packshotsProduct.html'
        };
    }

})();
