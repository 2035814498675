(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('brightcoveScript', brightcoveScript);

    brightcoveScript.$inject = ['brightcoveService'];

    function brightcoveScript (brightcoveService) {
        return {
            restrict: 'E',
            template: '<script language="JavaScript" type="text/javascript" src="'+brightcoveService.getUrl().replace('{account_id}', brightcoveService.getAccountId()).replace('{Player_id}', brightcoveService.getPlayerId())+'"></script>'
        };
    }

})();
