(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('lastComparator', lastComparator);

    lastComparator.$inject = ['$rootScope', '$state', 'dialogService'];

    function lastComparator($rootScope, $state, dialogService) {

        return {
            restrict: 'A',
            link: function (scope, element) {

                element.hide();
                var noComparatorModal;

                var lastSavedComparator = {
                    state: null,
                    params: null
                };

                element.on('click', function () {
                    if (lastSavedComparator.params) {
                        $state.go('smComparator', lastSavedComparator.params);
                    }
                    else {
                        openThePopup();
                    }
                });


                function openThePopup() {
                    if (!noComparatorModal) {
                        noComparatorModal = dialogService.showMessageBox('global.smComparator', 'smComparator.noSelection');
                        noComparatorModal.closed.then(function () {
                            noComparatorModal = null;
                        });
                    }
                }

                $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
                    if (toState.name.indexOf('smComparator') > -1) {
                        lastSavedComparator.state = toState;
                        lastSavedComparator.params = toParams;
                        element.show();
                    }
                });
            }
        };

    }

})();
