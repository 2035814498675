(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('productListSelectionService', productListSelectionService);

    productListSelectionService.$inject = ['webStorage'];

    function productListSelectionService (webStorage) {

        return {
            getSelection: function () {
                return webStorage.memory.get('productListSelection');
            },
            setSelection: function (key, massAction, productSelection) {

                var productIdSelection = _.chain(productSelection)
                                          .where({selected: true})
                                          .pluck('alfrescoId')
                                          .value();

                var productListSelection = {
                    key: key,
                    list: productIdSelection,
                    massAction: massAction
                };

                webStorage.memory.set('productListSelection', productListSelection);

            },
            hasSelection: function (key) {
                return webStorage.memory.get('productListSelection') && webStorage.memory.get('productListSelection').key === key;
            },
            cleanSelection: function () {
                webStorage.memory.clear();
            }
        };

    }

})();
