(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('fragranceService', fragranceService);

    fragranceService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function fragranceService(axiosService, masterdatasCache, envService) {

        return {
            getFragrances: function (locale) {
                return axiosService
                    .get(
                        envService.getOfferDatasContext() +
                            '/fragrances?' +
                            Object.entries({ locale })
                                .map(([key, val]) => `${key}=${val}`)
                                .join('&'),
                        {
                            withCredentials: true,
                            cache: masterdatasCache,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (response) {
                        return response.data;
                    });
            }
        };

    }

})();
