(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('spidi18n', spidi18n);

    spidi18n.$inject = ['translationService'];

    function spidi18n (translationService) {

        var translationsOnErrorFR = {
            'error': { 'exception': { 'title': 'Une erreur est survenue'} },
            'warning': { 'technicalError': 'Une erreur technique s\'est produite. Si l\'erreur persiste, merci de contacter le support SPID via le formulaire d\'assistance.' },
            'global': { 'close': 'Fermer' }
        };
        var translationsOnErrorEN = {
            'error': { 'exception': { 'title': 'An error occurred'} },
            'warning': { 'technicalError': 'A technical error occurred. If the problem persist, please contact SPID support via the support form.' },
            'global': { 'close': 'Close' }
        };

        return function (options) {
            return translationService.get(options).then(function (response) {
                return response.data;
            }, function () {
                return (options.key === 'fr_FR' ? translationsOnErrorFR : translationsOnErrorEN);
            });
        };
    }

})();
