(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('careSymbols', careSymbols);

    careSymbols.$inject = ['envService'];

    function careSymbols (envService) {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/infoPlus/careSymbols/careSymbols.html',
            scope: {
                careSymbols: '=',
                careSymbolsRepository: '=',
                disabled: '@'
            },
            link: function (scope) {

                scope.careSymbolsRepository = scope.careSymbolsRepository.map(function (careSymbol) {
                    careSymbol.absoluteUrl = careSymbol.url;
                    return careSymbol;
                });

                scope.readOnly = (scope.disabled === 'true');

                scope.careSymbols = scope.careSymbols || [];

                scope.baseUrl = envService.getBaseUrl();

                scope.$watch('disabled', function(){
                    scope.readOnly = (scope.disabled === 'true');
                });

            }
        };
    }

})();
