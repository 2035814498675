(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('sports', sportsDirective);

    sportsDirective.$inject = ['$uibModal', '$filter', '$state', '$stateParams', 'userSettings'];

    function sportsDirective($uibModal, $filter, $state, $stateParams, userSettings) {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/productIdentity/sports/sports.html',
            scope: {
                modelSportsGroups: '=ngModel',
                sportsGroupsRepository: '=',
                sportsRepository: '=',
                practicesRepository: '=',
                sportsOldRepository: '=',
                practicesOldRepository: '=',
                smSeasonId: '=',
                hasRole: '=',
                allModelInFuture: '=',
                readOnly: '='
            },
            link: function (scope) {

                scope.convientA = userSettings.hasAuthority('ROLE_CUD');

                var editSportsModal;

                scope.openEditSports = function () {
                    if (!editSportsModal) {
                        editSportsModal = $uibModal.open({
                            templateUrl: 'productCardNg/productIdentity/sports/editSports/editSports.html',
                            controller: 'editSportsController',
                            size: 'xl',
                            resolve: {
                                modelSportsGroups: function () {
                                    return angular.copy(scope.modelSportsGroups);
                                },
                                sportsRepository: function () {
                                    return scope.sportsRepository;
                                },
                                convientA: function () {
                                    return !scope.hasRole && scope.convientA;
                                }
                            }
                        });

                        editSportsModal.result.then(function (modelSportsGroupsUpdated) {
                            scope.modelSportsGroups = modelSportsGroupsUpdated;
                        }).finally(function () {
                            editSportsModal = null;
                        });
                    }
                };

            }

        };
    }

})();
