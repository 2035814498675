(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('UniversesController', universesController);

    universesController.$inject = ['$scope', '$uibModal', 'universeService'];

    function universesController ($scope, $uibModal, universeService) {

        $scope.criteria = {
            ldapKey: null,
            brand: null,
            idUniverse: null,
            enabled: null
        };

        $scope.search = function () {

            universeService.search($scope.criteria).then(function (data) {
                $scope.universes = data.data;
            }).catch(function () {
                $scope.universes = [];
            })
            .finally(function () {
                $scope.$apply();
            });
        };

        $scope.deleteUniverse = function (universe) {
            universeService.delete(universe).then(function () {
                $scope.search();
            })
            .finally(function () {
                $scope.$apply();
            });
        };

        var universeModal;

        $scope.openUniverse = function (originalUniverse, editMode) {

            if (!universeModal) {
                $scope.universe = angular.copy(originalUniverse);
                $scope.editMode = editMode;

                universeModal = $uibModal
                    .open({
                        templateUrl: 'admin/universes/popup/universePopup.html',
                        size: 'lg',
                        scope: $scope
                    });
                universeModal.result
                    .then(function (modifiedUniverse) {
                        if (editMode) {
                            universeService.edit(modifiedUniverse).then(function (response) {
                                $scope.universe = [response.data];
                                $scope.universes = $scope.universes.map(function (universe) {
                                   if (universe.idUniverse === response.data.idUniverse) {
                                       return response.data;
                                   }
                                   return universe;
                                });
                            })
                                .finally(function () {
                                    $scope.$apply();
                                });
                        }
                        else {
                            universeService.create(modifiedUniverse).then(function (response) {
                                $scope.universe = [response.data];
                                $scope.universes.push(response.data);
                            })
                            .finally(function () {
                                $scope.$apply();
                            });
                        }
                    }, function () {
                    });
                universeModal.closed.then(function () {
                    universeModal = null;
                });
            }
        };
    }
})();
