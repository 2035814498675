(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('InfosModelsController', InfosModelsController);


    InfosModelsController.$inject = ['$scope', '$stateParams', '$timeout', '$window', 'spidModal', 'envService', 'materialService', 'modelService', 'userSettings', 'wediaService', '$rootScope'];

    function InfosModelsController($scope, $stateParams, $timeout, $window, spidModal, envService, materialService, modelService, userSettings, wediaService, $rootScope) {

        var NO_PICTURE_PATH = 'common/styles/img/no-picture.jpg';
        $scope.flavorIds = [];
        $scope.fragranceIds = [];
        _($scope.model.models).forEach(function (mdl) {
            $scope.flavorIds.push(mdl.flavors.flavor1Id);
            $scope.flavorIds.push(mdl.flavors.flavor2Id);
            $scope.flavorIds.push(mdl.flavors.flavor3Id);
            $scope.fragranceIds.push(mdl.fragrance);
        });

        $scope.flavors = computeFlavorFathers(filterInactive($scope.flavorsRepository, $scope.flavorIds));
        $scope.fragrances = filterInactive($scope.fragrancesRepository, $scope.fragranceIds);
        $scope.referentialNotes = undefined;


        $scope.floatTheadOptions = {
            top: 96,
            position: 'fixed',
            autoReflow: true,
            responsiveContainer: function($table){
                return $table.closest('.table-responsive');
            }
        };

        $scope.scroll = function (score) {
            var infosModelsContainer = angular.element('#infosModelsContainer');
            var start = infosModelsContainer.scrollLeft();
            infosModelsContainer.scrollLeftAnimated(start + score);
        };

        $scope.calculHeight = function () {
            var maxHeight = 150;
            _($scope.siblings).forEach(function (sibling) {
                if (sibling.available) {
                    var lelement = angular.element('#nutritionTable-' + sibling.code);
                    var currentHeight = lelement[0].scrollHeight;
                    maxHeight = (currentHeight > maxHeight ? currentHeight : maxHeight );
                }
            });
            $scope.nutritionTableHeight = (maxHeight + 5) + 'px';
        };

        $scope.resetHeight = function () {
            $scope.nutritionTableHeight = 40 + 'px';
        };

        $scope.resetHeight();

        $scope.scrollWesh = function () {
            $timeout(function () {
                var someElement = angular.element('#sibling-' + ($scope.focusedSibling != null ? $scope.focusedSibling.code : $stateParams.modelCode));
                var containerElement = angular.element('#infosModelsContainer');
                if (someElement.length && containerElement.length) {

                    var scrollIndex = parseInt(someElement.attr('scrollIndex'), 10);
                    var newScrollPosition = someElement.width() * scrollIndex;

                    var viewPortWidth = containerElement.width();

                    var containerPositionX = containerElement[0].getBoundingClientRect().left;
                    var siblingPositionX = someElement[0].getBoundingClientRect().left;
                    var siblingPositionWithWidth = siblingPositionX + someElement.width();

                    if (siblingPositionX < containerPositionX || siblingPositionX > (containerPositionX + viewPortWidth)) {
                        containerElement.scrollLeftAnimated(newScrollPosition);
                    }
                    else if (siblingPositionWithWidth < containerPositionX || siblingPositionWithWidth > (containerPositionX + viewPortWidth)) {
                        containerElement.scrollLeftAnimated(newScrollPosition);
                    }
                }
            }, 200);
        };

        $scope.focusSiblingAndResize = function (sibling) {
            $scope.calculHeight();
            $scope.focusSibling(sibling);
        };

        $scope.$watch('focusedSibling.alfrescoId', $scope.scrollWesh);

        function filterInactive(offerdatas, selectedValueIds) {

            var inactiveValues =
                _(selectedValueIds)
                    .chain()
                    .filter(function (selectedValueId) {
                        return selectedValueId != null;
                    })
                    .map(function (selectedValueId) {
                        return _(offerdatas).findWhere({id: selectedValueId});
                    })
                    .filter(function (selectedValue) {
                        // eslint-disable-next-line
                        return selectedValue.hasOwnProperty('active') ? !selectedValue.active : !selectedValue.isActive;
                    })
                    .value();

            var activeOfferdata = _(offerdatas).filter(function (offerdata) {
                // eslint-disable-next-line
                return offerdata.hasOwnProperty('active') ? offerdata.active : offerdata.isActive;
            });

            return (inactiveValues != null && inactiveValues.length > 0) ? [].concat(inactiveValues).concat(activeOfferdata) : activeOfferdata;
        }

        /**
         * Les gouts ont une hierarchie père/fils
         * certain père n'ont pas de fils, il faut les afficher comme les fils
         * @param flavors
         */
        function computeFlavorFathers (flavors) {
            var flavorParentsMap =
                _(flavors)
                    .chain()
                    .filter(function (flavor) {
                        return flavor.parent === null;
                    })
                    .each(function (fatherFlavor) {
                        fatherFlavor.hasNoChildren = true;
                    })
                    .indexBy('id')
                    .value();

            return _(flavors).each(function (flavor) {
                if (flavor.parent != null) {
                    var parent = flavorParentsMap[flavor.parent];
                    if (parent) {
                        parent.hasNoChildren = false;
                        flavor.parentName = parent.label;
                    }
                }
            });
        }
        $scope.setWebLabelMustBeUpdatedByTranlation = function (sibling) {
            if (!sibling.master) {
                sibling.webLabelMustBeUpdatedByTranslation = !sibling.webLabel.value;
            }
        };

        var colorsPopup;
        $scope.displayColorsFlavorsFragrancesPopup = function (sibling) {

            if (!colorsPopup) {
                $scope.focusSibling(sibling);
                colorsPopup = spidModal.open({
                    templateUrl: 'productCardNg/infosModels/colorsFragranceFlavors/colorsFragranceFlavorsPopup.html',
                    controller: 'ColorsFragranceFlavorsPopup',
                    size: 'md',
                    resolve: {
                        model: function () {
                            return angular.copy(sibling);
                        },
                        definitionCategory: function () {
                            return $scope.productCardDto.definitionCategory;
                        },
                        siblings: function () {
                            return $scope.siblings;
                        },
                        colorsRepository: function () {
                            return $scope.colorsRepository;
                        },
                        fragrances: function () {
                            return $scope.fragrances;
                        },
                        flavors: function () {
                            return $scope.flavors;
                        },
                        colorIsDisabled: function () {
                            return !$scope.hasRoleCUD || (!userSettings.hasRole('ROLE_ADMIN') && sibling.metadatas.paceColors);
                        },
                        modelBrand: function() {
                            return $scope.model;
                        }
                    }
                });
                colorsPopup.result.then(function (updatedModel) {
                    $scope.productCardDto.definitionCategory = updatedModel.definitionCategory;
                    sibling.definitionCategory = updatedModel.definitionCategory;
                    sibling.colors.color1Id = updatedModel.colors.color1Id;
                    sibling.colors.color2Id = updatedModel.colors.color2Id;
                    sibling.colors.color3Id = updatedModel.colors.color3Id;
                    sibling.flavors.flavor1Id = updatedModel.flavors.flavor1Id;
                    sibling.flavors.flavor2Id = updatedModel.flavors.flavor2Id;
                    sibling.flavors.flavor3Id = updatedModel.flavors.flavor3Id;
                    sibling.fragrance = updatedModel.fragrance;
                    sibling.flavorChanged=(updatedModel.flavors.flavor1Id || updatedModel.flavors.flavor2Id || updatedModel.flavors.flavor3Id);
                    sibling.fragranceChanged= updatedModel.fragrance!=null;

                    $rootScope.somethingChanged=true;
                    $scope.modelsFields.modified=true;


                    //_(sibling).extend(updatedModel);
                }, function () {
                    // do nothing
                });
                colorsPopup.closed.then(function () {
                    colorsPopup = null;
                });
            }
        };

        var compositionsModal;

        $scope.openCompositionsPopup = function (sibling) {
            if (!compositionsModal) {
                $scope.focusSibling(sibling);
                compositionsModal = spidModal.open({
                    templateUrl: 'productCardNg/infosModels/composition/modelCompositionPopup.html',
                    scope: $scope,
                    size: 'lg',
                    controller: 'ModelCompositionPopup',
                    resolve: {
                        model: function () {
                            return angular.copy(sibling);
                        },
                        oldCompositionMaxSize: function () {
                            return $scope.oldCompositionMaxSize;
                        },
                        materialsRepository: function () {
                            return $scope.materialsRepository;
                        },
                        productPartsRepository: function () {
                            return $scope.productPartsRepository;
                        },
                        compositionIsDisabled: function () {
                            return !$scope.hasRoleCUD || (!userSettings.hasRole('ROLE_ADMIN') && sibling.metadatas.paceComposition);
                        }
                    }
                });
                compositionsModal.result.then(function (updatedModel) {
                    sibling.composition = updatedModel.composition;
                });

                compositionsModal.closed.then(function () {
                    compositionsModal = null;
                });
            }
        };


        var weightModal;

        $scope.displayWeightDlg = function (sibling) {
            if (!weightModal) {
                $scope.focusSibling(sibling);
                weightModal = spidModal.open({
                    templateUrl: 'productCardNg/infosModels/weight/weight.html',
                    controller: 'WeightController',
                    size: 'lg',
                    resolve: {
                        model: function () {
                            return angular.copy(sibling);
                        },
                        productNature: function () {
                            return angular.copy($scope.productCardDto.productNature);
                        },
                        productCardDto: function () {
                            return angular.copy($scope.productCardDto);
                        },
                        articlesGridSize: function () {
                            return modelService.getArticles(sibling.code).then(function (articles) {
                                if (articles.length < 1 ) {
                                    return _(sibling.items).forEach(function(article) {
                                        return _({
                                            articleId: article.code,
                                            articleSizeLabel: article.size,
                                            weightInput: null,
                                            capacity: {
                                                value: null,
                                                unit: null
                                            },
                                            gridSize: {}
                                        }).extend(getArticleGridSize(article.articleId));
                                    });
                                } else {
                                    return articles.map(function (article) {
                                        return _({
                                            articleId: article.articleId,
                                            articleSizeLabel: article.size,
                                            weight: null,
                                            capacity: {
                                                value: null,
                                                unit: null
                                            },
                                            gridSize: {}
                                        }).extend(getArticleGridSize(article.articleId));
                                    });
                                }
                                function getArticleGridSize(articleId) {
                                    return angular.copy(_(sibling.articles).findWhere({articleId: articleId}));
                                }

                            }, function (errorResponse) {
                                $timeout(function () {
                                    weightModal.dismiss(errorResponse);
                                });
                            });
                        }
                    }
                });
                weightModal.result.then(function (modelUpdated) {
                    _(sibling.articles).each(function (article) {
                        var updatedArticle = _(modelUpdated.articles).findWhere({code: article.code});
                        if(typeof updatedArticle !== 'undefined') {
                            article.articleSizeLabel = updatedArticle.articleSizeLabel;
                        }
                    });

                    sibling.items = modelUpdated.items;
                    sibling.capacityUnit = modelUpdated.capacityUnit;
                    sibling.capacityValue = modelUpdated.capacityValue;
                    sibling.packaging = modelUpdated.packaging;
                    sibling.weightContext = modelUpdated.weightContext;

                });
                weightModal.closed.then(function () {
                    weightModal = null;
                });

            }
        };

        $scope.flavorIsActive = function(flavorId) {
            var flavor = _($scope.flavors).findWhere({id: parseInt(flavorId, 10)});
            return angular.isDefined(flavor) && !flavor.isActive ? 'obsolete' : null;
        };

        $scope.fragranceIsActive = function(fragranceId) {
            var fragrance = _($scope.fragrances).findWhere({id: parseInt(fragranceId, 10)});
            return angular.isDefined(fragrance) && !fragrance.isActive ? 'obsolete' : null;
        };

        $scope.colorsAreValid = function(sibling, colorsRepository) {
            var inactiveValues = [];

            _(colorsRepository)
                .chain()
                .filter(function (color) {
                    return [sibling.businessColor1Id, sibling.businessColor2Id, sibling.businessColor3Id].indexOf(color.businessColor1Id) > -1;
                })
                .each(function (color) {
                    if (!color.isActive) {
                        inactiveValues.push(color.label);
                    }
                });

            var colorOrderError = false;

            if (sibling.colors.color3Id && (!sibling.colors.color2Id || !sibling.colors.color1Id)) {
                colorOrderError = true;
            }
            if (sibling.colors.color2Id && !sibling.colors.color1Id) {
                colorOrderError = true;
            }

            return _(inactiveValues).isEmpty() && (!colorOrderError);
        };

        $scope.compositionsAreValid = function(compositions, productPartsRepository, materialsRepository) {
            var compositionIsValid = true;
            _(compositions).forEach(function (productPart) {
                if (productPart.id === null) {
                    compositionIsValid = false;
                } else {
                    _(productPart.rawMaterials).forEach(function (material) {
                        if (material.id === null) {
                            compositionIsValid = false;
                        }
                    });
                }
            });

            var hasCorrectValues = true;
            _(compositions).forEach(function (productPart) {
                _(productPart.rawMaterials).forEach(function (material) {
                    if(!materialService.isCorrectQuantityValue(material.quantity)) {
                        hasCorrectValues = false;
                    }
                });
            });


            if (!compositionIsValid || !hasCorrectValues) {
                return false;
            }


            var hasActivePartsOfProduct = true;
            var hasActiveMaterials = true;
            _(compositions).forEach(function (productPart) {
                var partOfProduct = _(productPartsRepository).findWhere({id: productPart.id});
                hasActivePartsOfProduct = hasActivePartsOfProduct && partOfProduct.isActive;
                _(productPart.rawMaterials).forEach(function (partMaterial){
                    var material = _(materialsRepository).findWhere({id: partMaterial.id});
                    hasActiveMaterials = hasActiveMaterials && (material && material.isActive);
                });
            });

            if (!(hasActivePartsOfProduct && hasActiveMaterials)) {
                return false;
            }


            var productPartsNotFilledTo100Percents = [];

            _(compositions).forEach(function (productPart) {

                var percents = 0;

                _(productPart.rawMaterials).forEach(function (material) {
                    percents += (material.quantity * 100);
                });

                if (percents !== (100 * 100)) {
                    productPartsNotFilledTo100Percents.push(productPart);
                }

            });

            return _(productPartsNotFilledTo100Percents).isEmpty();
        };

        $scope.filterSelectedFlavors = function(flavorAId, flavorBId) {
            return function(flavor) {
                return flavor != null && flavor.id !== flavorAId && flavor.id !== flavorBId;
            };
        };


        $scope.filterParentWithChildren = function () {
            return function (flavor) {
                return flavor.parent != null || flavor.hasNoChildren;
            };
        };

        $scope.openSphereLink = function(sibling) {
            let locale = $stateParams.initLocale || $stateParams.smLocale;
            modelService.getSphereContentUrl(sibling.code, locale).then(function (contentUrl) {
                $window.open(contentUrl.contentUrl);
            });
        };

        let signEasyModal;

        $scope.openSignEasyModal = function(sibling) {
            if (!signEasyModal) {

                signEasyModal = spidModal.open({
                    templateUrl: 'productCardNg/infosModels/signEasy/signEasy.html',
                    controller: 'SignEasyController',
                    size: 'md',
                    resolve: {
                        model: function () {
                            var model = angular.copy(sibling);
                            model.versionDate = $stateParams.versionDate;
                            return model;
                        },
                        smLocale: function () {
                            return $stateParams.smLocale;
                        },
                        formats: function () {
                            var locale = $stateParams.initLocale || $stateParams.smLocale;

                            return modelService.getAvailableSignKit(sibling.code, locale).then(function (formats) {
                                return formats;
                            }, function (errorResponse) {
                                $timeout(function () {
                                    signEasyModal.dismiss(errorResponse);
                                });
                            });
                        }
                    }
                });
                signEasyModal.result.then(function () {

                });
                signEasyModal.closed.then(function () {
                    signEasyModal = null;
                });
            }
        };

        /**
         * Retrieves an environmentalNoteUrl for a given sibling
         * @param sibling
         *          the sibling of the environmentalNote to retrieve
         * @returns the url of the environmental note if it exists, else NO_PICTURE_PATH content
         */
        $scope.getEnvironmentalNoteUrl = function(sibling) {
            var envNotes = {
                'A': '70506',
                'B': '70505',
                'C': '70504',
                'D': '70503',
                'E': '70502'
            };

            if (!sibling || !envNotes[sibling.environmentalNote]) {
                return NO_PICTURE_PATH;
            }

            return wediaService.getMediaURL({
                type: 'COMMUNICATION',
                pixlId: envNotes[sibling.environmentalNote],
                name: 'environmental_note'
            }, 100);
        };

        var productSellingDateModal = null;

        $scope.openProductSellingDateModal = function (sibling) {
            if (!productSellingDateModal) {
                $scope.focusSibling(sibling);
                productSellingDateModal = spidModal.open({
                    templateUrl: 'productCardNg/infosModels/productSellingDate/productSellingDate.html',
                    controller: 'ProductSellingDateController',
                    size: 'md',
                    resolve: {
                        worldStartDate: function () {
                            return sibling.worldStartDate;
                        },
                        worldEndDate: function () {
                            return sibling.worldEndDate;
                        }
                    }
                });
                productSellingDateModal.result.then(function (calendarsOptions) {
                    sibling.worldStartDate = formatDate(calendarsOptions.worldStartDate.date);
                    sibling.worldEndDate = formatDate(calendarsOptions.worldEndDate.date);

                    $rootScope.somethingChanged = true;
                });
                productSellingDateModal.closed.then(function () {
                    productSellingDateModal = null;
                });
            }
        };

        function formatDate(date) {
            // Before: yyyy-MM-DDTHH:mm:ss.sssZ
            // After:  yyyy-MM-DDTHH:mm:ss
            return date.toISOString().substring(0, 19);
        }
    }

})();
