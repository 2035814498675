(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('brightcove', brightcoveDirective);

    brightcoveDirective.$inject = ['$sce', 'brightcoveService'];

    function brightcoveDirective ($sce, brightcoveService) {
        return {
            restrict: 'E',
            replace: true,
            transclude: false,
            scope: {
                id: '@'
            },
            templateUrl: 'productCardNg/imagesVideos/brightcovePlayer/brightcove-player.html',
            controller: ['$scope', function ($scope) {


                $scope.url = $sce.trustAsResourceUrl(
                    '//players.brightcove.net/{account_id}/{Player_id}_default/index.html?videoId=ref:{id}'
                    .replace('{account_id}', brightcoveService.getAccountId())
                        .replace('{Player_id}', brightcoveService.getPlayerId())
                        .replace('{id}', $scope.id));
            }]
        };
    }

})();
