/**
 * Created by thomas on 20/12/16.
 */
(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('translationService', translationService);

    translationService.$inject = ['axiosService', 'envService'];

    function translationService(axiosService, envService) {

        var self = this;

        return {
            initLocalesMapping: function () {
                this.getLocalesMappings().then(function (response) {
                    self.localesInternationalMappings = response.data;
                });
            },
            get: function (options) {
                return axiosService.get(
                    envService.getTranslationUrl(),
                    {
                        params: { locale: options.key },
                        data: {},
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            },
            getLocalesMappings: function () {
                return axiosService.get(
                    envService.getTranslationUrl() + '/localesMapping',
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            },
            getLocalesMapping: function (localeInternational) {
                if (localeInternational) {
                    return _(self.localesInternationalMappings).find(function (localesInternationalMapping) {
                        return localesInternationalMapping.localeInternational == localeInternational;
                    }).localeReference;
                } else {
                    return self.localesInternationalMappings;
                }
            },
            getMainSource: function (target, modelCode, startWeek, filter) {

                return axiosService.get(
                    envService.getTranslationUrl() +
                        '/mainSourceTranslation',
                    {
                        params: {
                            target: target,
                            modelCode: modelCode,
                            startWeek: startWeek,
                            filter: filter,
                        },
                        data: {},
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );

            },
            search: function (criteria) {
                return axiosService.get(envService.getTranslationV2Url(),
                {
                    params: criteria,
                    data: {},
                    withCredentials: true,
                    headers: {
                        Accept: 'application/json;charset=UTF-8',
                    },
                }
            );

            },
            create: function (translation) {
                return axiosService.post(envService.getTranslationV2Url(), translation, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            },
            edit: function (translation) {
                return axiosService.put(envService.getTranslationV2Url(), translation, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            },
            delete: function (translation) {
                return axiosService.delete(envService.getTranslationV2Url(), {
                    withCredentials: true,
                    data: translation,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            },
            clearCache: function () {
                return axiosService.get(
                    envService.getTranslationV2Url() + '/refresh',
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            }
        };
    }

})();
