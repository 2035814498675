(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ColorModalController', ColorModalController)
        .filter('colorFilter', colorFilter);

    ColorModalController.$inject = ['$scope', '$filter', 'colorsRepository', 'amiColors'];

    function ColorModalController($scope, $filter, colorsRepository, amiColors) {

        $scope.amiColors = amiColors;

        $scope.businessColors = [];
        $scope.businessColors[0] = $filter('orderBy')(colorsRepository, 'businessColorId').splice(0, Math.ceil(colorsRepository.length / 2));
        $scope.businessColors[1] = $filter('orderBy')(colorsRepository, 'businessColorId').splice(Math.ceil(colorsRepository.length / 2), Math.abs(colorsRepository.length / 2));
    }

    function colorFilter() {
        return function (colors, searchValue) {

            if (!searchValue) {
                return colors;
            }

            return _(colors).filter(function (color) {
                return color.label.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 ||
                    color.hexaCode.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1;
            });

        };
    }

})();
