(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('structurationService', structurationService);

    structurationService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function structurationService(axiosService, masterdatasCache, envService) {

        return {

            get: function (locale) {
                return axiosService.get(
                    envService.getOfferDatasContext() + '/structurations',
                    {
                        params: { locale },
                        withCredentials: true,
                        cache: masterdatasCache,
                        headers: {
                            'Accept': 'application/json'
                        }
                    })
                .then(function (structurations) {
                    return Promise.resolve(structurations.data);
                });
            },
            getModelStructuration: function (structurationsSourceTree, structurationValueIds) {
                var modelStructurations = [];
                angular.copy(structurationsSourceTree, modelStructurations);

                _(modelStructurations)
                    .chain()
                    .map(function (structuration) {
                        return structuration.structurationTypes;
                    })
                    .flatten()
                    .each(function (structurationType) {
                        structurationType.structurationValues = _(structurationType.structurationValues)
                            .chain()
                            .filter(function (structurationValue) {
                                return _(structurationValueIds).contains(structurationValue.structurationValueId);
                            })
                            .sortBy('order')
                            .value();
                    });

                _(modelStructurations)
                    .chain()
                    .each(function (structuration) {
                        structuration.structurationTypes = _(structuration.structurationTypes).filter(function (structurationType) {
                            structurationType.isNew = false;
                            structurationType.isForSignKit = false;
                            return structurationType.structurationValues.length > 0;
                        });
                    })
                    .value();

                return modelStructurations;
            },

            /**
             * @function Allow to get a structurationType thanks to a structurationValueId
             * @param structurationValueId
             *          the id of the structurationValue
             * @param structurations
             * @return structurationType if it exists, null if not
             */
            getStructurationTypeByValueId: function (structurationValueId, structurations) {
                var res = null;
                _(structurations)
                    .each(function (structuration) {
                        _(structuration.structurationTypes)
                            .each(function (structurationType) {
                                if (typeof (_(structurationType.structurationValues).findWhere({structurationValueId: structurationValueId}))
                                    !== 'undefined') {
                                    res = structurationType;
                                    return res;
                                }
                            });
                    });
                return res;
            },

            /**
             * @function Allow to get a structurationValue thanks to its id
             * @param id
             *          the id of the structurationValue
             * @param structurations
             * @return structurationValue if it exists, null if not
             */
            getStructurationValueById: function (id, structurations) {
                var res = null;
                _(structurations)
                    .each(function (structuration) {
                        _(structuration.structurationTypes)
                            .each(function (structurationType) {
                                var structurationValue = _(structurationType.structurationValues).findWhere({structurationValueId: id});
                                if (typeof structurationValue !== 'undefined') {
                                    res = structurationValue;
                                    return res;
                                }
                            });
                    });
                return res;
            }


        };

    }

})();
