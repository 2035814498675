(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ProductListSupportController', ProductListSupportController);

    ProductListSupportController.$inject = ['$scope', '$stateParams', '$state', '$window', 'productList', 'wediaService', 'envService', 'translationJobService', 'userSettings', 'countries', 'masterDependencies', 'modelService'];

    function ProductListSupportController ($scope, $stateParams, $state, $window, productList, wediaService, envService, translationJobService, userSettings, countries, masterDependencies, modelService) {

        $scope.currentModel = null;
        $scope.translationData = null;
        $scope.random = null;
        $scope.translationOrderStatusHover = ['CANCELLED', 'ERROR'];

        //$scope.productList = _(productList).chain().map().value();


        function haveIMasterModel(locale, availableLocales) {
            var findMasterCountries = masterDependencies[locale.split('_')[1]];
            var hasMaster = false;

            _(findMasterCountries).each(function (findMaster) {
                _(availableLocales).each(function (availableLanguage) {
                    if (availableLanguage.split('_')[1] == findMaster) {
                        hasMaster = true;
                    }
                });
            });
            return hasMaster;
        }

        $scope.productList =
            _(productList)
                .chain()
                .map(function (searchModelBo) {
                    return searchModelBo.modelSeasonId;
                })
                .uniq()
                .map(function (modelSeasonId) {

                    var modelSeasonLocalizeds = _(productList).filter(function (searchModelBo) {
                        return searchModelBo.modelSeasonId === modelSeasonId;
                    });

                    _(modelSeasonLocalizeds).each(function (modelSeasonLocalized) {
                        modelSeasonLocalized.isMaster = !haveIMasterModel(modelSeasonLocalized.locale, _(modelSeasonLocalizeds).map(function (msl) {
                            return msl.locale;
                        }));
                    });


                    var modelSeason = _(modelSeasonLocalizeds).find(function (searchModelBo) {
                        return searchModelBo.isMaster;
                    });

                    modelSeason.modelSeasonLocalizeds = _(productList).filter(function (modelSeasonLocalized) {
                        return modelSeasonLocalized.modelSeasonId === modelSeasonId;
                    });

                    return modelSeason;
                })
                .sortBy(function (modelSeason) {
                    return - parseInt(modelSeason.productSellingStartWeek, 10);
                })
                .value();

        $scope.searchByCC = _($scope.productList)
            .chain()
            .filter(function (product) {
                return product.code === $stateParams.code;
            })
            .isEmpty()
            .value();




        $scope.openProduct = function (product, language, country) {
            $state.go('productCardNg', {
                smSeasonId: product.smSeasonId,
                modelId: product.alfrescoId,
                language: language,
                country: country
            });
        };

        $scope.selectCurrent = function (product) {
            $scope.currentModel = product;
            $scope.translationData = null;
            $scope.random = new Date().getTime();
            $scope.compteurMedias = {};


            modelService.countMedias(product.modelSeasonId, product.locale).then(function (modelMediasCount) {
                $scope.compteurMedias = modelMediasCount;
            });

            return translationJobService.getTranslationsData(product.alfrescoId)
                .then(function(response){
                    $scope.translationData = response.data;

                    if ($scope.translationData.translationsExisting) {
                        _($scope.translationData.translationsExisting).each(function (existingTranslation) {
                            var countryCode = existingTranslation.modelLocale.split('_')[1];
                            var modelCountry = _(countries).find(function (country) {
                                return country.code === countryCode.toUpperCase();
                            });
                            existingTranslation.modelCountryLabel = (modelCountry && modelCountry.label) || '';
                        });
                    }
                    $scope.$apply();
                });
        };

        /**
         * Allows to open a transdiag tab for the given modelCode, dateSale and language;
         * It allows to see in transdiag the state of the current translation request.
         * @param modelCode
         *          the modelCode
         * @param dateSale
         *          the dateSale
         * @param language
         *          the transline Code
         */
        $scope.openTransdiagPage = function(modelCode, dateSale, language) {
            $window.open(envService.getTransdiagTranslationStateUrl(modelCode, dateSale, language));
        };

    }

    angular
        .module('spidwebApp')
        .constant('productListSupport', {
            productList: ['$stateParams', 'searchService', 'userSettings', function ($stateParams, searchService, userSettings) {
                userSettings.setLastSearch($stateParams.code);
                return searchService.searchForSupport($stateParams.code);
            }],
            countries: ['countriesService', 'userSettings', function (countriesService, userSettings) {
                return countriesService.getCountries(userSettings.getSelectedIhmLanguage().toUpperCase());
            }],
            masterDependencies: ['countriesService', function (countriesService) {
                return countriesService.getMasterDependencies();
            }]
        });

})();
