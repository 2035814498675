(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('translationMappingsService', translationMappingsService);

    translationMappingsService.$inject = ['axiosService', 'envService'];

    function translationMappingsService(axiosService, envService) {


        return {
            get: function () {
                return axiosService.get(
                    envService.getTranslationMappingsContext(),
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            create: function (translationMapping) {
                return axiosService.post(
                    envService.getTranslationMappingsContext(),
                    translationMapping,
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );
            },
            delete: function (translationMapping) {
                return axiosService.delete(
                    envService.getTranslationMappingsContext() +
                        '/' +
                        translationMapping.id,
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            }
        };

    }

})();
