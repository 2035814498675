(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('FunctionalitiesController', FunctionalitiesController);

    FunctionalitiesController.$inject = ['$scope', '$timeout', 'dialogService'];

    function FunctionalitiesController($scope, $timeout, dialogService) {

        $scope.characteristicsChanged = false;
        $scope.productCardDto.characteristics = $scope.productCardDto.characteristics || [];

        var pristineFunctionalities = angular.copy($scope.productCardDto.characteristics);

        $scope.sortableOptions = {
            update: function () {
                $timeout(function () {
                    angular.forEach($scope.productCardDto.characteristics, function (funct, index) {
                        funct.order = index + 1;
                    });
                });
            }
        };


        // mise à jour de l'ordre des functionalités
        var functionalitiesReOrder = function () {
            var modelUbListe = $scope.productCardDto.characteristics;
            for (var i = 0; i < modelUbListe.length; i++) {
                var functionality = modelUbListe[i];
                functionality.order = i + 1;
            }
        };
        $scope.initNewScope = initNewScope;

        function initNewScope() {

            $scope.newFunc = {
                id: null,
                name: {
                    value: '',
                    override: ''
                },
                description: {
                    value: '',
                    override: ''
                },
                order: null
            };
        }

        // on change l'ordre des UB
        $scope.$watch('model.characteristics', function () {
            functionalitiesReOrder();
        }, true);

        $scope.removeFunc = function (func) {
            var modal = dialogService.showDeleteBox('warning.warning', 'warning.deleteFunc');
            modal.result.then(function () {
                var index = $scope.productCardDto.characteristics.indexOf(func);
                $scope.productCardDto.characteristics.splice(index, 1);
                angular.forEach($scope.productCardDto.characteristics, function (funct, i) {
                    funct.order = i + 1;
                });
                $scope.characteristicsChanged = !angular.equals(pristineFunctionalities, $scope.productCardDto.characteristics);
            });
        };

        $scope.addFunc = function () {

            var newFunc = {};
            angular.copy($scope.newFunc, newFunc);
            newFunc.order = $scope.productCardDto.characteristics.length + 1;
            newFunc.isNew = true;
            $scope.productCardDto.characteristics.push(newFunc);
            initNewScope();
            $scope.characteristicsChanged = !angular.equals(pristineFunctionalities, $scope.productCardDto.characteristics);
        };

        $scope.isDuplicated = function (characteristic) {
            return _($scope.model.functionalities)
                .filter(function (modelCharacteristic) {
                    return modelCharacteristic != characteristic && modelCharacteristic.name.value == characteristic.name.value;
                }).length > 0;
        };
    }

})();

