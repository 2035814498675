(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('propagationService', propagationService);

    propagationService.$inject = ['axiosService', 'envService'/*, 'declinaisonService'*/];

    function propagationService(axiosService, envService/*, declinaisonService*/) {

        /**
         * Gets an object that represents the datas to propagate for SM
         * @param sourceSuperModel
         *          The super model before changes
         * @param targetSuperModel
         *          The super model after changes
         * @returns map{somethingHasChanged: boolean, datasToPropagate: object}
         */
        function getDatasToPropagateForSm(sourceSuperModel, targetSuperModel) {
            var result = {somethingHasChanged: false};
            var datasToPropagate = {};
            var seasonDatas = getMapOfSeasonDatasForSuperModel();
            for (var property in seasonDatas) {
                // eslint-disable-next-line
                if (seasonDatas.hasOwnProperty(property)) {
                    var compareFunc = seasonDatas[property];
                    if (!compareFunc(sourceSuperModel, targetSuperModel)) {
                        result.somethingHasChanged = true;
                        datasToPropagate[property] = true;
                    }
                }
            }
            result.datasToPropagate = datasToPropagate;
            return result;
        }

        /**
         * Gets an object that represents the datas to propagate for models
         * @param sourceSuperModel
         *          The super model that contains siblings before changes
         * @param targetSuperModel
         *          The super model that contains siblings after changes
         * @returns map{somethingHasChanged: boolean, datasToPropagate: object}
         */
        function getDatasToPropagateForModels(sourceSuperModel, targetSuperModel) {
            var result = {somethingHasChanged: false};
            var datasToPropagate = {};
            var seasonDatas = getMapOfSeasonDatasForModel();
            for (var i = 0; i < sourceSuperModel.models.length; i++) {
                // we get the current sibling from the source sm
                var sibling = sourceSuperModel.models[i];
                // we try to get the related sibling from the target sm
                var targetSibling = {code: sibling.code};
                for (var j = 0; j < targetSuperModel.models.length; j++) {
                    var target = targetSuperModel.models[j];
                    if (_.isMatch(target, targetSibling)) {
                        targetSibling = target;
                        break;
                    }
                }
                datasToPropagate[sibling.code] = {modelCode: sibling.code};
                for (var property in seasonDatas) {
                    // eslint-disable-next-line
                    if (seasonDatas.hasOwnProperty(property)) {
                        var compareFunc = seasonDatas[property];
                        if (!compareFunc(sibling, targetSibling)) {
                            result.somethingHasChanged = true;
                            datasToPropagate[sibling.code][property] = true;
                            datasToPropagate[sibling.code]['modelSeasonId'] = targetSibling.modelSeasonId;
                        }
                    }
                }
            }
            result.datasToPropagate = datasToPropagate;
            return result;
        }

        /**
         * Returns a map that contains as key the name of the season data for super model,
         * and as value the comparison function that allows to affirm that the data has been modified
         */
        function getMapOfSeasonDatasForSuperModel() {
            var seasonDatas = {
                'gender': compareGender,
                'sports': compareSports,
                'madeFor': compareMadeFor,
                'madeForExtended': compareMadeForExtended,
                'teaser': compareTeaser,
                'internetTeaser': compareInternetTeaser,
                'warranty': compareWarranty,
                'structurations': compareStructurations,
                'userBenefits': compareUserBenefits,
                'caracteristics': compareCaracteristics,
                'careInstructionsAndSymbols': compareCareInstructionsAndSymbols,
                'storageAdvice': compareStorageAdvice,
                'testText': compareTestText,
                'agreeBy': compareAgreeBy,
                'userRestriction': compareUserRestriction,
                'legalNotice': compareLegalNotice,
                'notice': compareNotice
            };

            return seasonDatas;
        }

        /**
         * Returns a map that contains as key the name of the season data for model,
         * and as value the comparison function that allows to affirm that the data has been modified
         */
        function getMapOfSeasonDatasForModel() {
            var seasonDatas = {
                'medias': compareMedias,
                'modelInfoName': compareModelInfoName,
                'webLabel': compareWebLabel
            };

            return seasonDatas;
        }

        // COMPARISON FUNCTIONS FOR SUPER MODELS //

        /**
         * Compares gender between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareGender(superModel1, superModel2) {
            return _.isEqual(superModel1.genders, superModel2.genders);
        }

        /**
         * Compares sports between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareSports(superModel1, superModel2) {
            return _.isEqual(superModel1.sportGroups, superModel2.sportGroups);
        }

        /**
         * Compares madeFor between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareMadeFor(superModel1, superModel2) {
            return _.isEqual(superModel1.madeFor, superModel2.madeFor);
        }

        /**
         * Compares madeForExtended between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareMadeForExtended(superModel1, superModel2) {
            return _.isEqual(superModel1.madeForExtended, superModel2.madeForExtended);
        }

        /**
         * Compares teaser between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareTeaser(superModel1, superModel2) {
            return _.isEqual(superModel1.teaser, superModel2.teaser);
        }

        /**
         * Compares internet teaser between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareInternetTeaser(superModel1, superModel2) {
            return _.isEqual(superModel1.webTeaser, superModel2.webTeaser);
        }

        /**
         * Compares warranty between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareWarranty(superModel1, superModel2) {
            return _.isEqual(superModel1.warrantyText, superModel2.warrantyText) &&
                _.isEqual(superModel1.warrantyPeriod, superModel2.warrantyPeriod);
        }

        /**
         * Compares structurations between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareStructurations(superModel1, superModel2) {
            return _.isEqual(superModel1.segmentation, superModel2.segmentation);
        }

        /**
         * Compares userBenefits between two super models
         * @param superModel1
         *          the first superModel to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareUserBenefits(superModel1, superModel2) {
            return _.isEqual(superModel1.userBenefits, superModel2.userBenefits);
        }

        /**
         * Compares caracteristics between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareCaracteristics(superModel1, superModel2) {
            // Use of angular.copy because of $$hashKey field
            return _.isEqual(superModel1.characteristics, angular.copy(superModel2.characteristics));
        }

        /**
         * Compares care instructions and care symbols between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareCareInstructionsAndSymbols(superModel1, superModel2) {
            return _.isEqual(superModel1.careInstructions, superModel2.careInstructions) &&
                _.isEqual(superModel1.careSymbols, superModel2.careSymbols);
        }

        /**
         * Compares storage advice between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareStorageAdvice(superModel1, superModel2) {
            return _.isEqual(superModel1.storageAdvice, superModel2.storageAdvice);
        }

        /**
         * Compares test text between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareTestText(superModel1, superModel2) {
            return _.isEqual(superModel1.test, superModel2.test);
        }

        /**
         * Compares agreeBy between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareAgreeBy(superModel1, superModel2) {
            return _.isEqual(superModel1.agreeBy, superModel2.agreeBy);
        }

        /**
         * Compares user restrictions between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareUserRestriction(superModel1, superModel2) {
            return _.isEqual(superModel1.userRestriction, superModel2.userRestriction);
        }

        /**
         * Compares legal notice between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareLegalNotice(superModel1, superModel2) {
            return _.isEqual(superModel1.legalNotice, superModel2.legalNotice);
        }

        /**
         * Compares notice between two super models
         * @param superModel1
         *          the first super model to compare
         * @param superModel2
         *          the second super model to compare
         * @returns {boolean}
         */
        function compareNotice(superModel1, superModel2) {
            return _.isEqual(superModel1.notice, superModel2.notice);
        }

        // COMPARISON FUNCTIONS FOR MODELS //

        /**
         * Compares medias between two models
         * @param model1
         *          the first model to compare
         * @param model2
         *          the second model to compare
         * @returns {boolean}
         */
        function compareMedias(model1, model2) {
            // $sometingChanged is only used when medias has been changed (not used for other datas)
            return (model2.$somethingChanged !== true) &&
                model1.metadatas && model2.metadatas &&
                _.isEqual(model1.metadatas.masterProductActive, model2.metadatas.masterProductActive) &&
                _.isEqual(model1.metadatas.localizedProduct, model2.metadatas.localizedProduct) &&
                _.isEqual(model1.metadatas.masterContext, model2.metadatas.masterContext) &&
                _.isEqual(model1.metadatas.localizedContext, model2.metadatas.localizedContext);
        }

        /**
         * Compares model info name between two models
         * @param model1
         *          the first model to compare
         * @param model2
         *          the second model to compare
         * @returns {boolean}
         */
        function compareModelInfoName(model1, model2) {
            return _.isEqual(model1.modelInfoName, model2.modelInfoName);
        }

        /**
         * Compares web label between two models
         * @param model1
         *          the first model to compare
         * @param model2
         *          the second model to compare
         * @returns {boolean}
         */
        function compareWebLabel(model1, model2) {
            return _.isEqual(model1.webLabel, model2.webLabel);
        }

        return {
            propagate: function (request) {
                return axiosService.post(
                    envService.getPropagationContext(),
                    request,
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    });
            },
            getDatasToPropagateForSm: getDatasToPropagateForSm,
            getDatasToPropagateForModels: getDatasToPropagateForModels
        };
    }
})();
