(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ConfirmDeleteVersionController', ConfirmDeleteVersionController);

    ConfirmDeleteVersionController.$inject = ['$scope', 'versionDate', 'locale', 'code', 'modelCodes'];

    function ConfirmDeleteVersionController ($scope, versionDate, locale, code, modelCodes) {
        $scope.versionDateFormatted = moment(versionDate).format('YYYY/MM/DD');
        $scope.locale = locale;
        $scope.code = code;
        $scope.modelCodesFormatted = modelCodes.join(', ');
    }
})();
