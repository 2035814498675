(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('TimelineService', TimelineService);

    TimelineService.$inject = ['axiosService', 'envService'];

    function TimelineService(axiosService, envService) {
        return {
            getSuperModelTimeline: code =>
                axiosService.get(envService.getSuperModelTimelineUrl().replace(':code', code), {
                    params: {code: code},
                    data: {},
                    withCredentials: true,
                }).then(response => response.data)
        };
    }
})();
