(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('packshotsContext', packshotsContext);

    function packshotsContext () {
        return  {
            restrict: 'E',
            templateUrl: 'productCardNg/imagesVideos/packshotsContext/packshotsContext.html'
        };
    }

})();
