(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('treeService', treeService);

    treeService.$inject = ['axiosService', 'envService'];

    function treeService(axiosService, envService) {

        var callUrl = function (url, params) {

            return axiosService
                .get(
                    url +
                        '?' +
                        Object.entries(params)
                            .map(function(e) {
                                return e.join('=');
                            })
                            .join('&'),
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                )
                .then(function (response) {
                    return Promise.resolve(response.data);
                }).catch(console.error);

        };

        return {
            getUniverses: function (searchDate, language) {
                return callUrl(envService.getTreeUrl(), {
                    type: 'level',
                    id: 5,
                    searchDate: moment(searchDate).format('YYYY-MM-DD'),
                    language: language
                });
            },
            getDepartments: function (searchDate, language, parent) {
                if (!parent) {
                    return callUrl(envService.getTreeUrl(), {
                        type: 'level',
                        id: 4,
                        searchDate: moment(searchDate).format('YYYY-MM-DD'),
                        language: language
                    });
                }
                return callUrl(envService.getTreeUrl(), {
                    type: 'parent',
                    id: parent,
                    searchDate: moment(searchDate).format('YYYY-MM-DD'),
                    language: language
                });
            },
            getSubDepartments: function (searchDate, language, parent) {
                if (!parent) {
                    return callUrl(envService.getTreeUrl(), {
                        type: 'level',
                        id: 3,
                        searchDate: moment(searchDate).format('YYYY-MM-DD'),
                        language: language
                    });
                }
                return callUrl(envService.getTreeUrl(), {
                    type: 'parent',
                    id: parent,
                    searchDate: moment(searchDate).format('YYYY-MM-DD'),
                    language: language
                });
            },
            getFamilies: function (searchDate, language, parent) {
                if (!parent) {
                    return callUrl(envService.getTreeUrl(), {
                        type: 'level',
                        id: 2,
                        searchDate: moment(searchDate).format('YYYY-MM-DD'),
                        language: language
                    });
                }
                return callUrl(envService.getTreeUrl(), {
                    type: 'parent',
                    id: parent,
                    searchDate: moment(searchDate).format('YYYY-MM-DD'),
                    language: language
                });
            },
            getFamilyByCode: function (familyCode, searchDate, language) {
                if (!familyCode) {
                    return null;
                }
                return callUrl(envService.getTreeUrl(), {
                    type: 'family',
                    id: familyCode,
                    searchDate: moment(searchDate).format('YYYY-MM-DD'),
                    language: language
                });
            }
        };
    }

})();
