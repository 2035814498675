(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('universeService', universeService);

    universeService.$inject = ['axiosService', 'envService'];

    function universeService(axiosService, envService) {

        return {
            search: function (criteria) {
                return axiosService.get(
                    envService.getBrandUniverseUrl(),
                    {
                        params: criteria,
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );

            },
            create: function (universe) {
                return axiosService.post(envService.getBrandUniverseUrl(), universe, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            },
            edit: function (universe) {
                return axiosService.put(envService.getBrandUniverseUrl(), universe, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            },
            delete: function (universe) {
                return axiosService.delete(envService.getBrandUniverseUrl(), {
                    withCredentials: true,
                    data: universe,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Accept: 'application/json;charset=UTF-8',
                    },
                });
            }
        };
    }

})();
