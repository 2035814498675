(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('massRepublicationController', massRepublicationController);

    massRepublicationController.$inject = ['$scope', 'axiosService', 'envService', 'countriesService'];

    function massRepublicationController($scope, axiosService, envService, countriesService) {

        $scope.models = [];
        $scope.locales = [];
        $scope.model = {};


        //8203355;201301;201355;fr_FR;it_IT
        var correctRowFormat = new RegExp('[0-9]+;([a-z]{2,3}_[A-Z]{2,3})|i18n');

        countriesService.getCountries('FR').then(function (countries) {
            $scope.locales = _.chain(countries)
                .map(function (country) {
                    return _(country.listLangages).map(function (language) {
                        return {code: language.code + '_' + country.code};
                    });
                }).flatten().value();
        })
        .finally(function () {
            $scope.$apply();
        });

        $scope.addList = function (textToParse) {
            if (!textToParse) {
                return;
            }
            var rows = textToParse.split('\n');
            var badRows = [];
            var goodRows = [];

            rows.forEach(function (row) {
                if (row.match(correctRowFormat)) {
                    goodRows.push(row);
                } else {
                    badRows.push(row);
                }
            });

            $scope.textToParse = '';
            badRows.forEach(function (row) {
                $scope.textToParse = $scope.textToParse + row + '\n';
            });

            var models = _(goodRows).map(function (row) {
                var cells = row.split(';');
                var locale;
                if(cells[1] != 'i18n'){
                    locale = cells[1];
                }else{
                    locale = null;
                }
                return {
                    modelCode: cells[0],
                    modelLocale: locale

                };
            });
            $scope.models = $scope.models.concat(models);
            $scope.$apply();
        };

        $scope.add = function () {
            if(!$scope.model.modelLocale){
                $scope.model.modelLocale = null;
            }else{
                $scope.model.modelLocale = $scope.model.modelLocale.code;
            }

            $scope.models.push($scope.model);
            $scope.model = {};
            $scope.$apply();
        };

        $scope.send = function () {

            _($scope.models).each(function (model) {
                    axiosService.get(envService.getMassRepublicationtUrl(model.modelCode),
                        {
                            params: {modelLocale: model.modelLocale},
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json;charset=UTF-8',
                                'Accept': 'application/json;charset=UTF-8'
                            }
                        }).then(function successCallback(response) {
                        model.status = response.data;
                    }).catch(function errorCallback() {
                        model.status = 'NOK';
                    })
                    .finally(function () {
                        $scope.$apply();
                    });
                    model.status = 'En cours';
                }
            );

        };
        $scope.reset = function () {
            $scope.models = [];
            $scope.$apply();
        };

    }

})();
