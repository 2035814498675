(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MediaMetadataController', MediaMetadataController);

    MediaMetadataController.$inject = ['$scope', 'media', 'wediaService'];

    function MediaMetadataController ($scope, media, wediaService) {

        if (media.type === 'PACKSHOT') {
            $scope.mediaLink = wediaService.getMediaURL(media, 400);
            $scope.downloadLink = wediaService.getDownloadURL(media);
        }
        else if (media.type === 'MEDIA360') {
            $scope.downloadLink = wediaService.getDownloadURL(media);
        }

        $scope.media = media;
        $scope.hasExpired = wediaService.hasExpired(media);
        $scope.willExpireInMonths = wediaService.willExpireInMonths(media, 6);
    }

})();
