(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('videos', videos);

    function videos () {
        return  {
            restrict: 'E',
            templateUrl: 'productCardNg/imagesVideos/videos/videos.html'
        };
    }

})();
