(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('sportService', sportService);

    sportService.$inject = ['axiosService', 'masterdatasCache', 'envService'];

    function sportService(axiosService, masterdatasCache, envService) {


        return {

            getSportsGroups: function (locale) {
                return axiosService.get(
                    envService.getSportsGroupsUrl(),
                    {
                        params: {locale},
                        withCredentials: true,
                        cache: masterdatasCache,
                        headers: {
                            'Accept': 'application/json'
                        }
                    })
                    .then(function (sportsGroups) {
                        return Promise.resolve(sportsGroups.data);
                    });
            },

            getSports: function (locale) {
                return axiosService.get(
                    envService.getSportsUrl(),
                    {
                        params: { locale },
                        withCredentials: true,
                        cache: masterdatasCache,
                        headers: {
                            'Accept': 'application/json;charset=UTF-8'
                        }
                    })
                    .then(function (sports) {
                        return Promise.resolve(sports.data);
                    });
            },
            sortSportsGroups: function (sportsGroups) {
                var self = this;

                if (_(sportsGroups).isEmpty()) {
                    return sportsGroups;
                }
                var sportsGroupsWithSportsSorted = _(sportsGroups).map(function (sportsGroup) {
                    sportsGroup.sports = self.sortSports(sportsGroup.sports);
                    return sportsGroup;
                });
                return _(sportsGroupsWithSportsSorted).sortBy(function (sportsGroup) {
                    return sportsGroup.order;
                });
            },
            sortSports: function (sportsContainers) {
                var self = this;
                var sportsContainersWithSortedPractices = _(sportsContainers).map(function (sportsContainer) {
                    sportsContainer.sports = self.sortPractices(sportsContainer.sports);
                    return sportsContainer;
                });
                return _(sportsContainersWithSortedPractices).sortBy(function (sport) {
                    return sport.label;
                });
            },
            sortPractices: function (practicesContainer) {
                return _(practicesContainer).sortBy(function (practice) {
                    return practice.label;
                });
            }

        };

    }

})();
