(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('mainService', mainService);

    mainService.$inject = ['parameterService', 'userSettings'];

    function mainService (parameterService, userSettings) {

        return {
            getItMessage: getItMessage,
            getNewsMessage: getNewsMessage
        };

        function getItMessage () {
            // chargement du message d'infos MOE
            var parameterNameMoe = 'informationPageContentEnglish';
            if (userSettings.getSelectedIhmLocale() == 'fr_FR') {
                parameterNameMoe = 'informationPageContentFrench';
            }
            return parameterService.getParameter('support', parameterNameMoe);
        }

        function getNewsMessage () {
            var parameterNameMoa = 'informationMoaPageContentEnglish';
            if (userSettings.getSelectedIhmLocale() == 'fr_FR') {
                parameterNameMoa = 'informationMoaPageContentFrench';
            }
            return parameterService.getParameter('support', parameterNameMoa);
        }

    }

})();
