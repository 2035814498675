(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('InfoPlusController', InfoPlusController);

    InfoPlusController.$inject = ['$scope', '$rootScope', 'noticeService'];

    function InfoPlusController($scope, $rootScope, noticeService) {

        $scope.urlPattern = noticeService.urlPattern;

        $scope.validationMessage = 'Choose an existing Concept or Brand Component please.';
        $scope.alreadySelectedCBC = [];

        $scope.enumTypeComponent = {
            concept: 'C',
            brandComponent: 'BC'
        };

        $scope.careSymbolIsDisabled = true;
        $scope.careInstructionIsDisabled = true;

        /* Care Symbol*/
        $scope.$watch('productCardDto.metadatas.paceCareSymbol', function (paceCarePicto) {
            var hasRight = $scope.hasRoleCUD;
            var isAdmin = $scope.isAdmin;
            var editedByPace = paceCarePicto;
            $scope.careSymbolIsDisabled = !hasRight || (!isAdmin && editedByPace);
        });

        /* Care Label*/
        $scope.$watch('productCardDto.metadatas.paceCareInstruction', function (paceCareInstruction) {
            var hasRight = $scope.hasRoleCUD;
            var isAdmin = $scope.isAdmin;
            var editedByPace = paceCareInstruction;
            $scope.careInstructionIsDisabled = !hasRight || (!isAdmin && editedByPace);
        });

        $scope.noticeInvalidAndNotBlank = function (notice) {
            if (!notice) {
                return false;
            } else {
                return !noticeService.noticeIsValid(notice);
            }
        };

    }

})();
