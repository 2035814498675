(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('contenteditable', contenteditable);

    function contenteditable () {
        return {
            restrict: 'A', // only activate on element attribute
            require: '?ngModel', // get a hold of NgModelController
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) { return; } // do nothing if no ng-model

                var html = scope.$eval(attrs.contentInitial);
                element.html(html);
                // Specify how UI should be updated
                ngModel.$render = function () {
                    element.html(ngModel.$viewValue || '');
                };

                // Listen for change events to enable binding
                element.on('blur keyup change', function () {
                    scope.$apply(read);
                });

                // Write data to the model
                function read() {
                    var htmlToWrite = angular.element('<textarea />').html(element.html()).text();
                    // When we clear the content editable the browser leaves a <br> behind
                    // If strip-br attribute is provided then we strip this out
                    if (attrs.stripBr && htmlToWrite == '<br>') {
                        htmlToWrite = '';
                    }
                    ngModel.$setViewValue(htmlToWrite);
                }

            }
        };
    }

})();
