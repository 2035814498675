(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .filter('showWeekYear', showWeekYear);

    function showWeekYear () {
        return function (input) {
            var year = input.substring(0, 4);
            var week = input.substring(4, 6);
            return week + '/' + year;
        };
    }

})();
