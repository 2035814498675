(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('selectOpened', selectOpened);

    selectOpened.$inject = [];

    function selectOpened() {
        return {
            restrict: 'A',
            require: 'uiSelect',
            link: function(scope, element, attr, $select) {
                scope.$watch(function () {
                    return element.is(':visible');
                }, function() {
                        $select.activate();
                    }
                );
            }
        };
    }
})();
