(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('translationJobService', translationJobService);

    translationJobService.$inject = ['axiosService', 'envService'];

    function translationJobService(axiosService, envService) {

        return {
            getTranslationsData: function (alfrescoId) {
                return axiosService.get(
                    envService.getTranslationJobUrl(alfrescoId),
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json;charset=utf-8',
                        },
                    }
                );
            }
        };

    }

})();
