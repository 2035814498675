(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('color', colorDirective);

    colorDirective.$inject = ['spidModal'];

    function colorDirective (spidModal) {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/infosModels/color/color.html',
            scope: {
                colorId: '=',
                colorsRepository: '=',
                amiColors: '=',
                readOnly: '=disabled'
            },
            link: function (scope) {
                scope = _(scope).extend({
                    color: getColor(scope.colorId),
                    displayColor: displayColor,
                    removeColor: removeColor
                });

                scope.$watch('colorId', function(newColorId){
                    if(newColorId){
                        scope.color = getColor(newColorId);
                    } else {
                        scope.color = null;
                    }
                });

                function getColor (colorId) {
                    return _(scope.colorsRepository)
                        .chain()
                        .filter(function (color) {
                            return color.businessColorId === colorId;
                        })
                        .first()
                        .value();
                }

                var colorModal;

                function displayColor () {
                    if (!colorModal) {
                        colorModal = spidModal.open({
                            templateUrl: 'productCardNg/infosModels/color/colorModal.html',
                        controller: 'ColorModalController',
                        dialogFade: true,
                        backdropFade: true,
                        resolve: {
                            colorsRepository: function () {
                                return _(scope.colorsRepository).filter(function (color) {
                                    return !scope.amiColors || !!color.ami;
                                });
                            },
                            amiColors: function () {
                                return scope.amiColors;
                            }
                        }
                        });
                        colorModal.result.then(function (selectedColorId) {
                            scope.colorId = selectedColorId;
                            scope.color = getColor(selectedColorId);
                    });
                        colorModal.closed.then(function () {
                            colorModal = null;
                        });
                    }
                }

                function removeColor () {
                    scope.colorId = null;
                    scope.color = null;
                }

            }
        };
    }

})();
