(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('notationUnitsService', notationUnitsService);

    notationUnitsService.$inject = ['axiosService', 'envService', 'translationService'];

    function notationUnitsService(axiosService, envService, translationService) {

        function loadCache(locale) {
            return axiosService
                .get(envService.getNotationUnitsUrl(), {
                    withCredentials: true,
                    cache: true,
                    headers: {
                        Accept: 'application/json;charset=UTF-8',
                    },
                })
                .then(function (response) {
                    var lookupLocale = locale;
                    if (locale.indexOf('INT') > -1) {
                        lookupLocale =
                            translationService.getLocalesMapping(locale);
                    }
                    return _(response.data).where({ locale: lookupLocale });
                });
        }

        return {
            get: function (country, language) {
                var locale = language + '_' + country;
                return loadCache(locale);
            }
        };

    }

})();
