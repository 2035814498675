(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('notationPopoverContent', notationPopoverContent);

    function notationPopoverContent () {
        return {
            restrict: 'E',
            templateUrl: 'productCardNg/productBenefits/notationPopover/notation-popover-content.html'
        };
    }

})();

