(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('modelValidationService', modelValidationService);

    modelValidationService.$inject = ['$filter', 'noticeService', 'genderService', 'materialService', 'structurationService', 'mediaService'];

    function modelValidationService($filter, noticeService, genderService, materialService, structurationService, mediaService) {
        return {
            checkSuperModelValidForSave: function (productCardDto, genders, structurations, userBenefits, productCardUserBenefits) {
                var isGoodNotice = !productCardDto.notice || noticeService.noticeIsValid(productCardDto.notice);

                if (!isGoodNotice) {
                    return $filter('translate')('error.notice');
                }

                var inactiveValues = [];

                var inactiveStructurationValues = [];
                var inactiveStructurationTypes = [];
                if (!_(productCardDto.segmentation.valueIds).isEmpty()) {

                    // StructurationTypes
                    _(productCardDto.segmentation.valueIds)
                        .chain()
                        .map(function (structurationValueId) {
                            return structurationService.getStructurationTypeByValueId(structurationValueId, structurations);
                        })
                        .reject(function (structurationType) {
                            return structurationType == null || structurationType.isActive;
                        })
                        .reduce(function (mem, inactiveStructurationType) {
                            inactiveStructurationTypes.push(inactiveStructurationType.label);
                        }, '')
                        .value();

                    // StructurationValues
                    _(productCardDto.segmentation.valueIds)
                        .chain()
                        .map(function (structurationValueId) {
                            return structurationService.getStructurationValueById(structurationValueId, structurations);
                        })
                        .reject(function (structurationValue) {
                            return structurationValue == null || structurationValue.isActive;
                        })
                        .reduce(function (mem, inactiveStructurationValue) {
                            inactiveStructurationValues.push(inactiveStructurationValue.label);
                        }, '')
                        .value();
                }

                if (!_(inactiveStructurationTypes).isEmpty()) {
                    inactiveValues = inactiveValues.concat(_(inactiveStructurationTypes).uniq());
                }

                if (!_(inactiveStructurationValues).isEmpty()) {
                    inactiveValues = inactiveValues.concat(_(inactiveStructurationValues).uniq());
                }

                // User Benefits
                productCardUserBenefits.forEach(function (userBenefit) {
                    if (userBenefit.userBenefitCache && userBenefit.userBenefitCache.isActive === false) {
                        var userBenefitTechnicalTitle = _(userBenefits).findWhere({id: userBenefit.userBenefitId}).technicalTitle;
                        inactiveValues.push(userBenefitTechnicalTitle);
                    }
                });


                if (!_(inactiveValues).isEmpty()) {
                    return $filter('translate')('product.unActiveValuesInfo') + inactiveValues;
                }
            },
            checkModelValidForSave: function (model, colorsRepository, fragrancesRepository, flavorsRepository) {
                var isValid = true;
                var messageValidForm = '';

                var colorOrderError = false;
                if (model.colors.color3Id && (!model.colors.color2Id || !model.colors.color1Id)) {
                    colorOrderError = true;
                }
                if (model.colors.color2Id && !model.colors.color1Id) {
                    colorOrderError = true;
                }
                if (colorOrderError) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.colors_order'); //"- color";
                    isValid = false;
                }

                var flavorOrderError = false;
                if (model.flavors.flavor3Id && (!model.flavors.flavor2Id || !model.flavors.flavor1Id)) {
                    flavorOrderError = true;
                }
                if (model.flavors.flavor2Id && !model.flavors.flavor1Id) {
                    flavorOrderError = true;
                }
                if (flavorOrderError) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.flavors_order'); //"- color";
                    isValid = false;
                }


                var inactiveValues = [];

                _(colorsRepository)
                    .chain()
                    .filter(function (color) {
                        return [model.colors.color1Id, model.colors.color2Id, model.colors.color3Id].indexOf(color.businessColor1Id) > -1;
                    })
                    .each(function (color) {
                        if (!color.isActive) {
                            inactiveValues.push(color.label);
                        }
                    });

                _(flavorsRepository)
                    .chain()
                    .filter(function (flavor) {
                        return [model.flavors.flavor1Id, model.flavors.flavor2Id, model.flavors.flavor3Id].indexOf(flavor.id) > -1;
                    })
                    .each(function (flavor) {
                        if (!flavor.isActive) {
                            inactiveValues.push(flavor.label);
                        }
                    });

                _(fragrancesRepository)
                    .chain()
                    .filter(function (fragrance) {
                        return [model.fragrance].indexOf(fragrance.id) > -1;
                    })
                    .each(function (fragrance) {
                        if (!fragrance.isActive) {
                            inactiveValues.push(fragrance.label);
                        }
                    });


                if (!_(inactiveValues).isEmpty()) {
                    return $filter('translate')('product.unActiveValuesInfo') + inactiveValues;
                }

                if (!isValid) {
                    return messageValidForm.substring(1, messageValidForm.length);
                }
            },
            checkSuperModelValidForPublication: function (superModel) {

                var isValid = true;
                var messageValidForm = '';

                if (_(superModel.genders).isEmpty()) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.gender'); //"- Sex";
                    isValid = false;
                }

                if (_(superModel.sports).isEmpty()) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.sport');
                    isValid = false;
                }
                if (!superModel.madeForExtended.value && !superModel.madeForExtended.override) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.designedForOrMadeForExtended'); // "- Designed for or Made For Extended";
                    isValid = false;
                }
                if (!superModel.webTeaser.value && !superModel.webTeaser.override) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.webCatchline'); //"- Product web catchline";
                    isValid = false;
                }
                if (_(superModel.userBenefits).isEmpty()) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.userBenefit'); //"-User benefits";
                    isValid = false;
                }
                if ((!superModel.warrantyPeriod.value && (!superModel.warrantyText.value && !superModel.warrantyText.override)) ||
                    (!!superModel.warrantyPeriod.value && (!!superModel.warrantyText.value && !!superModel.warrantyText.override))
                ) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.warranty'); //"- Global guarantee OR Parts guarantee";
                    isValid = false;
                }

                var hasUserBenefitsDoublons = _(superModel.userBenefits).chain()
                    .countBy(function (e) {
                        return e.userBenefitId;
                    })
                    .max().value() > 1;

                if (hasUserBenefitsDoublons) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.userBenefitDoublons'); //"-User benefits";
                    isValid = false;
                }

                var hasNumberValueUserBenefitWithoutValue = !!_(superModel.userBenefits).find(function (userBenefit) {
                    return userBenefit.note.type === 'NUMBER_VALUE' && (userBenefit.note.value !== 0 && !userBenefit.note.value);
                });

                if (hasNumberValueUserBenefitWithoutValue) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.userBenefitEmptyValue');
                    isValid = false;
                }

                var hasUserBenefitNotComplete = !!_(superModel.userBenefits).find(function (userBenefit) {
                    return !userBenefit.note.type && !userBenefit.label.value;
                });

                if (hasUserBenefitNotComplete) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.userBenefitNoTextNoNotation');
                    isValid = false;
                }

                if (!isValid) {
                    return $filter('translate')('error.publish.pleaseFill') + messageValidForm.substring(1, messageValidForm.length);
                }

            },
            checkModelValidForPublication: function (model) {

                var isValid = true;
                var messageValidForm = '';
                if (!model.modelInfoName.value && !model.modelInfoName.override) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.signLabel'); //"- Store label";
                    isValid = false;
                }
                if (!model.webLabel.value && !model.webLabel.override) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.webLabel'); //"- Libelle balisage";
                    isValid = false;
                }

                if (!isValid) {
                    return $filter('translate')('error.publish.pleaseFill') + messageValidForm.substring(1, messageValidForm.length);
                }

            },
            getCleannedFields: function (model, user) {

                var modelCleanned = angular.copy(model);

                if (modelCleanned.firstEditor == null) {
                    modelCleanned.firstEditor = user;
                }

                /*modelCleanned.declinaisons = modelCleanned.declinaisons.filter(function (variation) {
                    return (variation.name != null && variation.name !== '' && variation.value != null && variation.value !== '');
                });*/

                modelCleanned.warrantyPeriod = modelCleanned.warrantyPeriod || '';

                if (!!modelCleanned.gender && (!modelCleanned.gender.id || modelCleanned.gender.id === -1)) {
                    modelCleanned.gender = null;
                }

                modelCleanned.userBenefits = _(modelCleanned.userBenefits).map(function (userBenefit) {
                    // Ici, on check bien != null (et non pas falsy)
                    if (userBenefit.userBenefitNotationValue !== null && angular.isDefined(userBenefit.userBenefitNotationValue)) {
                        userBenefit.userBenefitNotationValue = userBenefit.userBenefitNotationValue.toString();
                    }
                    return userBenefit;
                });

                return modelCleanned;
            },
            formIsInvalid: function (model) {
                var isValid = true;
                var messageValidForm = '';

                var colorOrderError = false;
                if (model.colors.color3Id && (!model.colors.color2Id || !model.colors.color1Id)) {
                    colorOrderError = true;
                }
                if (model.colors.color2Id && !model.colors.color1Id) {
                    colorOrderError = true;
                }
                if (colorOrderError) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.colors_order'); //"- color";
                    isValid = false;
                }

                var flavorOrderError = false;
                if (model.flavors.flavor3Id && (!model.flavors.flavor2Id || !model.flavors.flavor1Id)) {
                    flavorOrderError = true;
                }
                if (model.flavors.flavor2Id && !model.flavors.flavor1Id) {
                    flavorOrderError = true;
                }
                if (flavorOrderError) {
                    messageValidForm += ', ' + $filter('translate')('error.publish.flavors_order'); //"- color";
                    isValid = false;
                }

                if (!isValid) {
                    return messageValidForm.substring(1, messageValidForm.length);
                }
            },
            checkCharacteristics: function (model) {
                var errors = [];

                _(model.functionalities)
                    .chain()
                    .groupBy('characteristicType')
                    .filter(function (a) {
                        return a.length > 1;
                    })
                    .map(function (a) {
                        return a[0];
                    })
                    .each(function (a) {
                        errors = errors.concat($filter('translate')('product.characteristics.data_duplication_detected', {characteristicType: a.characteristicType}));
                    });


                return _(errors).isEmpty() ? false : errors;
            },
            checkStructurations: function (model) {

                var errors = [];

                var mandatorySegmentationWithoutValue = _(model.segmentation.types)
                    .chain()
                    .filter(function (structurationType) {
                        return structurationType.taxonomyMandatory
                            && (structurationType.structurationValues === null || structurationType.structurationValues.length === 0);
                    })
                    .map(function (structurationType) {
                        return $filter('translate')('product.struturation.structurationType.empty') + ' ' + structurationType.label;
                    })
                    .compact()
                    .value();

                if (!_(mandatorySegmentationWithoutValue).isEmpty()) {
                    errors = errors.concat(mandatorySegmentationWithoutValue.join('<br/>'));
                }
                return _(errors).isEmpty() ? false : errors;
            },
            checkDeclinaisons: function (model) {
                var errors = [];

                _(model.declinaisons)
                    .chain()
                    .each(function (declinaison) {
                        if (angular.isDefined(declinaison.name) && declinaison.name !== null && declinaison.name.length > 30) {
                            errors.push($filter('translate')('product.declinaison.name.max_length_exceeded'));
                        }
                        if (angular.isDefined(declinaison.value) && declinaison.value !== null && declinaison.value.length > 130) {
                            errors.push($filter('translate')('product.declinaison.value.max_length_exceeded'));
                        }
                    })
                    .value();

                return _(errors).isEmpty() ? false : errors;
            },
            checkComposition: function (model, productPartsRepository, materialsRepository) {

                var compositionIsValid = true;
                _(model.composition.partOfProducts).forEach(function (productPart) {
                    if (productPart.id === null) {
                        compositionIsValid = false;
                    } else {
                        _(productPart.rawMaterials).forEach(function (material) {
                            if (material.id === null) {
                                compositionIsValid = false;
                            }
                        });
                    }
                });

                var hasCorrectValues = true;
                _(model.composition.partOfProducts).forEach(function (productPart) {
                    _(productPart.rawMaterials).forEach(function (material) {
                        if (!materialService.isCorrectQuantityValue(material.quantity)) {
                            hasCorrectValues = false;
                        }
                    });
                });


                if (!compositionIsValid || !hasCorrectValues) {
                    return $filter('translate')('product.composition.check.general');
                }


                var hasActivePartsOfProduct = true;
                var hasActiveMaterials = true;
                _(model.composition.partOfProducts).forEach(function (productPart) {
                    var partOfProduct = _(productPartsRepository).findWhere({id: productPart.id});
                    hasActivePartsOfProduct = hasActivePartsOfProduct && partOfProduct.isActive;
                    _(productPart.rawMaterials).forEach(function (partMaterial) {
                        var material = _(materialsRepository).findWhere({id: partMaterial.id});
                        hasActiveMaterials = hasActiveMaterials && (material && material.isActive);
                    });
                });

                if (!(hasActivePartsOfProduct && hasActiveMaterials) && (model.metadatas.needToPublish || model.published)) {
                    return $filter('translate')('error.composition.check.notActive');
                }


                var productPartsNotFilledTo100Percents = [];

                _(model.composition.partOfProducts).forEach(function (productPart) {

                    var percents = 0;

                    _(productPart.rawMaterials).forEach(function (material) {
                        percents += (material.quantity * 100);
                    });

                    if (percents !== (100 * 100)) {
                        productPartsNotFilledTo100Percents.push(productPart);
                    }

                });

                if (productPartsNotFilledTo100Percents.length) {
                    var messageSeparator = ', ';
                    var message = '';
                    _(productPartsNotFilledTo100Percents).forEach(function (productPart) {

                        var partOfProduct = _(productPartsRepository).findWhere({id: productPart.id});
                        message += messageSeparator + partOfProduct.label;

                    });
                    message = message.slice(messageSeparator.length);
                    return $filter('translate')('product.composition.check.percents') + message;
                }

                return false;

            },

            checkAndAlertInactiveValues: function (model, genders, structurations, userBenefits, colorsRepository, fragrancesRepository, flavorsRepository) {


                var isGoodNotice = !model.notice || noticeService.noticeIsValid(model.notice);

                if (!isGoodNotice) {
                    return $filter('translate')('error.notice');
                }


                var inactiveValues = [];

                // Gender
                var inactiveGenders = [];
                if (model.gender && !model.gender.isActive) {
                    inactiveGenders.push(model.gender.label);
                }
                if (!_(model.genders).isEmpty()) {

                    _(model.genders)
                        .chain()
                        .map(function (genderId) {
                            return _(genders).findWhere({id: genderId});
                        })
                        .reject(function (gender) {
                            return gender.isActive;
                        })
                        .reduce(function (mem, inactiveGender) {
                            inactiveGenders.push(inactiveGender.label);
                        }, '')
                        .value();
                }
                if (!_(inactiveGenders).isEmpty()) {
                    inactiveValues = inactiveValues.concat(_(inactiveGenders).uniq());
                }

                var inactiveStructurationValues = [];
                var inactiveStructurationTypes = [];
                if (!_(model.segmentation.valueIds).isEmpty()) {

                    // StructurationTypes
                    _(model.segmentation.valueIds)
                        .chain()
                        .map(function (structurationValueId) {
                            return structurationService.getStructurationTypeByValueId(structurationValueId, structurations);
                        })
                        .reject(function (structurationType) {
                            return structurationType == null || structurationType.isActive;
                        })
                        .reduce(function (mem, inactiveStructurationType) {
                            inactiveStructurationTypes.push(inactiveStructurationType.label);
                        }, '')
                        .value();

                    // StructurationValues
                    _(model.segmentation.valueIds)
                        .chain()
                        .map(function (structurationValueId) {
                            return structurationService.getStructurationValueById(structurationValueId, structurations);
                        })
                        .reject(function (structurationValue) {
                            return structurationValue == null || structurationValue.isActive;
                        })
                        .reduce(function (mem, inactiveStructurationValue) {
                            inactiveStructurationValues.push(inactiveStructurationValue.label);
                        }, '')
                        .value();
                }

                if (!_(inactiveStructurationTypes).isEmpty()) {
                    inactiveValues = inactiveValues.concat(_(inactiveStructurationTypes).uniq());
                }

                if (!_(inactiveStructurationValues).isEmpty()) {
                    inactiveValues = inactiveValues.concat(_(inactiveStructurationValues).uniq());
                }

                // User Benefits
                model.userBenefits.forEach(function (userBenefit) {
                    if (userBenefit.userBenefitCache && userBenefit.userBenefitCache.isActive === false) {
                        var userBenefitTechnicalTitle = _(userBenefits).findWhere({id: userBenefit.id}).technicalTitle;
                        inactiveValues.push(userBenefitTechnicalTitle);
                    }
                });

                _(colorsRepository)
                    .chain()
                    .filter(function (color) {
                        model.models.forEach(function (mdl) {
                            return [mdl.colors.color1Id, mdl.colors.color2Id, mdl.colors.color3Id].indexOf(color.businessColor1Id) > -1;
                        });
                        //return [model.colors.color1Id, model.colors.color2Id, model.colors.color3Id].indexOf(color.businessColor1Id) > -1;
                    })
                    .each(function (color) {
                        if (!color.isActive) {
                            inactiveValues.push(color.label);
                        }
                    });

                _(flavorsRepository)
                    .chain()
                    .filter(function (flavor) {
                        model.models.forEach(function (mdl) {
                            return [mdl.flavors.flavor1Id, mdl.flavors.flavor2Id, mdl.flavors.flavor3Id].indexOf(flavor.id) > -1;
                        });
                    })
                    .each(function (flavor) {
                        if (!flavor.isActive) {
                            inactiveValues.push(flavor.label);
                        }
                    });

                _(fragrancesRepository)
                    .chain()
                    .filter(function (fragrance) {
                        model.models.forEach(function (mdl) {
                            return [mdl.fragrance].indexOf(fragrance.id) > -1;
                        });
                    })
                    .each(function (fragrance) {
                        if (!fragrance.isActive) {
                            inactiveValues.push(fragrance.label);
                        }
                    });


                if (!_(inactiveValues).isEmpty()) {
                    return $filter('translate')('product.unActiveValuesInfo') + inactiveValues;
                }

            },
            checkAndAlertIfMediasDirtyState: function (superModel, pristineSiblings = []) {
                var errors = [];

                var atLeastOneTechnicalReorder = _(superModel.siblings).some(function (sibling) {
                    return sibling.medias.hadTechnicalReorder;
                });

                var atLeastOneNotPristine = _(superModel.siblings).some(function (sibling) {
                    var thePristine = _(pristineSiblings).find(function (pristineSibling) {
                        return pristineSibling.alfrescoId === sibling.alfrescoId;
                    });
                    return !angular.equals(thePristine.medias, sibling.medias);
                });

                var hasObsoleteMedias = mediaService.hasObsoleteMedias(superModel.siblings);


                if (atLeastOneNotPristine || atLeastOneTechnicalReorder) {
                    errors.push($filter('translate')('error.publish.imagesDirtyState'));
                }
                if (hasObsoleteMedias) {
                    errors.push($filter('translate')('error.publish.some_medias_are_outdated'));
                }


                return errors.length > 0 && errors;
            },
            checkMedias: function (siblings = []) {
                var errors = [];
                var errorMessage;

                var obsoleteMedias = _(siblings)
                    .chain()
                    .filter(function (sibling) {
                        return sibling.available;
                    })
                    .map(function (sibling) {
                        return [].concat(
                            _(sibling.medias.modelPackshots).map(function (modelPackshot) {
                                return modelPackshot;
                            }),
                            _(sibling.medias.modelMedia360).map(function (modelMedia360) {
                                return modelMedia360;
                            }),
                            _(sibling.medias.modelMediaFilms).map(function (modelMediaFilms) {
                                return modelMediaFilms.mostRelevantVideo;
                            }));
                    })
                    .flatten()
                    .filter(function (mediaNg) {
                        return (mediaNg != null) && mediaNg.obsolete;
                    })
                    .value();

                if (obsoleteMedias.length > 0) {
                    errorMessage = $filter('translate')('error.publish.some_medias_are_outdated');
                    _(obsoleteMedias).each(function (obsoleteMedia) {
                        errorMessage += '<br /> - ' + (obsoleteMedia.type === 'FILM' ? (obsoleteMedia.mostRelevantVideo && obsoleteMedia.mostRelevantVideo.pixlId) : obsoleteMedia.pixlId);
                    });
                    errors.push(errorMessage);
                }

                return _(errors).isEmpty() ? false : errors;
            }
        };
    }

})();
