(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .filter('displayArray', displayArray);

    function displayArray () {
        return function (input, index) {
            if (Object.prototype.toString.call(input) == '[object Array]' && input.length > 0) {
                var result = '';
                if (index == 'first') {
                    result += input[0];
                } else if (index == 'last') {
                    result += input[input.length - 1];
                } else {
                    input.forEach(function (element, idx) {
                        if (idx !== 0) {
                            result += ',';
                        }
                        result += element;
                    });
                }
                return result;
            }
            return null;
        };
    }

})();
