(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .directive('faq', faq);

    faq.$inject = ['$translate', '$rootScope'];

    function faq($translate, $rootScope) {
        return {
            restrict: 'E',
            scope: {
                subject: '@'
            },
            template: '<a translate-cloak ng-href="{{faqLink}}" ng-disabled="!faqLink" target="_blank" analytics-on analytics-event="Consultation d\'une FAQ" analytics-category="FAQ" analytics-label="Consultation de la FAQ {{subject}}" class="btn-faq"></a>',
            link: function(scope) {

                scope.faqLink = null;

                var ihmLanguageWatcher = $rootScope.$on('$translateChangeSuccess', function () {
                    updateFaqLink();
                });

                function updateFaqLink() {
                    $translate('faqLink.' + scope.subject).then(function (translation) {
                        scope.faqLink = translation;
                    }, function (translationId) {
                        scope.faqLink = null;
                        console.debug('No FAQ translation for', translationId, 'in language', $translate.use());
                    });
                }

                $translate.onReady().then(updateFaqLink);


                scope.$on('$destroy', function () {
                    if (ihmLanguageWatcher) {
                        ihmLanguageWatcher();
                    }
                });
            }
        };
    }
})();
