(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('AdvancedSearchController', AdvancedSearchController);

    AdvancedSearchController.$inject = ['$rootScope', '$scope', '$state', 'userSettings', 'treeService'];

    function AdvancedSearchController($rootScope, $scope, $state, userSettings, treeService) {
        $scope.opened = false;
        /**
         * $scope.closeAdvancedSearch
         *
         * Close Advanced search modal
         */
        $scope.closeAdvancedSearchModal = function () {
            $scope.opened = false;
        };

        /**
         * $scope.toggleAdvancedSearchModal
         *
         * Open or close advanced
         */
        $scope.toggleAdvancedSearchModal = function () {
            $scope.opened = !$scope.opened;
        };
        /**
         * $scope.checkAdvancedSearch
         *
         * Check calendars
         * -> If start date change and goes after end date, end date goes to start date + 1 week
         * -> End date can not select date before start date
         */
        $scope.checkAdvancedSearch = function () {
            $scope.search.calendarTo.minDate = $scope.search.calendarFrom.date;
            if ($scope.search.calendarTo.date < $scope.search.calendarFrom.date) {
                $scope.search.calendarTo.date = new Date($scope.search.calendarFrom.date.getTime() + 7 * 86400000);
            }
        };

        /**
         * fullTree
         *
         * This is the whole tree reference for the date given
         * It changes only when the tree date changes.
         * It is the reference of the tree displayed on the form
         */
        var fullTree = {
            universes: null,
            departments: null,
            subDepartments: null,
            families: null
        };

        /**
         * $scope.tree
         *
         * Tree displayed on the form.
         * The values departments/subDepartments/families change when the form change
         */
        $scope.tree = {
            universes: null,
            departments: null,
            subDepartments: null,
            families: null
        };

        /**
         * $scope.search
         *
         * All search data scoped in the search form
         */
        $scope.search = {
            calendarFrom: {
                date: new Date(),
                isOpen: false,
                format: 'dd/mm/yyyy',
                startingDay: 1,
                minDate: new Date(1990, 1, 1),
                maxDate: new Date(2049, 12, 31),
            },
            calendarTo: {
                date: new Date(new Date().getTime() + 7 * 86400000),
                isOpen: false,
                format: 'dd/mm/yyyy',
                startingDay: 1,
                minDate: new Date(),
                maxDate: new Date(2049, 12, 31),
            },
            calendarTree: {
                date: new Date(),
                isOpen: false,
                format: 'ww/yyyy',
                startingDay: 1,
                minDate: new Date(1990, 1, 1),
                maxDate: new Date(2049, 12, 31),
            },
            tree: {
                universe: null,
                department: null,
                subDepartment: null,
                family: null
            },
            productNature: null
        };

        /**
         * initTree
         *
         * Download the tree with the user ihm locale and at the given date
         */
        function initTree() {
            var treeSettings = userSettings.getPreferedTreeSettings();
            treeService.getUniverses($scope.search.calendarTree.date, userSettings.getSelectedIhmLanguage().toUpperCase())
                .then(function (universes) {
                    fullTree.universes = universes;
                    $scope.tree.universes = universes;
                    $scope.search.tree.universe = treeSettings.universeId || null;
                });
            treeService.getDepartments($scope.search.calendarTree.date, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (departments) {
                fullTree.departments = departments;
                $scope.tree.departments = departments;
                $scope.search.tree.department = treeSettings.departmentId || null;
            });
            treeService.getSubDepartments($scope.search.calendarTree.date, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (subDepartments) {
                fullTree.subDepartments = subDepartments;
                $scope.tree.subDepartments = subDepartments;
                $scope.search.tree.subDepartment = treeSettings.subDepartmentId || null;
            });
            treeService.getFamilies($scope.search.calendarTree.date, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (families) {
                fullTree.families = families;
                $scope.tree.families = families;
                $scope.search.tree.family = treeSettings.familyId || null;
                loadPreviousSearch();
            });
        }

        /**
         * init the tree with the default search date
         */
        initTree();

        $rootScope.$on('profileTreeChanged', function() {
            initTree();
        });

        /**
         * $scope.initTree
         *
         * also populate the scope with the init tree function when the tree date changes
         */
        $scope.initTree = initTree;

        /**
         * $scope.universeChange
         *
         * When a universe changes, it resets the department/subDepartment/family
         * and it populates the department with new values
         */
        $scope.universeChange = function () {
            $scope.search.tree.department = null;
            $scope.search.tree.subDepartment = null;
            $scope.search.tree.family = null;
            $scope.tree.departments = fullTree.departments
                .filter(function (department) {
                    return department.fathernodeID === $scope.search.tree.universe;
                });
            if ($scope.search.tree.universe == null) {
                $scope.tree.families = fullTree.families;
            }
        };

        /**
         * $scope.departmentChange
         *
         * When a department changes, it resets the subDepartment/family
         * and it populates the subDepartment with new values
         */
        $scope.departmentChange = function () {
            $scope.search.tree.subDepartment = null;
            $scope.search.tree.family = null;
            $scope.tree.subDepartments = fullTree.subDepartments
                .filter(function (subDepartment) {
                    return subDepartment.fathernodeID === $scope.search.tree.department;
                });
        };

        /**
         * $scope.subDepartmentChange
         *
         * When a subDepartment changes, it resets the family
         * and it populates the family with new values
         */
        $scope.subDepartmentChange = function () {
            $scope.search.tree.family = null;
            $scope.tree.families = fullTree.families
                .filter(function (family) {
                    return family.fathernodeID === $scope.search.tree.subDepartment;
                });
        };

        /**
         * $scope.familyChange
         *
         * If the family change without universe given, it populates the
         * universe/department/subDepartment values linked to the family
         */
        $scope.familyChange = function () {
            if ($scope.search.tree.universe === null && $scope.search.tree.family !== null) {
                var familyReference = fullTree.families
                    .find(function (family) {
                        return family.nodeID === $scope.search.tree.family;
                    });
                var subDepartmentReference = fullTree.subDepartments
                    .find(function (subDepartment) {
                        return subDepartment.nodeID === familyReference.fathernodeID;
                    });
                var departmentReference = fullTree.departments
                    .find(function (department) {
                        return department.nodeID === subDepartmentReference.fathernodeID;
                    });
                $scope.search.tree.subDepartment = subDepartmentReference.nodeID;
                $scope.search.tree.department = departmentReference.nodeID;
                $scope.search.tree.universe = departmentReference.fathernodeID;

                $scope.tree.subDepartments = fullTree.subDepartments
                    .filter(function (subDepartment) {
                        return subDepartment.fathernodeID === subDepartmentReference.fathernodeID;
                    });
                $scope.tree.departments = fullTree.departments
                    .filter(function (department) {
                        return department.fathernodeID === departmentReference.fathernodeID;
                    });
            }
        };

        /**
         * $scope.sendAdvancedSearch
         *
         * Goes to the family search results with the
         */
        $scope.sendAdvancedSearch = function () {
            var params = {
                code: $scope.code,
                locale: userSettings.getSelectedWorkingLocale(),
                from: $scope.search.calendarFrom.date.toISOString().split('T')[0],
                to: $scope.search.calendarTo.date.toISOString().split('T')[0],
                treeDate: $scope.search.calendarTree.date.toISOString().split('T')[0],
                family: $scope.search.tree.family,
                subDepartment: $scope.search.tree.subDepartment,
                department: $scope.search.tree.department,
                universe: $scope.search.tree.universe
            };
            $scope.code = '';
            $scope.closeAdvancedSearchModal();
            $state.go('smVersionSearch', params, {
                reload: true
            });
        };

        /**
         * Loads previous search entries into the form
         */
        function loadPreviousSearch() {
            if ($state.params.from) {
                $scope.search.calendarFrom.date = new Date($state.params.from);
            }
            if ($state.params.to) {
                $scope.search.calendarTo.date = new Date($state.params.to);
            }
            if ($state.params.treeDate) {
                $scope.search.calendarTree.date = new Date($state.params.treeDate);
            }
            if ($state.params.universe) {
                $scope.search.tree.universe = $state.params.universe;
            }
            if ($state.params.family) {
                $scope.search.tree.universe = null;
                $scope.search.tree.family = parseInt($state.params.family);
                $scope.familyChange();  // force reload of univers, dept, subDept
            }
        }
    }
})();
