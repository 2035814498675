(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('SmVersionSearchController', SmVersionSearchController)
        .constant('smVersionSearchResolver', smVersionSearchResolver())
        .constant('smVersionSearchByCodeResolver', smVersionSearchByCodeResolver());

    SmVersionSearchController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', '$translate', '$uibModal', '$analytics', 'userSettings', 'smVersions', 'searchType', 'searchFamily', 'spidModal', 'toastService', '$filter', 'versionService', 'parameterService'];

    function SmVersionSearchController($scope, $rootScope, $stateParams, $state, $translate, $uibModal, $analytics, userSettings, smVersions, searchType, searchFamily, spidModal, toastService, $filter, versionService, parameterService) {
        $scope.smVersions = smVersions;
        $scope.smVersionsInt = smVersions
            .filter(function (smVersion) {
                return !smVersion.masterLocale || smVersion.masterLocale.contains('_INT');
            });
        $scope.smVersionsUserLocale = smVersions
            .filter(function (smVersion) {
                return smVersion.models.filter(function (model) {
                    return model.locales.indexOf(userSettings.getSelectedWorkingLocale()) > -1;
                }).length > 0;
            });
        $scope.smVersionsIntAndUserLocale = smVersions
            .filter(function (smVersion) {
                return !smVersion.masterLocale || smVersion.masterLocale.contains('_INT') || smVersion.models.filter(function (model) {
                    return model.locales.indexOf(userSettings.getSelectedWorkingLocale()) > -1;
                }).length > 0;
            });

        $scope.isAllChecked = false;

        $scope.selectedVersions = [];
        $scope.selectedVersionInt = [];

        /**
         * $scope.searchInfos
         *
         * All global infos of the result
         */
        $scope.searchInfos = {
            family: $stateParams.family,
            familyName: searchFamily ? searchFamily.translation.label : '',
            searchCode: $stateParams.code,
            totalVersions: smVersions.length,
            totalVersionsInt: $scope.smVersionsInt.length,
            totalVersionsUserLocale: $scope.smVersionsUserLocale.length,
            userSelectedWorkingLocale: userSettings.getSelectedWorkingLocale(),
            searchType: searchType
        };

        $scope.hasRoleCUD = userSettings.hasRole('ROLE_CUD');

        $scope.compare = function () {
            const totalSelectedVersions = $scope.selectedVersions.length + $scope.selectedVersionInt.length;
            if (totalSelectedVersions > 5) {
                toastService.displayToast($filter('translate')('product.comparison.limit.exceeded.error'), 'error');
            } else {
                $state.go('smComparator', {
                    selectedSmVersionIdsWithLocale: _($scope.selectedVersions)
                        .map(function (smSeason) {
                            return smSeason.id + '_' + userSettings.getSelectedWorkingLocale();
                        })
                        .concat(_($scope.selectedVersionInt)
                            .map(function (smSeason) {
                                return smSeason.id + '_' + smSeason.masterLocale;
                            })),
                    locale: userSettings.getSelectedWorkingLocale()
                });
            }
        };

        $scope.isFirstVersionOfSm = function (smVersion) {
            if (searchType === 'code') {
                var l = $scope.smSearchLocale === 'international' ? $scope.smVersionsInt : $scope.smVersionsIntAndUserLocale;
                l = l.filter(function (version) {
                    return version.superModel.code === smVersion.superModel.code;
                }).sort(function (version1, version2) {
                    if (version1.versionDate < version2.versionDate) {
                        return 1;
                    }
                    if (version1.versionDate > version2.versionDate) {
                        return -1;
                    }
                    return 0;
                });
                if (l.length > 0) {
                    return l[0].id === smVersion.id;
                }
            }
            return false;
        };

        /**
         * $scope.isSmVersionEnabled
         *
         * @param smVersion: the version to check if it's active in the search
         * @returns {boolean} true if there is at least one model in the user language or international (if int search active)
         */
        $scope.isSmVersionEnabled = function (smVersion) {
            if (smVersion.models.length === 0 || !userCanOpenVersion(smVersion)) {
                return false;
            } else if ($scope.smSearchLocale === 'international') {
                return containsLocale(smVersion, '_INT', false);
            } else if ($scope.smSearchLocale === 'localized') {
                return containsLocale(smVersion, $scope.searchInfos.userSelectedWorkingLocale, true);
            } else {
                return false;
            }
        };

        /**
         * Returns true if the given locale exists for the version
         *
         * @param smVersion: the version
         * @param {locale} locale: the search locale
         * @param strict {boolean}
         *  - false : you can pass a piece of locale
         *      (ex: en to match with en_GB, en_HK, or en_INT)
         *      (ex: INT to match with en_INT or fr_INT)
         *  - true : the match must be perfect
         *      (ex: fr_INT will only match with fr_INT locale)
         *      (ex: en will not match with en_INT or en_GB)
         * @returns {boolean}
         */
        function containsLocale(smVersion, locale, strict) {
            return extractModelsByLocale(smVersion, locale, strict).length > 0;
        }

        /**
         * Returns all models containing the locale given
         * @param smVersion
         * @param locale
         * @param strict {boolean}
         *  - false : you can pass a piece of locale
         *      (ex: en to match with en_GB, en_HK, or en_INT)
         *      (ex: INT to match with en_INT or fr_INT)
         *  - true : the match must be perfect
         *      (ex: fr_INT will only match with fr_INT locale)
         *      (ex: en will not match with en_INT or en_GB)
         * @returns models of the smVersion with only models matching with the locale
         */
        function extractModelsByLocale(smVersion, locale, strict) {
            return smVersion.models.filter(function (model) {
                if (strict) {
                    return model.locales.indexOf(locale) > -1;
                } else {
                    return model.locales.filter(function (modelLocale) {
                        return modelLocale.contains(locale);
                    }).length > 0;
                }
            });
        }

        /**
         * Returns all models not containing the locale given
         *
         * @param smVersion
         * @param locale
         * @param strict {boolean}
         *  - false : you can pass a piece of locale
         *      (ex: en to match with en_GB, en_HK, or en_INT)
         *      (ex: INT to match with en_INT or fr_INT)
         *  - true : the match must be perfect
         *      (ex: fr_INT will only match with fr_INT locale)
         *      (ex: en will not match with en_INT or en_GB)
         * @returns models of the smVersion without models matching with the locale
         */
        function extractModelsWithoutLocale(smVersion, locale, strict) {
            return smVersion.models.filter(function (model) {
                if (strict) {
                    return model.locales.indexOf(locale) === -1;
                } else {
                    return model.locales.filter(function (modelLocale) {
                        return modelLocale.contains(locale);
                    }).length === 0;
                }
            });
        }

        function publishedFilter(smVersion) {
            if ($scope.smSearchLocale === 'international') {
                return smVersion.models.filter(function (model) {
                    return model.published;
                });
            } else {
                return extractModelsByLocale(smVersion, $scope.searchInfos.userSelectedWorkingLocale, true).filter(function (model) {
                    return model.published;
                });
            }
        }

        function notPublishedFilter(smVersion) {
            return smVersion.models.filter(function (model) {
                return !model.published;
            });
        }

        function unavailableFilter(smVersion) {
            if ($scope.smSearchLocale === 'international') {
                return [];
            }
            return extractModelsWithoutLocale(smVersion, $scope.searchInfos.userSelectedWorkingLocale, true).filter(function (model) {
                return model.published;
            });
        }

        $scope.publishedFilter = publishedFilter;
        $scope.notPublishedFilter = notPublishedFilter;
        $scope.unavailableFilter = unavailableFilter;

        /**
         * A user can open a product card if:
         * - the product card is already initialized (ex: fr_INT or en_INT)
         * - the product card id not initialized (int_INT) & the user has role ROLE_CUD_INT & the user has rights on the product card universe
         *
         * @param smVersion
         * @returns {boolean|*}
         */
        function userCanOpenVersion(smVersion) {
            /*
            1. User can open version already initialzed
            2. User can open version int_INT if user has role ROLE_CUD + profile FR (see: userSettings.hasRole method) + universe
            3. User can open version int_INT if user has role ROLE_CUD_INT (see: userSettings.hasRole method) + universe

            /!\ DISCLAIMER :
            /!\ In this case if version is int_INT, we call method userSettings.hasRole with 3rd parameter INT
            /!\ Call method userSettings.hasRole with ROLE_CUD_INT is too strict!
            /!\ This is why ROLE_CUD + 3rd parameter INT is invoked
            */
            // TODO Utiliser la famille pour chercher l'univers

            return smVersion.masterLocale === 'int_INT' && smVersion.universe && (userSettings.hasRole('ROLE_CUD', 'INT'))
                || smVersion.masterLocale !== 'int_INT';
        }

        $scope.userCanOpenVersion = userCanOpenVersion;

        function userCanCreateNewVersion(versions) {
            return versions.some(smVersion => smVersion.universe && (userSettings.hasRole('ROLE_CUD', 'INT')));
        }

        $scope.rememberMyChoice = rememberMyChoice;


        $scope.changeSmSearchLocale = function (smSearchLocale) {
            $rootScope.sessionSmSearchLocale = $scope.smSearchLocale = smSearchLocale;
        };

        $scope.lastSavedSmSearchLocale = (userSettings.getUser().userProperties && userSettings.getUser().userProperties.smSearchLocale);
        if ($rootScope.sessionSmSearchLocale) {
            $scope.smSearchLocale = $rootScope.sessionSmSearchLocale;
        } else if ($scope.lastSavedSmSearchLocale) {
            $rootScope.sessionSmSearchLocale = $scope.smSearchLocale = $scope.lastSavedSmSearchLocale;
        } else {
            $rootScope.sessionSmSearchLocale = $scope.smSearchLocale = (userSettings.getSelectedWorkingLocale() === 'fr_FR' ? 'international' : 'localized');
            rememberMyChoice();
        }

        $scope.getNewModels = function (smVersion) {
            return smVersion.models.filter(function (model) {
                return isNewModel(smVersion, model);
            });
        };

        function isNewModel(smVersion, model) {
            return (model.worldStartDate && new Date(model.worldStartDate).getTime() >= new Date(smVersion.versionDate).getTime());
        }

        $scope.isNewModel = isNewModel;

        $scope.getPopOverInfo = function (smVersion) {
            return publishedFilter(smVersion).map(function (model) {
                return '<i class="fa fa-bookmark decathlon-green-text"></i> ' + (new Date(model.worldStartDate).getTime() < new Date(smVersion.versionDate).getTime() ? model.code : model.code + ' <i class="fa fa-star newProduct"></i>');
            }).concat(unavailableFilter(smVersion).map(function (model) {
                return '<i class="fa fa-bookmark decathlon-orange-text"></i> ' + (new Date(model.worldStartDate).getTime() < new Date(smVersion.versionDate).getTime() ? model.code : model.code + ' <i class="fa fa-star newProduct"></i>');
            })).concat(notPublishedFilter(smVersion).map(function (model) {
                return '<i class="fa fa-bookmark decathlon-red-text"></i> ' + (new Date(model.worldStartDate).getTime() < new Date(smVersion.versionDate).getTime() ? model.code : model.code + ' <i class="fa fa-star newProduct"></i>');
            })).join('<br/>');
        };

        function rememberMyChoice() {
            userSettings.addUserAdditionalProperty('smSearchLocale', $scope.smSearchLocale).then(function () {
                $scope.lastSavedSmSearchLocale = $scope.smSearchLocale;
            });
        }

        $scope.openSmVersion = function (smVersion, locale) {
            var relevantModel = smVersion.models.find(function (model) {
                return model.code === $stateParams.code;
            });
            $state.go('productCardNg', {
                smCode: smVersion.superModel.code,
                versionDate: smVersion.versionDate,
                smLocale: locale,
                modelCode: relevantModel ? relevantModel.code : smVersion.models[0].code
            });
        };

        $scope.getSmVersionPackshotUrl = function (smVersion) {
            return smVersion.models.sort(function (model1, model2) {
                return new Date(model1.worldStartDate).getTime() - new Date(model2.worldStartDate).getTime();
            }).map(function (model) {
                return model.packshotUrl;
            }).filter(function (packshotUrl) {
                return packshotUrl != null;
            })[0] || 'common/styles/img/no-picture.jpg';
        };

        $scope.toggleSmVersion = function (event, toggledVersion) {
            event.stopPropagation();

            if (!_($scope.selectedVersions).contains(toggledVersion)) {
                $scope.selectedVersions.push(toggledVersion);
            } else {
                $scope.selectedVersions = _($scope.selectedVersions).reject(version => version === toggledVersion);
            }
        };

        $scope.toggleSmVersionInt = function (event, toggledVersion) {
            event.stopPropagation();

            if (!_($scope.selectedVersionInt).contains(toggledVersion)) {
                $scope.selectedVersionInt.push(toggledVersion);
            } else {
                $scope.selectedVersionInt = _($scope.selectedVersionInt).reject(version => version === toggledVersion);
            }
        };

        $scope.toggleAllVersions = function (addAll) {

            if (addAll) {
                $scope.selectedVersions = [].concat($scope.smVersionsIntAndUserLocale);
            } else {
                $scope.selectedVersions = [];
            }
        };

        $scope.toggleAllVersionsInt = function (addAll) {
            if (addAll) {
                $scope.selectedVersionInt = [].concat($scope.smVersionsInt);
            } else {
                $scope.selectedVersionInt = [];
            }
        };

        $scope.createNewVersion = function () {
            var hasError;
            if ($scope.selectedVersionInt.length > 0) {
                $scope.selectedVersionInt.forEach(function (sm) {
                    if (sm.masterLocale === 'int_INT') {
                        toastService.displayToast($filter('translate')('product.createVersion.error.intLocale'), 'error');
                        hasError = true;
                    }
                });
            }

            var createVersionModal;
            if (!createVersionModal && !hasError) {
                createVersionModal = spidModal.open({
                    templateUrl: 'search/createNewVersionModal/createNewVersionModal.html',
                    controller: 'createNewVersionModal',
                    controllerAs: 'vm',
                    size: 'lg',
                    windowClass: 'create-new-version-modal',
                    resolve: {
                        versionsList: function () {
                            return $scope.selectedVersionInt.length > 0 ? $scope.selectedVersionInt : $scope.selectedVersions;
                        },
                        fromLocale: function () {
                            return $scope.selectedVersionInt.length > 0 ? 'international' : 'localized';
                        }
                    }
                });
                createVersionModal.result.then(function () {
                }).catch(angular.noop);
                createVersionModal.closed.then(function () {
                    createVersionModal = null;
                });
            }
        };

        parameterService.getParameter('feature', 'translation.forceTranslationStatus').then(function (resp) {
            $scope.forceTranslationStatus = resp;
        });

        $scope.displayTranslationStatus = function (smVersion, worldWide) {

            var createVersionModal;
            if (!createVersionModal) {
                createVersionModal = spidModal.open({
                    templateUrl: 'search/translationStatusModal/translationStatusModal.html',
                    controller: 'translationStatusModal',
                    controllerAs: 'vm',
                    size: 'lg',
                    windowClass: 'translation-status-modal',
                    resolve: {
                        smVersion: function () {
                            return smVersion;
                        },
                        worldWide: function() {
                            return worldWide;
                        }
                    }
                });
                createVersionModal.result.then(function () {
                }).catch(angular.noop);
                createVersionModal.closed.then(function () {
                    createVersionModal = null;
                });
            }
        };

        $scope.disableCreateVersionButton = function() {
            return !userCanCreateNewVersion($scope.smVersions)
                || !(!$rootScope.migratedLocales.some(ml => ml === userSettings.getSelectedWorkingLocale()) || userSettings.hasRole('ROLE_ADMIN') || !$rootScope.migratedLocales)
                || !($scope.selectedVersionInt.length !== 0 && $scope.smSearchLocale === 'international'
                    || $scope.selectedVersions.length !== 0 && $scope.smSearchLocale === 'localized'
                );
        };

        $scope.disableManualSendTranslationButton = function () {
            for (var version of $scope.selectedVersions) {
                for (var model of version.models) {
                    // We allow manual send in translation iff at least 1 model is published AND the masterLocale of the version is xx_INT (ie the version isn't locale specific)
                    if (model.published && version.masterLocale.contains('_INT')) {
                        return false;
                    }
                }
            }
            return true;
        };

        $scope.manualSendTranslation = function () {
            var manualSendTranslation;
            var smCodesAndSMVersionIdsAndModelStatuses = $scope.selectedVersions
                .filter(version =>  version.masterLocale.endsWith('_INT') && _(version.models).some(model => model.published))
                .reduce((map, smVersion) => {
                    map.smVersionIdAndModelCodes[smVersion.id] = smVersion.models.filter(m => m.published).map(m => m.code);
                    // Get all model statuses for the SM
                    $scope.smVersions
                        .filter(smV => smV.superModel.code === smVersion.superModel.code)
                        .sort((version1, version2) => {
                            if (version1.versionDate > version2.versionDate) {
                                return -1;
                            }
                            if (version1.versionDate < version2.versionDate) {
                                return 1;
                            }
                            return 0;
                        })
                        .forEach(smV => {
                            publishedFilter(smV).forEach(model => {
                                map.modelStatusesBySmVersion[smV.id] =  map.modelStatusesBySmVersion[smV.id] || {};
                                map.modelStatusesBySmVersion[smV.id][model.code] = map.modelStatusesBySmVersion[smV.id][model.code] || 'available';
                            });
                            unavailableFilter(smV).forEach(model => {
                                map.modelStatusesBySmVersion[smV.id] =  map.modelStatusesBySmVersion[smV.id] || {};
                                map.modelStatusesBySmVersion[smV.id][model.code] = map.modelStatusesBySmVersion[smV.id][model.code] || 'unavailable';
                            });
                        });
                return map;
            }, {smVersionIdAndModelCodes: {}, modelStatusesBySmVersion: {}});

            if (!manualSendTranslation) {
                manualSendTranslation = spidModal.open({
                    templateUrl: 'search/manualSendTranslation/manualSendTranslation.html',
                    controller: 'manualSendTranslationModal',
                    controllerAs: 'vm',
                    size: 'lg',
                    windowClass: 'manual-send-translation-modal',
                    resolve: {
                        models: function () {
                            return versionService.getManuallySendableModelVersions(smCodesAndSMVersionIdsAndModelStatuses.smVersionIdAndModelCodes, userSettings.getSelectedWorkingLocale())
                                .then(function (response) {
                                    return response.data;
                                });
                        },
                        modelStatusesBySmVersion: function () {
                            return smCodesAndSMVersionIdsAndModelStatuses.modelStatusesBySmVersion;
                        }
                    }
                });
                manualSendTranslation.result.then(function (modelsToSend) {
                    versionService.sendManualTranslation(modelsToSend, userSettings.getSelectedWorkingLocale()).then(response => {
                        if (!response.data || !response.data.length) {
                            toastService.displayToast($filter('translate')('product.manual_send_translation.result.success'), 'success');
                        } else {
                            toastService.displayToastDontCloseOnClick($filter('translate')('product.manual_send_translation.result.errorInTranslation') + response.data.join(','), 'error');
                        }
                    }, () => {
                        toastService.displayToastDontCloseOnClick($filter('translate')('product.manual_send_translation.result.errorBadRequest'), 'error');
                    });
                }).catch(angular.noop);
                manualSendTranslation.closed.then(function () {
                    manualSendTranslation = null;
                });
            }
        };
    }

    function smVersionSearchResolver() {
        return {
            searchFamily: ['$stateParams', 'treeService', 'userSettings', function ($stateParams, treeService, userSettings) {
                return treeService.getFamilyByCode($stateParams.family, $stateParams.treeDate, userSettings.getSelectedIhmLanguage().toUpperCase()).then(function (families) {
                    return Promise.resolve(families.length > 0 ? families[0] : null);
                }).catch(console.error);
            }],
            smVersions: ['$stateParams', 'versionService', function ($stateParams, versionService) {
                return versionService.search({
                    family: $stateParams.family,
                    startDate: new Date($stateParams.from).toISOString(),
                    endDate: new Date($stateParams.to).toISOString()
                }).then(function (response) {
                    return Promise.resolve(response
                            .data
                            .map(function (e) {
                                e.universe = $stateParams.universe;
                                return e;
                            })
                            .sort(function (version1, version2) {
                                    if (version1.superModel.brand < version2.superModel.brand) {
                                        return -1;
                                    }
                                    if (version1.superModel.brand > version2.superModel.brand) {
                                        return 1;
                                    }
                                    if (version1.superModel.productNature < version2.superModel.productNature) {
                                        return -1;
                                    }
                                    if (version1.superModel.productNature > version2.superModel.productNature) {
                                        return 1;
                                    }
                                    return 0;
                                })
                    );
                }).catch(console.error);
            }],
            searchType: function () {
                return 'arbo';
            }
        };
    }

    function smVersionSearchByCodeResolver() {
        return {
            searchFamily: function () {
                return null;
            },
            smVersions: ['$stateParams', 'versionService', function ($stateParams, versionService) {
                return versionService.searchByCode({
                    code: $stateParams.code
                }).then(function (response) {
                    // Comparison function to sort version
                    var versionCmp = function (version1, version2) {
                        if (version1.versionDate > version2.versionDate) {
                            return -1;
                        }
                        if (version1.versionDate < version2.versionDate) {
                            return 1;
                        }
                        return 0;
                    };
                    var codeSMVersions = [];
                    var conceptionCodeVersions = [];
                    var codeModelVersions = [];
                    // Puts each version in the corresponding array
                    response.data.forEach(function (version) {
                        if (version.superModel.code === $stateParams.code) {
                            codeSMVersions.push(version);
                        } else if (version.superModel.conceptionCode === $stateParams.code) {
                            conceptionCodeVersions.push(version);
                        } else {
                            codeModelVersions.push(version);
                        }
                    });
                    // Since we can have several SM for one conceptionCode, we need to sort and group them by SM to render them correctly
                    conceptionCodeVersions = _(conceptionCodeVersions.sort(versionCmp)).groupBy(function (v) {
                        return v.superModel.code;
                    });
                    // Flatten the Map<SmCode, Version> into Array<Version>
                    conceptionCodeVersions = Object.values(conceptionCodeVersions).flat();
                    return [].concat(codeSMVersions.sort(versionCmp), conceptionCodeVersions, codeModelVersions.sort(versionCmp));
                });
            }],
            searchType: function () {
                return 'code';
            }
        };
    }
})();
